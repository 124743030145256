import {
  ActionIcon,
  Group,
  rem,
  TextInput,
  Combobox,
  useCombobox,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import classes from "./styles/CompanySearch.module.css";
import { useSearchCompanies } from "../../shared/hooks/useSearchCompanies";
import { useDebouncedValue } from "@mantine/hooks";
import { CompanyInfo } from "../../shared/components/company-info/models/CompanyInfo";
import { IconSearch, IconX } from "@tabler/icons-react";
import CSComboboxDropdown from "./components/CSComboboxDropdown";

interface CompanySearchProps {
  closeSearch?: () => void;
  isSmall?: boolean;
  onCompanySelected: (company: CompanyInfo) => void;
}

export default function CompanySearch({
  closeSearch,
  isSmall,
  onCompanySelected,
}: CompanySearchProps) {
  const [searchValue, setSearchValue] = useState("");
  const { companies, searchCompanies, isLoading, isFailed, setCompanies } =
    useSearchCompanies();
  const [debounced] = useDebouncedValue(searchValue, 500);

  useEffect(() => {
    if (!debounced) {
      setCompanies(null);
      return;
    }

    searchCompanies(debounced);
    combobox.openDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced]);

  const clearCombobox = useCallback(() => {
    if (closeSearch) closeSearch();
    setSearchValue("");
  }, [closeSearch]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  return (
    <Combobox
      onOptionSubmit={(option) => {
        onCompanySelected(
          companies!.find((company) => company.companyName === option)!,
        );
        combobox.closeDropdown();
      }}
      store={combobox}
      onClose={clearCombobox}
      radius={0}
      width={isSmall ? "25%" : "40%"}
      offset={11}
      classNames={{
        dropdown: classes["search-combobox-dropdown"],
        option: classes["search-combobox-option"],
      }}
    >
      <Combobox.Target>
        <Group className={classes["target-group"]}>
          <TextInput
            radius="xxs"
            w={isSmall ? rem(265) : rem(386)}
            placeholder="Type ticker symbol or company name"
            leftSection={
              <IconSearch color="var(--mantine-color-secondary-hover-5)" />
            }
            classNames={{
              input: classes["search-input-input"],
            }}
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.currentTarget.value);
            }}
            onClick={() => combobox.openDropdown()}
            onBlur={() => combobox.closeDropdown()}
            autoFocus
            style={{ flex: 1 }}
          />
          <ActionIcon
            variant="light"
            radius="50%"
            bg="var(--mantine-color-secondary-hover-5)"
            onClick={clearCombobox}
          >
            <IconX
              size="1.5rem"
              stroke={2}
              color="var(--mantine-color-white)"
            />
          </ActionIcon>
        </Group>
      </Combobox.Target>
      <CSComboboxDropdown
        companies={companies}
        onCompanySelected={onCompanySelected}
        isLoading={isLoading}
        isFailed={isFailed}
        isSmall={isSmall}
        maxHeight={isSmall ? "43vh" : "60vh"}
      />
    </Combobox>
  );
}
