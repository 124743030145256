import { Center, Pill, Space } from "@mantine/core";
import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";
import CompanySearch from "../../company-search/CompanySearch";

interface CompanyFilterProps {
  companies: CompanyInfo[];
  setCompanies: (companies: CompanyInfo[]) => void;
}

export default function CompanyFilter({
  companies,
  setCompanies,
}: CompanyFilterProps) {
  const handleCompanySelected = (company: CompanyInfo) => {
    if (companies.find((c) => c.id === company.id)) {
      setCompanies(companies.filter((c) => c.id !== company.id));
    } else {
      setCompanies([...companies, company]);
    }
  };

  return (
    <>
      <CompanySearch onCompanySelected={handleCompanySelected} />
      <Space h="lg" />
      {companies.length > 0 && (
        <Center>
          <Pill.Group>
            {companies.map((company) => (
              <Pill
                key={company.id}
                withRemoveButton
                onRemove={() => {
                  handleCompanySelected(company);
                }}
              >
                <span>{company.companyName}</span>
              </Pill>
            ))}
          </Pill.Group>
        </Center>
      )}
    </>
  );
}
