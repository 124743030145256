import { useState, useEffect } from "react";
import {
  rem,
  Text,
  Stack,
  Group,
  Image,
  Center,
  SegmentedControl,
} from "@mantine/core";
import { ChatShort } from "../../../shared/models/Chat";
import { ChatProcessingStep } from "../models/ProcessingStep";
import logo from "../../../../public/images/logoDark.svg";
import Timer from "../../../shared/components/timer/Timer";
import classes from "../styles/ExistingChat.module.css";
import { AnalysisProgressBarValue } from "../enums/AnalysisProgressBarValue";
import {
  analysisProgressBarMap,
  analysisProgressBarData,
} from "../models/AnalysisProgressBarMap";
import useSmallScreen from "../../../shared/hooks/useSmallScreen";

interface AnalysisHeaderProps {
  chat: ChatShort;
  steps: ChatProcessingStep[];
  hasError: boolean;
  inProgress: boolean;
}

export default function AnalysisHeader({
  chat,
  steps,
  hasError,
  inProgress,
}: AnalysisHeaderProps) {
  const [progressBarValue, setProgressBarValue] =
    useState<AnalysisProgressBarValue>();
  const [chatFinishedAt, setChatFinishedAt] = useState<Date | undefined>();
  const { isSmallHeight } = useSmallScreen();

  useEffect(() => {
    if (steps.length === 0) {
      return;
    }

    let newProgressBarValue = progressBarValue;
    let lastMappedStepUpdatedAt = undefined;

    for (const step of steps) {
      if (analysisProgressBarMap.has(step.stepType)) {
        newProgressBarValue = analysisProgressBarMap.get(step.stepType);
        lastMappedStepUpdatedAt = step.updatedAt;
      }
    }
    setProgressBarValue(newProgressBarValue);
    setChatFinishedAt(lastMappedStepUpdatedAt);
  }, [steps, progressBarValue]);

  return (
    <>
      {isSmallHeight ? (
        <Center className={classes["analysis-small-header"]}>
          <Text fw={500}>{chat.input}</Text>
        </Center>
      ) : (
        <Stack className={classes["analysis-header"]}>
          <Text fw={500}>{chat.input}</Text>
          <Group className={classes["analysis-header-group"]}>
            <Group className={classes["analysis-progress-bar-container"]}>
              <Group className={classes["analysis-progress-text"]}>
                <Image w={rem(24)} src={logo} />
                <Text fw={500}>Progress</Text>
              </Group>
              <SegmentedControl
                value={progressBarValue}
                withItemsBorders={false}
                variant="default"
                readOnly
                data={analysisProgressBarData}
                classNames={{ label: classes["analysis-progress-bar-label"] }}
              />
            </Group>
            <Timer
              startedAt={chat.startedAt}
              finishedAt={inProgress ? undefined : chatFinishedAt}
              variant={inProgress ? "neutral" : "success"}
              hasError={hasError}
            />
          </Group>
        </Stack>
      )}
    </>
  );
}
