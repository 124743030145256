import { IconMessage2Plus } from "@tabler/icons-react";
import GradientActionIcon from "../../../shared/components/gradient/GradientActionIcon";
import classes from "../styles/NewChatButton.module.css";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface NewChatButtonProps {
  isActive?: boolean;
  onClick: () => void;
}

export default function NewChatButton({
  isActive = true,
  onClick,
}: NewChatButtonProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <GradientActionIcon
      icon={<IconMessage2Plus size={isLargeScreen ? 20 : 16} />}
      isActive={isActive}
      onClick={onClick}
      className={classes["main"]}
    />
  );
}
