import { Text, Group } from "@mantine/core";
import { dateTimeToString } from "../../../shared/utils/Dates";
import { ChatShort } from "../../../shared/models/Chat";

interface ChatInfoProps {
  chat: ChatShort;
}

export default function ChatInfo({ chat }: ChatInfoProps) {
  return (
    <>
      <Group>
        <Text fz="md" fw={500}>
          Date: {dateTimeToString(chat.startedAt)}
        </Text>
      </Group>
      <Group>
        <Text fz="md" fw={500}>
          Used settings:
        </Text>
        {Object.entries(chat.settings).map(([key, value], index) => (
          <Text key={"var" + index} fz="sm" fw={500} tt="uppercase">
            {key}: {value};
          </Text>
        ))}
      </Group>
    </>
  );
}
