import { useApiPrivateFileRequest } from "../../../shared/hooks/useApiFile";

export const useGetMarketDataFile = () => {
  const { isLoading, call } = useApiPrivateFileRequest();
  const getFileData = async (chatId: string, stepId: string) => {
    await call(`/chat/${chatId}/step/${stepId}/data`, "GET");
  };

  return { isLoading, getFileData };
};
