import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Center, Loader } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../enums/LocalStorageKeys";

export default function AuthProtectedRoute() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [, saveRedirectUrl] = useLocalStorage<string>({
    key: LocalStorageKeys.RedirectUrl,
  });

  useEffect(() => {
    if (isAuthenticated) return;

    saveRedirectUrl(window.location.pathname);
    loginWithRedirect();
  }, [isAuthenticated, loginWithRedirect, saveRedirectUrl]);

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Center h="100vh" w="100vw">
      <Loader />
    </Center>
  );
}
