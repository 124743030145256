import { ActionIcon, Group, rem, ScrollArea, Text } from "@mantine/core";
import { Dropzone, FileWithPath, PDF_MIME_TYPE } from "@mantine/dropzone";
import { IconUpload, IconX, IconFileTypePdf } from "@tabler/icons-react";

interface UploadDocumentsProps {
  files: FileWithPath[];
  setFiles: (files: FileWithPath[]) => void;
}

export function UploadDocuments({ files, setFiles }: UploadDocumentsProps) {
  const handleDropFiles = (newFiles: FileWithPath[]) => {
    setFiles([...files, ...newFiles]);
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <>
      <Dropzone
        onDrop={handleDropFiles}
        accept={PDF_MIME_TYPE}
        maxSize={5 * 1024 ** 3} // 5 GB
      >
        <Group
          justify="center"
          gap="xl"
          mih={220}
          style={{ pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <IconUpload
              style={{
                width: rem(52),
                height: rem(52),
                color: "var(--mantine-color-blue-6)",
              }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              style={{
                width: rem(52),
                height: rem(52),
                color: "var(--mantine-color-red-6)",
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileTypePdf
              style={{
                width: rem(52),
                height: rem(52),
                color: "var(--mantine-color-dimmed)",
              }}
              stroke={1.5}
            />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drag PDF here or click to select files
            </Text>
            <Text size="sm" c="dimmed" inline mt={7}>
              Upload one or more PDF files to be included into chat
            </Text>
          </div>
        </Group>
        <ScrollArea h={rem(450)}>
          {files.map((file, i) => (
            <Group>
              <ActionIcon
                variant="transparent"
                color="tertiary"
                onClick={(event) => {
                  event.stopPropagation(); // Prevent file select event
                  handleRemoveFile(i);
                }}
              >
                <IconX />
              </ActionIcon>
              <Text key={i}>{file.path}</Text>
            </Group>
          ))}
        </ScrollArea>
      </Dropzone>
    </>
  );
}
