import { ChatProcessingStep } from "../ProcessingStep";

export class DocumentSearchUserContentStep extends ChatProcessingStep {
  public query: string;
  public results: string;

  constructor(step: any) {
    super(step);
    this.query = step.data.searchQuery;
    this.results = step.data.searchResults;
  }
}
