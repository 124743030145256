import { useEffect, useState } from "react";
import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";
import InlineCompanySearch from "../../company-search/components/InlineCompanySearch";

interface InlineCompanyFilterProps {
  inputRef: React.RefObject<HTMLTextAreaElement>;
  input: string;
  setInput: (input: string) => void;
  children: React.ReactNode;
  companies: CompanyInfo[];
  setCompanies: (companies: CompanyInfo[]) => void;
  setIsSearching: (isSearching: boolean) => void;
}

export default function InlineCompanyFilter({
  inputRef,
  input,
  setInput,
  children,
  companies,
  setCompanies,
  setIsSearching,
}: InlineCompanyFilterProps) {
  const [searchValue, setSearchValue] = useState("");

  const handleCompanySelected = (company: CompanyInfo) => {
    if (!companies.find((c) => c.id === company.id)) {
      setCompanies([...companies, company]);
    }

    const updatedInput = input.replace(/#(?=[^#]*$)/, "").trim();
    setInput(updatedInput);
    setIsSearching(false);
  };

  useEffect(() => {
    const match = input.match(/#([^#]{1,50})$/); // Match up to 50 characters after the last #
    if (match) {
      setIsSearching(true);
      setSearchValue(match[1].trim());
    } else {
      setIsSearching(false);
      setSearchValue("");
    }
  }, [input, setIsSearching]);

  return (
    <InlineCompanySearch
      inputRef={inputRef}
      endsWithHashtag={input.endsWith("#")}
      searchValue={searchValue}
      onCompanySelected={handleCompanySelected}
    >
      {children}
    </InlineCompanySearch>
  );
}
