import { Center, Combobox, useCombobox, Text } from "@mantine/core";
import CSComboboxDropdown from "./CSComboboxDropdown";
import { useSearchCompanies } from "../../../shared/hooks/useSearchCompanies";
import { useDebouncedValue } from "@mantine/hooks";
import { useEffect } from "react";
import classes from "../styles/CompanySearch.module.css";
import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";

interface InlineCompanySearchProps {
  inputRef: React.RefObject<HTMLTextAreaElement>;
  endsWithHashtag: boolean;
  searchValue: string;
  children: React.ReactNode;
  onCompanySelected: (company: CompanyInfo) => void;
}

export default function InlineCompanySearch({
  inputRef,
  endsWithHashtag,
  searchValue,
  children,
  onCompanySelected,
}: InlineCompanySearchProps) {
  const { companies, searchCompanies, isLoading, isFailed, setCompanies } =
    useSearchCompanies();
  const [debounced] = useDebouncedValue(searchValue, 500);

  useEffect(() => {
    if (!debounced) {
      setCompanies(null);
      return;
    }

    searchCompanies(debounced);
    combobox.openDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  return (
    <Combobox
      onOptionSubmit={(option) => {
        onCompanySelected(
          companies!.find((company) => company.companyName === option)!,
        );
        combobox.closeDropdown();
        onCompanySelected(
          companies!.find((company) => company.companyName === option)!,
        );
        combobox.closeDropdown();
      }}
      store={combobox}
      radius={0}
      width={
        inputRef.current?.offsetWidth && inputRef.current.offsetWidth > 700
          ? `calc(${inputRef.current.offsetWidth}px - 25%)`
          : "25%"
      }
      offset={
        (document.getElementById("added-documents")?.offsetHeight || 0) + 11
      }
      position="top"
      classNames={{
        dropdown: classes["inline-search-combobox-dropdown"],
        option: classes["search-combobox-option"],
      }}
      zIndex={200}
    >
      <Combobox.Target>{children}</Combobox.Target>
      {endsWithHashtag && (
        <Combobox.Dropdown>
          <Center w="100%" mih="5vh" p={14}>
            <Text c="text-secondary.2">
              Please start typing company name or ticker symbol
            </Text>
          </Center>
        </Combobox.Dropdown>
      )}
      {!endsWithHashtag && (
        <CSComboboxDropdown
          companies={companies}
          onCompanySelected={onCompanySelected}
          isLoading={isLoading}
          isFailed={isFailed}
          isSmall={
            inputRef.current?.offsetWidth
              ? inputRef.current.offsetWidth < 700
              : false
          }
          maxHeight="17vh"
        />
      )}
    </Combobox>
  );
}
