import markdownClasses from "../../../../shared/styles/Markdown.module.css";
import { ModelOutputMessageData } from "../../models/message-data/ModelOutputMessageData";
import FunctionCall from "../FunctionCall";
import { MarkdownWithCitations } from "../../../sources/MarkdownWithCitations";
import CopyModelOutputIcon from "../CopyModelOutputIcon";
import ContentDocuments from "../../../sources/ContentDocuments";

interface ModelOutputMessageProps {
  data: ModelOutputMessageData;
}

export default function ModelOutputMessage({ data }: ModelOutputMessageProps) {
  return (
    <>
      <MarkdownWithCitations
        content={data.content}
        className={markdownClasses["markdown"]}
      />
      <FunctionCall functionCall={data.functionCall} />
      <ContentDocuments content={data.content} />
      {data.content && <CopyModelOutputIcon content={data.content} />}
    </>
  );
}
