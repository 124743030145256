import { ActionIcon } from "@mantine/core";
import { IconFileText } from "@tabler/icons-react";
import { CompanyDocumentType } from "../../../shared/enums/CompanyDocumentType";
import { generateDocumentLink } from "../../../shared/utils/Strings";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface DocumentContentOpenerIconProps {
  id: string;
  type: CompanyDocumentType;
}

export default function DocumentContentOpenerIcon({
  id,
  type,
}: DocumentContentOpenerIconProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <ActionIcon
      variant="default"
      radius="md"
      size={isLargeScreen ? "xl" : "md"}
      component="a"
      href={generateDocumentLink(id, type)}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Open document"
    >
      <IconFileText
        color={"var(--mantine-color-black)"}
        size={isLargeScreen ? "1.625rem" : "1rem"}
      />
    </ActionIcon>
  );
}
