export class XAxisOptions {
  public title: {
    text: string;
  };
  public type: "category" | "datetime" | "numeric";
  public tickAmount: number; // tickAmount doesn’t have any effect when xaxis.type = datetime
  public axisBorder: {
    show: boolean;
    color: string;
  };
  public axisTicks: {
    show: boolean;
  };

  constructor(
    xaxisTitle: any,
    xaxisType: any,
    tickAmount: number,
    plainAxis: boolean = false,
  ) {
    this.title = { text: xaxisTitle };
    this.type = xaxisType;
    this.tickAmount = tickAmount ?? 10;
    this.axisBorder = {
      show: plainAxis,
      color: "var(--mantine-color-secondary-6)",
    };
    this.axisTicks = {
      show: !plainAxis,
    };
  }
}

export class XAxisDatetimeOptions extends XAxisOptions {
  public labels: {
    formatter: (value: any, timestamp: any, opts: any) => string;
  };

  constructor(
    xaxisTitle: string,
    xaxisType: "category" | "datetime" | "numeric",
    tickAmount: number,
    plainAxis: boolean,
  ) {
    super(xaxisTitle, xaxisType, tickAmount, plainAxis);
    this.labels = {
      formatter: (value: any, timestamp: any, opts: any) => {
        return opts.dateFormatter(new Date(timestamp), "dd/MM/yyyy");
      },
    };
  }
}
