import { useEffect } from "react";
import { ChatUpdateType } from "../enums/ChatUpdateType";
import { logger } from "../utils/Logger";
import * as signalR from "@microsoft/signalr";
import { useAuth0 } from "@auth0/auth0-react";

export const useChatUpdates = (
  chatId: string,
  callback: (type: ChatUpdateType, data: any) => void,
): void => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const channelName = `${chatId}:chat`;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${import.meta.env.VITE_API_BASE_URL}/chatUpdatesHub`, {
        withCredentials: false,
        accessTokenFactory: getAccessTokenSilently,
      })
      .configureLogging(signalR.LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    const handleMessage = (name: string, data: string) => {
      callback(name as ChatUpdateType, JSON.parse(data));
    };

    const startConnection = async () => {
      try {
        await connection.start();
        logger.debug("SignalR connected");

        await connection.invoke("JoinGroup", channelName);
      } catch (error) {
        logger.error("SignalR connection error: ", error);
      }
    };

    // Start the connection
    startConnection();

    connection.on("ReceiveMessage", handleMessage);
    connection.onclose(() => {
      logger.debug("SignalR connection closed. Reconnecting...");
      setTimeout(startConnection, 3000); // Retry after 3 seconds
    });

    return () => {
      connection.off("ReceiveMessage", handleMessage);
      if (connection.state !== signalR.HubConnectionState.Connected) return;
      connection
        .invoke("LeaveGroup", channelName)
        .then(() => {})
        .catch((error) => {
          logger.error("Failed to leave group: ", error);
        })
        .finally(() => {
          connection.stop();
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);
};
