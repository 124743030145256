import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useRetryStep = (chatId: string, stepId: string) => {
  const { call } = useApiPrivateRequest<void>();
  const retryStep = async () => {
    await call(`/chat/${chatId}/step/${stepId}/retry`, "PUT");
  };

  return { retryStep };
};
