import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { StepModelCall } from "../../../shared/models/StepModelCall";

export const useGetStepModelCalls = (chatId: string, stepId: string) => {
  const { data: modelCalls, call } = useApiPrivateRequest<StepModelCall[]>(
    (response) => response.map((chat: any) => new StepModelCall(chat)),
  );
  const getModelCalls = async () => {
    await call(`/admin/chat/${chatId}/step/${stepId}/calls`, "GET");
  };

  return { modelCalls, getModelCalls };
};
