import { Text } from "@mantine/core";
import { useEffect, useRef } from "react";
import { InterpretationStep } from "../../models/chat-steps/InterpretationStep";
import { useIsVisible } from "../../../../shared/hooks/useIsVisible";
import classes from "../../styles/ExistingChat.module.css";
import {
  EventName,
  EventParameter,
  sendEvent,
} from "../../../../shared/utils/UsageAnalytics";

interface InterpretationProps {
  step: InterpretationStep;
}

export default function Interpretation({ step }: InterpretationProps) {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isVisible) {
      sendEvent(EventName.StepView, {
        [EventParameter.StepName]: step.stepType,
      });
    }
  }, [isVisible, step.stepType]);

  return (
    <>
      <Text ref={ref} style={{ whiteSpace: "pre-line" }}>
        {step.interpretation}
      </Text>
      {step.news.length !== 0 && (
        <Text>
          <br />
          Related news:
          <ul>
            {step.news.map((news, index) => (
              <li key={index}>
                <a
                  href={news.sourceUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={classes["news-link"]}
                >
                  {news.source}: {news.title}
                </a>
              </li>
            ))}
          </ul>
        </Text>
      )}
    </>
  );
}
