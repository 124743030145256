import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Question } from "../models/Question";

export const useSearchQuestions = () => {
  const {
    data: questions,
    call,
    isLoading: areQuestionsLoading,
    isFailed: areQuestionsFailed,
  } = useApiPrivateRequest<Question[]>((response) =>
    response.map((question: any) => new Question(question)),
  );
  const searchQuestions = async (query: string) => {
    await call("/questionbank", "GET", { query });
  };

  return {
    questions,
    searchQuestions,
    areQuestionsLoading,
    areQuestionsFailed,
  };
};
