import { Text, ScrollArea } from "@mantine/core";
import classes from "./styles/ScrollableText.module.css";

interface ScrollableTextProps {
  content?: string;
}

export default function ScrollableText({ content }: ScrollableTextProps) {
  return (
    <ScrollArea.Autosize className={classes["scrollarea"]}>
      <Text className={classes["text"]}>{content}</Text>
    </ScrollArea.Autosize>
  );
}
