import { ChatProcessingStep } from "../ProcessingStep";

export class UserInputStep extends ChatProcessingStep {
  public input: string;

  constructor(step: any) {
    super(step);
    this.input = step.data.input as string;
  }
}
