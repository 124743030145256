export class NewsEntry {
  id: string;
  imageUrl: string;
  sourceUrl: string;
  source: string;
  title: string;
  summary: string;
  publishedAt: Date;
  relevanceScore: number;

  constructor(data: any) {
    this.id = data.id;
    this.imageUrl = data.imageUrl;
    this.sourceUrl = data.sourceUrl;
    this.source = data.source;
    this.title = data.title;
    this.summary = data.summary;
    this.publishedAt = new Date(data.publishedAt);
    this.relevanceScore = data.relevanceScore;
  }
}
