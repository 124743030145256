export class ChatFeedback {
  public chatId: string;
  public userId: string;
  public chatTitle: string;
  public rating?: 1 | -1;
  public comment?: string;

  constructor(obj: any) {
    this.chatId = obj.chatId;
    this.userId = obj.userId;
    this.chatTitle = obj.chatTitle;
    this.rating = obj.rating;
    this.comment = obj.comment;
  }
}
