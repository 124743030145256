import { Button, Center, Stack } from "@mantine/core";
import { ChatProcessingStep } from "../../models/ProcessingStep";
import { useRetryStep } from "../../api/useRetryStep";
import { IconRefresh } from "@tabler/icons-react";

interface FailedStepProps {
  chatId: string;
  step: ChatProcessingStep;
  isLast: boolean;
}

export default function FailedStep({ chatId, step, isLast }: FailedStepProps) {
  const { retryStep } = useRetryStep(chatId, step.id);

  return (
    <Center>
      <Stack>
        {step.comment}
        {isLast && (
          <Center>
            <Button onClick={retryStep} size="md" leftSection={<IconRefresh />}>
              Try again
            </Button>
          </Center>
        )}
      </Stack>
    </Center>
  );
}
