import { CompanyDocument } from "../CompanyDocument";
import { FunctionCallMessageData } from "./FunctionCallMessageData";

export class FilterDocumentsMessageData extends FunctionCallMessageData {
  public documents: CompanyDocument[];

  constructor(data: any) {
    super(data);
    this.documents = data.documents.map(
      (document: any) => new CompanyDocument(document),
    );
  }
}
