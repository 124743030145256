import { rem, ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconTextPlus } from "@tabler/icons-react";
import { useCreateQuestion } from "../api/useCreateQuestion";
import { useEffect } from "react";
import classes from "../styles/AddQuestionIcon.module.css";
import {
  CustomEvent,
  useEventTrigger,
} from "../../../shared/hooks/useEventManagement";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface AddQuestionIconProps {
  question: string;
}

export default function AddQuestionIcon({ question }: AddQuestionIconProps) {
  const { isCreated, createQuestion, isCreating } = useCreateQuestion();
  const dispatchEvent = useEventTrigger();

  useEffect(() => {
    if (!isCreated) return;
    dispatchEvent(CustomEvent.RefreshQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated]);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <>
      {!isCreated && (
        <Tooltip
          label={!isCreated ? "Add to Question Bank" : "Question is added"}
          withArrow
          radius="xxs"
        >
          <ActionIcon
            variant="simple"
            size={isLargeScreen ? 20 : 16}
            radius={rem(4)}
            loading={isCreating}
            onClick={() => {
              createQuestion(question);
            }}
          >
            <IconTextPlus size={isLargeScreen ? 20 : 16} />
          </ActionIcon>
        </Tooltip>
      )}
      {isCreated && (
        <Group className={classes["existing-question-group"]}>
          <Text className={classes["existing-question-text"]}>
            Question Added
          </Text>
          <IconTextPlus size={12} color="var(--mantine-color-success-5)" />
        </Group>
      )}
    </>
  );
}
