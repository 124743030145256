export enum MessageDataType {
  UserInputMessageData = "UserInputMessageData",
  ModelOutputMessageData = "ModelOutputMessageData",
  GetMarketDataMessageData = "GetMarketDataMessageData",
  ExecuteCodeMessageData = "ExecuteCodeMessageData",
  FunctionCallMessageData = "FunctionCallMessageData",
  AskDocumentMessageData = "AskDocumentMessageData",
  FTSMessageData = "FTSMessageData",
  FilterDocumentsMessageData = "FilterDocumentsMessageData",
  AskCompanyDocumentsMessageData = "AskCompanyDocumentsMessageData",
}
