import { StepType } from "../enums/StepType";
import { Feedback } from "./Feedback";

export abstract class ChatProcessingStep {
  public id: string;
  public stepType: StepType;
  public comment: string;
  public completionPercentage: number;
  public startedAt: Date;
  public updatedAt?: Date;
  public feedback?: Feedback;

  constructor(step: any) {
    this.id = step.id as string;
    this.stepType = step.stepType as StepType;
    this.comment = step.comment as string;
    this.completionPercentage = step.completionPercentage as number;
    this.startedAt = new Date(step.startedAt);
    if (step.updatedAt) {
      this.updatedAt = new Date(step.updatedAt);
    }
    if (step.feedback) {
      this.feedback = new Feedback(step.feedback);
    }
  }

  public get inProgress() {
    return this.completionPercentage >= 0 && this.completionPercentage < 100;
  }

  public get failed() {
    return this.completionPercentage === -1;
  }

  public get timeInterval() {
    //plus operator converts Date to number
    return this.updatedAt ? +this.updatedAt - +this.startedAt : undefined;
  }

  public get textContent(): string | null {
    return null;
  }
}
