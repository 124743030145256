import { numberToString } from "../../../utils/Number";

export class YAxisItem {
  public labels: {
    formatter: (value: number) => string;
  };
  public decimalsInFloat: number;
  public title?: {
    text: string;
  };
  public seriesName?: string;
  public show?: boolean;
  public opposite?: boolean;
  public axisBorder: {
    show: boolean;
    color: string;
  };
  public axisTicks: {
    show: boolean;
    color: string;
  };

  constructor(
    decimalsInFloat: number,
    plainAxis: boolean = false,
    item?: any,
    title?: string,
  ) {
    if (item) {
      this.seriesName = item.seriesName;
      this.opposite = item.opposite;
      this.title = { text: item.title };
      this.show = item.show;
    }
    if (title) {
      this.title = { text: title };
      this.show = true;
    }

    this.axisBorder = {
      show: true,
      color: plainAxis
        ? "var(--mantine-color-secondary-6)"
        : "var(--mantine-color-black)",
    };
    this.axisTicks = {
      show: !plainAxis,
      color: "var(--mantine-color-black)",
    };
    this.decimalsInFloat = decimalsInFloat;
    this.labels = {
      formatter: (value: number) => {
        return numberToString(value, this.decimalsInFloat);
      },
    };
  }
}
