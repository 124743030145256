import { enumToString } from "../shared/utils/Enums";
import { CompanyFundamentalsType } from "../features/existing-chat/enums/CompanyFundamentalsType";
import { VariableType } from "../features/existing-chat/enums/VariableType";

export class VariableInfo {
  variable: string;
  variableType: VariableType;
  companyFundamentalsType?: CompanyFundamentalsType;

  constructor(variableInfo: any) {
    this.variable = variableInfo.variable as string;
    this.variableType = variableInfo.variableType as VariableType;
    if (this.variableType === VariableType.CompanyFundamentals) {
      this.companyFundamentalsType =
        variableInfo.companyFundamentalsType as CompanyFundamentalsType;
    }
  }

  public toViewString() {
    let ret = `${this.variable} : ${enumToString(this.variableType)}`;

    if (
      this.variableType === VariableType.CompanyFundamentals &&
      this.companyFundamentalsType
    ) {
      ret += ` : ${enumToString(this.companyFundamentalsType)}`;
    }

    return ret;
  }
}
