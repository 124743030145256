import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Transcript } from "../models/Transcript";

export const useGetTranscript = () => {
  const {
    data: transcript,
    call,
    isLoading,
  } = useApiPrivateRequest<Transcript>((response) => new Transcript(response));

  const getTranscript = async (id: number) => {
    await call(`/marketData/transcript/${id}`, "GET");
  };

  return { transcript, getTranscript, isLoading };
};
