import { ScrollArea, Center, Loader, Text, Combobox } from "@mantine/core";
import CompanyItem from "../../../shared/components/company-info/CompanyItem";
import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";

interface CSPopoverDropdownProps {
  companies: CompanyInfo[] | null;
  onCompanySelected: (company: CompanyInfo) => void;
  isLoading: boolean;
  isFailed: boolean;
  isSmall?: boolean;
  maxHeight: string;
}

export default function CSComboboxDropdown({
  companies,
  onCompanySelected,
  isLoading,
  isFailed,
  isSmall,
  maxHeight,
}: CSPopoverDropdownProps) {
  return (
    <Combobox.Dropdown data-small={isSmall}>
      <Combobox.Options>
        <ScrollArea.Autosize mah={maxHeight} type="scroll">
          {isLoading && (
            <Center w="100%" mih="5vh">
              <Loader />
            </Center>
          )}
          {isFailed && (
            <Center w="100%" mih="5vh">
              <Text c="red" p={14}>
                Failed to load companies, try again later
              </Text>
            </Center>
          )}
          {companies && companies.length === 0 && (
            <Center w="100%" mih="5vh">
              <Text c="text-secondary.2" p={14}>
                No companies found
              </Text>
            </Center>
          )}
          {companies &&
            companies.length !== 0 &&
            companies.map((company) => (
              <Combobox.Option value={company.companyName} key={company.id}>
                <CompanyItem
                  company={company}
                  onCompanySelected={onCompanySelected}
                  shortVersion={isSmall}
                />
              </Combobox.Option>
            ))}
        </ScrollArea.Autosize>
      </Combobox.Options>
    </Combobox.Dropdown>
  );
}
