import { FunctionCallMessageData } from "./FunctionCallMessageData";

export class AskDocumentMessageData extends FunctionCallMessageData {
  public answer: string;

  constructor(data: any) {
    super(data);
    this.answer = data.answer;
  }
}
