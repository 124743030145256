import { Button, Collapse, Text } from "@mantine/core";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../../shared/utils/UsageAnalytics";
import { useDisclosure } from "@mantine/hooks";

interface StepCommentProps {
  content: string;
}

export default function StepComment({ content }: StepCommentProps) {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    content && (
      <>
        <br />
        <Button
          variant="tertiary"
          size="xs"
          onClick={() => {
            toggle();
            if (!opened)
              sendEvent(EventName.ButtonClick, {
                [EventParameter.Action]: EventTitle.OpenStepExplanation,
              });
          }}
        >
          {opened ? "Hide explanation" : "Show explanation"}
        </Button>
        <Collapse in={opened}>
          <Text c="text-secondary" style={{ whiteSpace: "pre-line" }}>
            {content}
          </Text>
        </Collapse>
      </>
    )
  );
}
