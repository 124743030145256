import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { EarningsCallTranscript } from "../models/EarningsCallTranscript";

export const useSearchEarningsCalls = () => {
  const { data, call } = useApiPrivateRequest<EarningsCallTranscript[]>(
    (response) => response.map((ec: any) => new EarningsCallTranscript(ec)),
  );
  const searchEarningsCalls = async (
    symbol: string | undefined = undefined,
    year: number | undefined = undefined,
    quarter: number | undefined = undefined,
    page: number | undefined = undefined,
    pageSize: number | undefined = undefined,
    ascending: boolean | undefined = undefined,
  ) => {
    await call(`/admin/earnings`, "GET", {
      symbol,
      year,
      quarter,
      page,
      pageSize,
      ascending,
    });
  };
  return { earningsCalls: data, searchEarningsCalls };
};
