import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Chat } from "../../../shared/models/Chat";

export const useGetChat = (chatId: string, userId?: string) => {
  const {
    data: chat,
    setData: setChat,
    call,
  } = useApiPrivateRequest<Chat>((response) => new Chat(response));
  const getChat = async () => {
    await call(`${userId ? "/admin" : ""}/chat/${chatId}`, "GET", { userId });
  };

  return { chat, setChat, getChat };
};
