import { Accordion, rem, Stepper } from "@mantine/core";
import { ChatMessage } from "../models/ChatMessage";
import { MessageDataType } from "../enums/MessageDataType";
import { ModelOutputMessageData } from "../models/message-data/ModelOutputMessageData";
import classes from "../styles/ExecutionPlan.module.css";
import ModelOutputMessage from "./messages/ModelOutputMessage";
import { ExecutionPlanFailedIcon } from "./ExecutionPlanFailedIcon";

interface ExecutionPlanProps {
  messages: ChatMessage[];
}

const functionCallTitles = new Map<string, string>([
  ["ask_company_documents", "Reading through company documents"], // Old function name
  ["ask_agent", "Reading through company documents"],
  ["ask_user_documents", "Reading through uploaded documents"],
  ["filter_documents", "Filtering documents"],
  ["search_companies", "Searching for companies"],
  ["get_market_data", "Getting market data"],
  ["execute_code", "Executing code"],
  ["calculate", "Crunching numbers"],
  ["get_news", "Getting news"],
  ["get_estimates", "Getting estimates"],
]);

export function ExecutionPlan({ messages }: ExecutionPlanProps) {
  const isPlanFinished = messages[messages.length - 1].finishedAt !== undefined;
  const modelMessages = messages.filter(
    (m) => m.data?.type === MessageDataType.ModelOutputMessageData,
  );
  const finishedTitle = "Research completed";
  const workingTitle = "Researching...";
  const accordionTitle = isPlanFinished ? finishedTitle : workingTitle;

  return (
    <Accordion
      variant="contained"
      radius="xs"
      defaultValue="item"
      ml={rem(50)}
      chevronSize={18}
    >
      <Accordion.Item value="item">
        <Accordion.Control
          classNames={{
            control: classes["control"],
            label: classes["label"],
          }}
        >
          {accordionTitle}
        </Accordion.Control>
        <Accordion.Panel
          classNames={{
            panel: classes["accordion-panel"],
            content: classes["accordion-content"],
          }}
        >
          <Stepper
            active={modelMessages.length}
            iconSize={12}
            orientation="vertical"
            styles={{
              stepCompletedIcon: {
                position: "absolute",
                top: -1,
                left: -1,
                height: 10,
                width: 10,
              },
            }}
            classNames={{
              step: classes["step"],
              stepLabel: classes["step-label"],
            }}
          >
            {messages.map((message, index) => {
              if (message.data?.type !== MessageDataType.ModelOutputMessageData)
                return null;

              const data = message.data as ModelOutputMessageData;
              // Function call is always present in this message as other ones are filtered out in MessageList.tsx: 93
              const functionCall = data.functionCall!;
              const functionCallMessage =
                messages.length > index + 1 ? messages[index + 1] : null;
              const isLoading =
                message.finishedAt === undefined ||
                functionCallMessage?.finishedAt === undefined;
              return (
                <Stepper.Step
                  key={message.id}
                  label={functionCallTitles.get(functionCall.name)}
                  description={<ModelOutputMessage data={data} />}
                  completedIcon={
                    functionCallMessage?.error && (
                      <ExecutionPlanFailedIcon
                        error={functionCallMessage.error}
                      />
                    )
                  }
                  loading={isLoading}
                />
              );
            })}
          </Stepper>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
