import { Button, Code, Modal, Table } from "@mantine/core";
import { useGetStepModelCalls } from "../../api/useGetStepModelCalls";
import { useEffect, useState } from "react";
import {
  dateTimeToString,
  timeIntervalToString,
} from "../../../../shared/utils/Dates";
import { useDisclosure } from "@mantine/hooks";

interface StepModelCallsProps {
  chatId: string;
  stepId: string;
}

export default function StepModelCalls({
  chatId,
  stepId,
}: StepModelCallsProps) {
  const { modelCalls, getModelCalls } = useGetStepModelCalls(chatId, stepId);
  const [opened, { open, close }] = useDisclosure(false);
  const [modalContent, setModalContent] = useState<string>("");

  useEffect(() => {
    getModelCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = (content: string) => {
    setModalContent(content);
    open();
  };

  const rows =
    modelCalls?.map((call, i) => (
      <Table.Tr key={i}>
        <Table.Td>{dateTimeToString(call.createdAt)}</Table.Td>
        <Table.Td>
          <Button onClick={() => handleButtonClick(call.prompt)}>Prompt</Button>
        </Table.Td>
        <Table.Td>
          <Button onClick={() => handleButtonClick(call.output)}>Output</Button>
        </Table.Td>
        <Table.Td>{timeIntervalToString(call.durationMs, true)}</Table.Td>
      </Table.Tr>
    )) || [];

  return (
    rows.length > 0 && (
      <>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>CreatedAt</Table.Th>
              <Table.Th>Prompt</Table.Th>
              <Table.Th>Output</Table.Th>
              <Table.Th>Duration</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
        <Modal opened={opened} onClose={close} size="xl">
          <Code block>{modalContent}</Code>
        </Modal>
      </>
    )
  );
}
