import { ChatProcessingStep } from "../ProcessingStep";

export class ModelOutputStep extends ChatProcessingStep {
  public output: string;
  public functionCall: string;

  constructor(step: any) {
    super(step);
    this.output = step.data.output as string;
    this.functionCall = step.data.functionCall as string;
  }

  public get textContent() {
    const result = this.output
      ? this.output + "\n" + this.functionCall
      : this.functionCall;
    return result;
  }
}
