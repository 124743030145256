import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";

export const useSendUserInput = (chatId: string) => {
  const { call, isLoading: isUserInputLoading } =
    useApiPrivateRequest<ChatShort>((response) => new ChatShort(response));
  const sendUserInput = async (input: string) => {
    await call(`/chat/${chatId}`, "POST", undefined, { input: input.trim() });
  };

  return { isUserInputLoading, sendUserInput };
};
