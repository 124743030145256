import { Chart } from "../../../../shared/components/chart/models/Chart";
import { FunctionCallMessageData } from "./FunctionCallMessageData";

export class ExecuteCodeMessageData extends FunctionCallMessageData {
  public data: string;
  public chart?: Chart;

  constructor(data: any) {
    super(data);
    this.data = data.data;
    if (data.chart) {
      this.chart = Chart.parse(data.chart);
    }
  }
}
