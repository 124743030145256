import { NewsResult } from "../../../../models/NewsResult";
import { ChatProcessingStep } from "../ProcessingStep";

export class InterpretationStep extends ChatProcessingStep {
  public interpretation: string;
  public news: NewsResult[];

  constructor(step: any) {
    super(step);
    this.interpretation = step.data.interpretation as string;
    this.news = step.data.news.map((result: any) => new NewsResult(result));
  }

  public get textContent() {
    const news = this.news
      .map(
        (result) => `• ${result.source}: ${result.title}: ${result.sourceUrl}`,
      )
      .join("\n");
    return this.interpretation + "\n\nRelated news:\n\n" + news;
  }
}
