import { useNavigate } from "react-router-dom";
import { Box, Center, Divider, Text, UnstyledButton } from "@mantine/core";
import classes from "../styles/ModeSelector.module.css";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";
import { NewDataChatOption } from "../enums/NewDataChatOption";

interface ModeSelectorProps {
  variant: NewDataChatOption;
  title: string;
  description: string;
  icon: JSX.Element;
}

export default function ModeSelector({
  variant,
  title,
  description,
  icon,
}: ModeSelectorProps) {
  const navigate = useNavigate();

  return (
    <UnstyledButton
      className={classes["mode-selector"]}
      data-variant={variant}
      onClick={() => {
        sendEvent(EventName.ButtonClick, {
          [EventParameter.Action]: EventTitle.ChangeMode,
          [EventParameter.Label]: variant,
        });
        navigate(`/m/${variant}`);
      }}
    >
      <Box className={classes["text-container"]}>
        <Text className={classes["title"]}>{title}</Text>
        <Text className={classes["description"]}>{description}</Text>
      </Box>
      <Divider className={classes["divider"]} />
      <Center className={classes["icon-container"]}>{icon}</Center>
    </UnstyledButton>
  );
}
