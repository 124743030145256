import { CompanyDocumentType } from "../enums/CompanyDocumentType";

export class CompanyDocumentView {
  id: string;
  type: CompanyDocumentType;
  symbol: string;
  companyName: string;
  filedAt?: Date;
  period: string;
  formType: string;
  title: string;
  companyId: number;

  constructor(response: any) {
    this.id = response.id;
    this.type = response.type as CompanyDocumentType;
    this.symbol = response.symbol;
    this.companyName = response.companyName;
    this.filedAt = response.filedAt ? new Date(response.filedAt) : undefined;
    this.period = response.period;
    this.formType = response.formType;
    this.title = response.title;
    this.companyId = response.companyId;
  }
}
