import { PromptType } from "../enums/PromptType";

export class PromptVersionShort {
  public id: string;
  public name: PromptType;
  public createdAt: Date;
  public authorEmail: string;

  public constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.createdAt = new Date(data.createdAt);
    this.authorEmail = data.authorEmail;
  }
}

export class PromptVersion extends PromptVersionShort {
  public description: string | null;
  public text: string;
  public temperature: number;
  public maxTokens: number;

  public constructor(data: any) {
    super(data);
    this.description = data.description;
    this.text = data.text;
    this.temperature = data.temperature;
    this.maxTokens = data.maxTokens;
  }
}
