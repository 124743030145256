import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useRetryChat = (chatId: string) => {
  const { call } = useApiPrivateRequest<void>();
  const retryChat = async () => {
    await call(`/chat/${chatId}/retry`, "PUT");
  };

  return { retryChat };
};
