import { dateTimeToString } from "../../../shared/utils/Dates";
import { Filing } from "../models/Filing";
import ScrollableText from "../../../shared/components/scrollable-text/ScrollableText";

interface FilingContentProps {
  filing: Filing;
}

export default function FilingContent({ filing }: FilingContentProps) {
  if (filing.link) {
    window.location.href = filing.link;
    return <></>;
  }

  return (
    <>
      <h2>{filing.title}</h2>
      <p>{dateTimeToString(filing.filingDate)}</p>
      <ScrollableText content={filing.fullText} />
    </>
  );
}
