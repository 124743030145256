import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useCreateQuestion = () => {
  const {
    data: isCreated,
    call,
    isLoading: isCreating,
    isFailed: isCreateFailed,
  } = useApiPrivateRequest<boolean>((_) => true);
  const createQuestion = async (question: string) => {
    await call("/questionbank", "POST", undefined, { question });
  };

  return {
    isCreated,
    createQuestion,
    isCreating,
    isCreateFailed,
  };
};
