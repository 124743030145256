import App from "./App";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { quantlyTheme } from "./styles/theme";
import { Notifications } from "@mantine/notifications";
import * as amplitude from "@amplitude/analytics-browser";

if (
  import.meta.env.VITE_ENVIRONMENT !== "DEV" &&
  import.meta.env.VITE_GA4_ID &&
  import.meta.env.VITE_HJ_ID &&
  import.meta.env.VITE_HJ_SV &&
  import.meta.env.VITE_AMPLITUDE_ID
) {
  ReactGA.initialize(import.meta.env.VITE_GA4_ID);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  hotjar.initialize(import.meta.env.VITE_HJ_ID, import.meta.env.VITE_HJ_SV);
  amplitude.init(import.meta.env.VITE_AMPLITUDE_ID, { defaultTracking: true });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <BrowserRouter>
      <MantineProvider theme={quantlyTheme}>
        <Notifications limit={10} />
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
          }}
        >
          <App />
        </Auth0Provider>
      </MantineProvider>
    </BrowserRouter>
  </StrictMode>,
);
