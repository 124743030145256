import { Button, Text } from "@mantine/core";

interface AddDocumentsIconProps {
  disabled: boolean;
  open: () => void;
}

export default function AddDocumentsButton({
  disabled,
  open: toggleAddDocuments,
}: AddDocumentsIconProps) {
  return (
    <Button
      variant="secondary"
      px={{ base: 4, xl: 8 }}
      radius="xxs"
      h={{ base: 22, xl: 28 }}
      onClick={toggleAddDocuments}
      disabled={disabled}
    >
      <Text fz={{ base: 11, xl: 14 }}>Add Context</Text>
    </Button>
  );
}
