import { AppShell, rem } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useParams } from "react-router-dom";
import ChatManagement from "../features/chat-management/ChatManagement";
import { HeaderMenu } from "../features/header/HeaderMenu";
import HomePageBody from "../features/home/HomePageBody";
import ExistingChat from "../features/existing-chat/ExistingChat";
import classes from "./styles/Home.module.css";
import { isLargeScreenMediaQuery } from "../shared/utils/ResponsiveStyles";

const HEADER_HEIGHT = rem(52);
const BIG_SCREEN_HEADER_HEIGHT = rem(67);

export default function Home() {
  const { chatId } = useParams();
  const [opened, { toggle }] = useDisclosure(true);
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <AppShell
      header={{
        height: isLargeScreen ? BIG_SCREEN_HEADER_HEIGHT : HEADER_HEIGHT,
      }}
      navbar={{
        width: opened ? { base: 220, xl: 272 } : { base: 49, xl: 60 },
        breakpoint: "xs",
      }}
      classNames={{
        // I don't know how to get this working...
        navbar: opened ? classes["navbar-opened"] : classes["navbar-closed"],
      }}
    >
      <AppShell.Header
        style={{ borderColor: "var(--mantine-color-secondary-hover-5" }}
      >
        <HeaderMenu />
      </AppShell.Header>

      <AppShell.Navbar>
        <ChatManagement chatId={chatId} opened={opened} toggle={toggle} />
      </AppShell.Navbar>

      <AppShell.Main
        h={`calc(100vh - ${
          isLargeScreen ? BIG_SCREEN_HEADER_HEIGHT : HEADER_HEIGHT
        })`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {chatId ? <ExistingChat chatId={chatId} /> : <HomePageBody />}
      </AppShell.Main>
    </AppShell>
  );
}
