export class User {
  public id: string;
  public email: string;
  public createdAt: Date;
  public creditLimit: number;
  public creditsUsed: number;
  public chatsCount: number;

  constructor(obj: any) {
    this.id = obj.id;
    this.email = obj.email;
    this.createdAt = new Date(obj.createdAt);
    this.creditLimit = obj.creditLimit;
    this.creditsUsed = obj.creditsUsed;
    this.chatsCount = obj.chatsCount;
  }
}
