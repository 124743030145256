import { useDisclosure } from "@mantine/hooks";
import { Button, Collapse, Group, Text } from "@mantine/core";
import { CodeHighlight } from "@mantine/code-highlight";
import { GetMarketDataSqlQueryStep } from "../../models/chat-steps/GetMarketDataSqlQueryStep";

interface MarketDataSqlQueryProps {
  step: GetMarketDataSqlQueryStep;
}

export default function MarketDataSqlQuery({ step }: MarketDataSqlQueryProps) {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <>
      <Group justify="space-between" mt={10} mb={10}>
        <Text>SQL query executed by the model and its result </Text>
        <Button variant="tertiary" size="xs" onClick={toggle}>
          {opened ? "Hide code" : "Show code"}
        </Button>
      </Group>
      <Collapse in={opened}>
        <CodeHighlight
          maw="100%"
          language="sql"
          code={step.sqlQuery + "\n=================\n" + step.sqlQueryResult}
        />
      </Collapse>
    </>
  );
}
