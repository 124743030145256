import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatUserContent } from "../../existing-chat/models/ChatUserContent";

export const useGetChatUserContent = () => {
  const { data: userContents, call } = useApiPrivateRequest<ChatUserContent[]>(
    (response) => response.map((chat: any) => new ChatUserContent(chat)),
  );
  const getUserContents = async (chatId: string) => {
    await call(`/chat/${chatId}/userContent`, "GET");
  };

  return { userContents, getUserContents };
};
