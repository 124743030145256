import { Text } from "@mantine/core";
import { NewsLoadingStep } from "../../models/chat-steps/NewsLoadingStep";
import classes from "../../styles/ExistingChat.module.css";

interface NewsLoadingProps {
  step: NewsLoadingStep;
}

export default function NewsLoading({ step }: NewsLoadingProps) {
  return (
    <>
      {step.news.length !== 0 ? (
        <Text>
          <br />
          Related news:
          <ul>
            {step.news.map((news, index) => (
              <li key={index}>
                <a
                  href={news.sourceUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={classes["news-link"]}
                >
                  {news.source}: {news.title}
                </a>
              </li>
            ))}
          </ul>
        </Text>
      ) : (
        <Text>No related news found.</Text>
      )}
    </>
  );
}
