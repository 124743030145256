import { ChartClassification, ChartType } from "../enums/ChartType";
import { HeatmapOptions, Options, SimpleOptions } from "./Options";
import { SeriesElement, SimpleSeriesElement } from "./SeriesElement";

export abstract class Chart {
  public type: ChartType;
  public series: SeriesElement[] | string[][];
  public options: Options | string[];

  constructor(chart: any) {
    this.type = chart.type as ChartType;
    this.series = chart.series;
    this.options = chart.options;
  }

  public static parse(chart: any): Chart {
    if (!chart) return new SimpleChart({ series: [], options: {} }); // will result in empty chart with text "No data available"
    switch (chart.type as ChartClassification) {
      case ChartClassification.Simple:
        return new SimpleChart(chart);
      case ChartClassification.Heatmap:
        return new HeatmapChart(chart);
      case ChartClassification.Table:
        return new TableChart(chart);
      default:
        throw new Error("Unknown chart type: " + chart.type);
    }
  }
}

export class SimpleChart extends Chart {
  public series: SimpleSeriesElement[];
  public options: SimpleOptions;

  protected getChartType() {
    const uniqueTypes = new Set(this.series.map((serie) => serie.type));
    return uniqueTypes.size === 1 ? [...uniqueTypes][0] : ChartType.Line;
  }

  constructor(chart: any) {
    super(chart);
    this.series = chart.series.map(
      (serie: any) => new SimpleSeriesElement(serie),
    );
    this.type = this.getChartType();
    this.options = new SimpleOptions(chart.options, this.type);
  }
}

export class HeatmapChart extends Chart {
  public series: SeriesElement[];
  public options: HeatmapOptions;

  constructor(chart: any) {
    super(chart);
    this.options = new HeatmapOptions(chart.options);
    this.series = chart.series.map((serie: any) => new SeriesElement(serie));
  }
}

export class TableChart extends Chart {
  public caption: string;
  public series: string[][];
  public options: string[];

  constructor(chart: any) {
    super(chart);
    this.caption = chart.caption;
    this.series = chart.series;
    this.options = chart.options;
  }
}
