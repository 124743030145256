import { rem, AppShell } from "@mantine/core";
import { HeaderMenu } from "../features/header/HeaderMenu";
import ChatManagement from "../features/chat-management/ChatManagement";
import ExistingChat from "../features/existing-chat/ExistingChat";
import { useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";

const HEADER_HEIGHT = rem(67);

export default function AdminUserChats() {
  const { userId, chatId } = useParams();
  const [opened, { toggle }] = useDisclosure(true);

  return (
    <AppShell
      header={{ height: HEADER_HEIGHT }}
      navbar={{
        width: opened ? rem(272) : rem(60),
        breakpoint: "md",
      }}
    >
      <AppShell.Header
        style={{ borderColor: "var(--mantine-color-secondary-hover-5" }}
      >
        <HeaderMenu />
      </AppShell.Header>

      <AppShell.Navbar>
        <ChatManagement
          chatId={chatId}
          opened={opened}
          toggle={toggle}
          userId={userId}
        />
      </AppShell.Navbar>

      <AppShell.Main
        h={`calc(100vh - ${HEADER_HEIGHT})`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {chatId && <ExistingChat chatId={chatId} userId={userId} />}
      </AppShell.Main>
    </AppShell>
  );
}
