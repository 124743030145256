import { rem, Text } from "@mantine/core";
import { UserInputStep } from "../../models/chat-steps/UserInputStep";
import useSmallScreen from "../../../../shared/hooks/useSmallScreen";

interface UserInputProps {
  step: UserInputStep;
}

export default function UserInput({ step }: UserInputProps) {
  const { isSmallWidth } = useSmallScreen();
  return (
    <Text fw={500} fz={isSmallWidth ? rem(14) : "md"}>
      {step.input}
    </Text>
  );
}
