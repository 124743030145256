import { ChatProcessingStep } from "../../models/ProcessingStep";
import { StepType } from "../../enums/StepType";
import { AnalysisInputsIdentificationStep } from "../../models/chat-steps/AnalysisInputsIdentificationStep";
import { CodeExecutionStep } from "../../models/chat-steps/CodeExecutionStep";
import { CodeGenerationStep } from "../../models/chat-steps/CodeGenerationStep";
import { DataLoadingStep } from "../../models/chat-steps/DataLoadingStep";
import { GetMarketDataSqlQueryStep } from "../../models/chat-steps/GetMarketDataSqlQueryStep";
import { InterpretationStep } from "../../models/chat-steps/InterpretationStep";
import { ModelOutputStep } from "../../models/chat-steps/ModelOutputStep";
import { NewsLoadingStep } from "../../models/chat-steps/NewsLoadingStep";
import { SecFilingSectionLoadingStep } from "../../models/chat-steps/SecFilingSectionLoadingStep";
import { UnhandledErrorStep } from "../../models/chat-steps/UnhandledErrorStep";
import { UserInputStep } from "../../models/chat-steps/UserInputStep";
import AnalysisInputsIdentification from "../steps/AnalysisInputsIdentificationStep";
import CodeExecution from "../steps/CodeExecution";
import CodeGeneration from "../steps/CodeGeneration";
import DataLoading from "../steps/DataLoading";
import Interpretation from "../steps/Interpretation";
import MarketDataSqlQuery from "../steps/MarketDataSqlQuery";
import ModelOutput from "../steps/ModelOutput";
import NewsLoading from "../steps/NewsLoading";
import SecFilingSectionLoading from "../steps/SecFilingSectionLoading";
import UnhandledError from "../steps/UnhandledError";
import UserInput from "../steps/UserInput";
import ExternalContextUserContent from "../steps/ExternalContextUserContent";
import { ExternalContextUserContentStep } from "../../models/chat-steps/ExternalContextUserContentStep";
import ExternalContextMultipleModelsUserContent from "../steps/ExternalContextMultipleModelsUserContent";
import { ExternalContextMultipleModelsUserContentStep } from "../../models/chat-steps/ExternalContextMultipleModelsUserContentStep";
import ExternalContextSeparateQuestionsUserContent from "../steps/ExternalContextSeparateQuestionsUserContent";
import { ExternalContextSeparateQuestionsUserContentStep } from "../../models/chat-steps/ExternalContextSeparateQuestionsUserContentStep";
import DocumentSearchUserContent from "../steps/DocumentSearchUserContent";
import { DocumentSearchUserContentStep } from "../../models/chat-steps/DocumentSearchUserContentStep";

interface AnalysisStepProps {
  step: ChatProcessingStep;
}

export default function AnalysisStep({ step }: AnalysisStepProps) {
  switch (step.stepType as StepType) {
    case StepType.DataLoading:
      return <DataLoading step={step as DataLoadingStep} />;
    case StepType.CodeGeneration:
      return <CodeGeneration step={step as CodeGenerationStep} />;
    case StepType.CodeExecution:
      return <CodeExecution step={step as CodeExecutionStep} />;
    case StepType.Interpretation:
      return <Interpretation step={step as InterpretationStep} />;
    case StepType.UnhandledError:
      return <UnhandledError step={step as UnhandledErrorStep} />;
    case StepType.AnalysisInputsIdentification:
      return (
        <AnalysisInputsIdentification
          step={step as AnalysisInputsIdentificationStep}
        />
      );
    case StepType.UserInput:
      return <UserInput step={step as UserInputStep} />;
    case StepType.ModelOutput:
      return <ModelOutput step={step as ModelOutputStep} />;
    case StepType.NewsLoading:
      return <NewsLoading step={step as NewsLoadingStep} />;
    case StepType.SecFilingSectionLoading:
      return (
        <SecFilingSectionLoading step={step as SecFilingSectionLoadingStep} />
      );
    case StepType.MarketDataSqlQuery:
      return <MarketDataSqlQuery step={step as GetMarketDataSqlQueryStep} />;
    case StepType.ExternalContextUserContent:
      return (
        <ExternalContextUserContent
          step={step as ExternalContextUserContentStep}
        />
      );
    case StepType.ExternalContextMultipleModelsUserContent:
      return (
        <ExternalContextMultipleModelsUserContent
          step={step as ExternalContextMultipleModelsUserContentStep}
        />
      );
    case StepType.ExternalContextSeparateQuestionsUserContent:
      return (
        <ExternalContextSeparateQuestionsUserContent
          step={step as ExternalContextSeparateQuestionsUserContentStep}
        />
      );
    case StepType.DocumentSearch:
      return (
        <DocumentSearchUserContent
          step={step as DocumentSearchUserContentStep}
        />
      );
  }
}
