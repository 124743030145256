import {
  ActionIcon,
  Group,
  rem,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import classes from "../styles/QuestionItem.module.css";
import {
  IconCheck,
  IconEditCircle,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { useDeleteQuestion } from "../api/useDeleteQuestion";
import { useEffect, useState } from "react";
import {
  CustomEvent,
  useEventTrigger,
} from "../../../shared/hooks/useEventManagement";
import { useUpdateQuestion } from "../api/useUpdateQuestion";

interface QuestionItemProps {
  id: string;
  question: string;
  setInput: (input: string) => void;
}

export default function QuestionItem({
  id,
  question,
  setInput,
}: QuestionItemProps) {
  const { isDeleted, deleteQuestion } = useDeleteQuestion();
  const { isUpdated, updateQuestion } = useUpdateQuestion();
  const [isEditing, setIsEditing] = useState(false);
  const [updatedQuestion, setUpdatedQuestion] = useState(question);
  const dispatchEvent = useEventTrigger();

  const iconProps = { strokeWidth: 1.5, size: 16 };

  const handleDeleteClick = () => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      deleteQuestion(id);
    }
  };

  useEffect(() => {
    if (!isDeleted) return;
    dispatchEvent(CustomEvent.RefreshQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted]);

  const handleClick = () => {
    if (isEditing) return;
    setInput(question);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setUpdatedQuestion(question);
  };

  const handleSaveClick = () => {
    updateQuestion(id, updatedQuestion);
  };

  useEffect(() => {
    if (!isUpdated) return;
    setIsEditing(false);
    dispatchEvent(CustomEvent.RefreshQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  return (
    <Stack className={classes["stack"]} onClick={handleClick}>
      <Group justify="space-between">
        {!isEditing && <Text className={classes["text"]}>{question}</Text>}
        {isEditing && (
          <Textarea
            w="90%"
            radius="xs"
            value={updatedQuestion}
            onChange={(event) => setUpdatedQuestion(event.currentTarget.value)}
          >
            {question}
          </Textarea>
        )}
        <Group gap={rem(4)}>
          {!isEditing && (
            <Tooltip label="Delete" withArrow radius="xxs">
              <ActionIcon
                variant="transparent"
                color="tertiary"
                onClick={handleDeleteClick}
              >
                <IconTrash {...iconProps} />
              </ActionIcon>
            </Tooltip>
          )}
          {!isEditing && (
            <Tooltip label="Edit" withArrow radius="xxs">
              <ActionIcon
                variant="transparent"
                color="tertiary"
                onClick={handleEditClick}
              >
                <IconEditCircle {...iconProps} />
              </ActionIcon>
            </Tooltip>
          )}
          {isEditing && (
            <Tooltip label="Cancel" withArrow radius="xxs">
              <ActionIcon
                variant="transparent"
                color="tertiary"
                onClick={handleCancelClick}
              >
                <IconX {...iconProps} />
              </ActionIcon>
            </Tooltip>
          )}
          {isEditing && (
            <Tooltip label="Save" withArrow radius="xxs">
              <ActionIcon
                variant="transparent"
                color="tertiary"
                onClick={handleSaveClick}
              >
                <IconCheck {...iconProps} />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      </Group>
    </Stack>
  );
}
