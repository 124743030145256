import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useGetCompanies = () => {
  const {
    data: companies,
    call,
    isLoading,
    isFailed,
    setData,
  } = useApiPrivateRequest<CompanyInfo[]>((response) =>
    response.map((item: any) => new CompanyInfo(item)),
  );

  const getCompanies = async (companyIds: number[]) => {
    await call(`/marketdata/companies`, "GET", {
      companyIds,
    });
  };

  return {
    companies,
    getCompanies,
    isLoading,
    isFailed,
    setCompanies: setData,
  };
};
