import { CompanyDocumentType } from "../../../shared/enums/CompanyDocumentType";

export class CompanyDocumentAnswer {
  public id: string;
  public type: CompanyDocumentType;
  public question: string;
  public answer: string;

  constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.question = data.question;
    this.answer = data.answer;
  }
}
