import { Button, Text } from "@mantine/core";

interface QuestionBankIconProps {
  disabled: boolean;
  toggleQuestionBank: () => void;
}

export default function QuestionBankButton({
  disabled,
  toggleQuestionBank,
}: QuestionBankIconProps) {
  return (
    <Button
      variant="secondary"
      px={{ base: 4, xl: 8 }}
      radius="xxs"
      h={{ base: 22, xl: 28 }}
      disabled={disabled}
      onClick={toggleQuestionBank}
    >
      <Text fz={{ base: 11, xl: 14 }}>Question Bank</Text>
    </Button>
  );
}
