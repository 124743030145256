import { Button, SimpleGrid } from "@mantine/core";
import { ChatMode } from "../../../shared/enums/ChatMode";
import { useGetChatModeSuggestions } from "../api/useGetChatModeSuggestions";
import { useEffect, useState } from "react";
import { ChatModeSuggestion } from "../models/ChatModeSuggestion";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";
import classes from "../styles/ChatModeSuggestions.module.css";
import { SecondaryChatMode } from "../../../shared/enums/SecondaryChatMode";

interface ChatModeSuggestionsProps {
  mode: ChatMode;
  secondaryMode?: SecondaryChatMode;
  processSuggestionSend: (suggestion: ChatModeSuggestion) => Promise<void>;
}

export default function ChatModeSuggestions({
  mode,
  secondaryMode,
  processSuggestionSend,
}: ChatModeSuggestionsProps) {
  const { suggestions, getSuggestions } = useGetChatModeSuggestions(mode);
  const [isLoading, setIsLoading] = useState<number | undefined>(undefined);

  const [visibleSuggestions, setVisibleSuggestions] = useState<
    ChatModeSuggestion[]
  >([]);

  useEffect(() => {
    getSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!suggestions || suggestions.length === 0) return;

    if (secondaryMode !== null && secondaryMode !== undefined) {
      setVisibleSuggestions(
        suggestions
          .filter((suggestion) => suggestion.secondaryMode === secondaryMode)
          .sort(() => 0.5 - Math.random())
          .slice(0, 4),
      );
    } else {
      setVisibleSuggestions(
        suggestions.sort(() => 0.5 - Math.random()).slice(0, 4),
      );
    }
  }, [mode, secondaryMode, suggestions]);

  const handleSuggestionClick = async (suggestion: ChatModeSuggestion) => {
    setIsLoading(visibleSuggestions.indexOf(suggestion));
    sendEvent(EventName.ButtonClick, {
      [EventParameter.Action]: EventTitle.ExampleChat,
      [EventParameter.Label]: `${mode}: ${suggestion.input}`,
    });
    await processSuggestionSend(suggestion);
    setIsLoading(undefined);
  };

  return (
    visibleSuggestions &&
    visibleSuggestions.length > 0 && (
      <SimpleGrid cols={{ base: 1, sm: 2 }}>
        {visibleSuggestions.map((suggestion, index) => (
          <Button
            key={index}
            variant="secondary"
            classNames={classes}
            disabled={isLoading !== undefined && isLoading !== index}
            loading={isLoading === index}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            {suggestion.input}
          </Button>
        ))}
      </SimpleGrid>
    )
  );
}
