import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useStopChat = (chatId: string) => {
  const { call, isLoading: isStopChatLoading } = useApiPrivateRequest<void>();
  const stopChat = async () => {
    await call(`/chat/${chatId}/stop`, "PUT");
  };

  return { isStopChatLoading, stopChat };
};
