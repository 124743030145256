import { logger } from "../../../shared/utils/Logger";

export class FunctionCall {
  public id?: string;
  public name: string;
  public arguments: any;

  public constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    try {
      this.arguments = JSON.parse(data.arguments);
    } catch (e) {
      logger.error("Error parsing arguments for function call", e);
    }
  }
}
