import { Box, Group, Modal, ScrollArea, Stack, Text } from "@mantine/core";
import classes from "../styles/UserDocumentLink.module.css";
import { IconFileTypePdf } from "@tabler/icons-react";
import { ChatUserContent } from "../../existing-chat/models/ChatUserContent";
import ScrollableText from "../../../shared/components/scrollable-text/ScrollableText";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface UserDocumentLinkProps {
  document: ChatUserContent;
}

export default function UserDocumentLink({ document }: UserDocumentLinkProps) {
  const [opened, { open, close }] = useDisclosure(false);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <>
      <Box onClick={open}>
        <Group className={classes["link-group"]}>
          <IconFileTypePdf
            color="var(--mantine-color-primary-5)"
            size={isLargeScreen ? 20 : 18}
          />
          <Text className={classes["link-text"]}>{document.title}</Text>
        </Group>
      </Box>
      <Modal
        opened={opened}
        onClose={close}
        title="Content"
        radius="xs"
        scrollAreaComponent={ScrollArea.Autosize}
        classNames={{
          content: classes["user-content-modal-content"],
          title: classes["user-content-modal-title"],
          body: classes["user-content-modal-body"],
        }}
      >
        <Stack>
          <>
            <Group>
              <Text truncate className={classes["doc-title"]}>
                {document.title}
              </Text>
              {document.url && (
                <a
                  href={document.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Link to the file
                </a>
              )}
            </Group>
            <ScrollableText content={document.content} />
          </>
        </Stack>
      </Modal>
    </>
  );
}
