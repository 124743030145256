export class EarningsCallTranscript {
  public id: number;
  public symbol: string;
  public quarter: number;
  public year: number;
  public date: Date;
  public content: string;

  constructor(data: any) {
    this.id = data.id;
    this.symbol = data.symbol;
    this.quarter = data.quarter;
    this.year = data.year;
    this.date = new Date(data.date);
    this.content = data.content;
  }
}
