import { Text } from "@mantine/core";
import { UnhandledErrorStep } from "../../models/chat-steps/UnhandledErrorStep";

interface UnhandledErrorProps {
  step: UnhandledErrorStep;
}

export default function UnhandledError({ step }: UnhandledErrorProps) {
  return (
    <Text>
      {step.message || "An unhandled error occurred during processing."}
    </Text>
  );
}
