import React from "react";
import { ActionIcon } from "@mantine/core";
import GradientIcon from "./GradientIcon";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../utils/ResponsiveStyles";

interface GradientActionIconProps {
  icon: React.ReactElement;
  isActive?: boolean;
  onClick: () => void;
  className?: string;
}

export default function GradientActionIcon({
  icon,
  isActive = true,
  onClick,
  className,
}: GradientActionIconProps) {
  const iconWithTertiaryColor = React.cloneElement(icon, {
    style: { color: "#295c8b" },
  });
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <ActionIcon
      variant="gradient-border"
      data-inactive={!isActive}
      radius={4}
      size={isLargeScreen ? 40 : 36}
      onClick={onClick}
      className={className}
    >
      {isActive ? (
        <GradientIcon icon={icon} size={isLargeScreen ? "20" : "16"} />
      ) : (
        iconWithTertiaryColor
      )}
    </ActionIcon>
  );
}
