import { rem, Group, Button, TextInput, Space, Box } from "@mantine/core";
import { useEffect } from "react";
import { IconChevronLeft, IconSearch } from "@tabler/icons-react";
import classes from "./styles/QuestionBank.module.css";
import { useSearchQuestions } from "./api/useSearchQuestions";
import {
  CustomEvent,
  useEventListener,
} from "../../shared/hooks/useEventManagement";
import QuestionList from "./components/QuestionList";
import { useDebouncedState } from "@mantine/hooks";

interface QuestionBankProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  setInput: (input: string) => void;
}

export default function QuestionBank({
  opened,
  setOpened,
  setInput,
}: QuestionBankProps) {
  const {
    questions,
    searchQuestions,
    areQuestionsLoading,
    areQuestionsFailed,
  } = useSearchQuestions();
  const [searchValue, setSearchValue] = useDebouncedState("", 300);

  useEffect(() => {
    if (!opened || questions || areQuestionsLoading) return;

    searchQuestions(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, questions, areQuestionsLoading]);

  useEventListener(CustomEvent.RefreshQuestions, () => {
    searchQuestions(searchValue);
  });

  const clearPopover = () => {
    setOpened(false);
    setSearchValue("");
  };

  return (
    <Box className={classes["question-bank-root"]}>
      <Group justify="space-between">
        <Button
          variant="plain-dark"
          leftSection={<IconChevronLeft />}
          onClick={() => setOpened(false)}
        >
          Back
        </Button>
        <TextInput
          value={searchValue}
          onChange={(event) => {
            setSearchValue(event.currentTarget.value);
          }}
          w={rem(260)}
          placeholder="Search"
          leftSectionWidth={rem(50)}
          leftSection={<IconSearch color="var(--mantine-color-black)" />}
          disabled={questions?.length === 0}
          classNames={{
            input: classes["textinput-input"],
            section: classes["textinput-section"],
          }}
        />
      </Group>
      <Space h={rem(8)} />
      <QuestionList
        questions={questions}
        emptyMessage={
          questions || searchValue
            ? "No questions found"
            : "This is your question bank. You can save questions you frequently ask for easier access and send them instantly!"
        }
        isLoading={areQuestionsLoading}
        isFailed={areQuestionsFailed}
        setInput={(input: string) => {
          setInput(input);
          clearPopover();
        }}
      />
    </Box>
  );
}
