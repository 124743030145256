import { Code, Collapse, Button, ScrollArea } from "@mantine/core";
import { useHasAdminRole } from "../../../shared/hooks/useHasRole";
import { useAdminView } from "../../../shared/hooks/useAdminView";
import { useDisclosure } from "@mantine/hooks";
import JsonFormatter from "react-json-formatter";

interface FunctionCallProps {
  functionCall?: any;
}

export default function FunctionCall({ functionCall }: FunctionCallProps) {
  const isAdmin = useHasAdminRole();
  const [isAdminView] = useAdminView();
  const [opened, { toggle }] = useDisclosure(false);

  try {
    functionCall.arguments = JSON.parse(functionCall.arguments);
  } catch {
    /* empty */
  }

  return (
    <>
      {isAdmin && isAdminView && functionCall && (
        <>
          <Button variant="tertiary" size="xs" onClick={toggle}>
            {opened ? "Hide function call" : "Show function call"}
          </Button>
          <Collapse in={opened}>
            <ScrollArea.Autosize mah={400}>
              <Code>
                <JsonFormatter json={JSON.stringify(functionCall)} />
              </Code>
            </ScrollArea.Autosize>
          </Collapse>
        </>
      )}
    </>
  );
}
