export function enumToString(
  enumValue: string,
  firstUpper: boolean = true,
  secondUpper: boolean = false,
): string {
  return enumValue
    .split(/(?=[A-Z])/)
    .map((word, index) => {
      if (index === 0 && firstUpper) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      } else if (index === 1 && secondUpper) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word.toLowerCase();
      }
    })
    .join(" ");
}
