import classes from "../styles/Settings.module.css";
import { rem, Modal, Stack } from "@mantine/core";
import SettingsItem from "./SettingsItem";
import { useGetSettings } from "../api/useGetSettings";
import { useEffect } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface SettingsProps {
  opened: boolean;
  close: () => void;
}

export default function Settings({ opened, close }: SettingsProps) {
  const { settings, getSettings, setSettings } = useGetSettings();

  useEffect(() => {
    if (opened) getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  const handleSettingChange = (key: string, value: string) => {
    setSettings((prevSettings) => {
      if (!prevSettings) return prevSettings;
      const newSettings = [...prevSettings];
      const settingIndex = newSettings.findIndex(
        (setting) => setting.key === key,
      );
      newSettings[settingIndex].value = value;
      return newSettings;
    });
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Modal
      classNames={{
        content: classes["modal-content"],
        header: classes["modal-header"],
        body: classes["modal-body"],
        title: classes["modal-title"],
      }}
      opened={opened}
      onClose={close}
      title="Settings"
      centered
    >
      <Stack gap={isLargeScreen ? rem(12) : rem(6)}>
        {settings &&
          settings.map((setting) => (
            <SettingsItem
              setting={setting}
              key={setting.label}
              onChange={handleSettingChange}
            />
          ))}
      </Stack>
    </Modal>
  );
}
