import { Stack, Center } from "@mantine/core";
import classes from "./styles/ChatFooter.module.css";
import UserInput from "./components/UserInput";
import { useState } from "react";

interface ChatFooterProps {
  chatId?: string;
  inProgress?: boolean;
  children?: React.ReactNode;
  processUserInput?: (
    input: string,
    companyIds?: number[],
    filingIds?: string[],
    transcriptIds?: number[],
    files?: File[],
    useNews?: boolean,
  ) => Promise<void>;
  disabled?: boolean;
  allowEmptyInput?: boolean;
  useNewsDefault?: boolean;
}

export default function ChatFooter({
  chatId,
  inProgress,
  children,
  disabled,
  processUserInput,
  allowEmptyInput = false,
  useNewsDefault,
}: ChatFooterProps) {
  const [showChildren, setShowChildren] = useState<boolean>(true);
  return (
    <Stack className={classes["chat-footer"]}>
      {children && showChildren && <Center>{children}</Center>}
      <UserInput
        chatId={chatId}
        inProgress={inProgress}
        processUserInput={processUserInput}
        disabled={disabled}
        allowEmpty={allowEmptyInput}
        setShowChildren={setShowChildren}
        useNewsDefault={useNewsDefault}
      />
    </Stack>
  );
}
