import { Stack, rem } from "@mantine/core";
import { ChatShort } from "../../../shared/models/Chat";
import ChatItem from "./ChatItem";

interface ChatItemListProps {
  chats: ChatShort[];
  chatId?: string;
  userId?: string;
}

export default function ChatItemList({
  chats,
  chatId,
  userId,
}: ChatItemListProps) {
  return (
    <Stack gap={rem(2)} pb={rem(10)}>
      {chats.map((item) => (
        <ChatItem
          key={item.id}
          chat={item}
          isActive={item.id === chatId}
          userId={userId}
        />
      ))}
    </Stack>
  );
}
