import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";

export class CompanyDocText extends CompanyDocumentView {
  public chunkId?: string;
  public content: string;

  constructor(data: any) {
    super(data);
    this.chunkId = data.chunkId;
    this.content = data.content;
  }
}
