import { useEffect } from "react";
import { ContentWithCitations } from "./models/ContentWithCitations";
import { useListState } from "@mantine/hooks";
import UserDocumentLinkGroup from "./UserDocumentLinkGroup";
import { CitationSourceType } from "../../shared/enums/CitationSourceType";

interface ContentDocumentsProps {
  content: string;
}

export default function ContentDocuments({ content }: ContentDocumentsProps) {
  const [filingIds, { append: appendFilingIds }] = useListState<string>();
  const [transcriptIds, { append: appendTranscriptIds }] =
    useListState<number>();
  const [seenDocumentIds, { append: appendSeenDocumentIds }] =
    useListState<string>();

  useEffect(() => {
    // Extract citations from the content
    const contentWithCitations = new ContentWithCitations(content);

    // Fetch the sources for the citations
    const filingsToGet = contentWithCitations.citations
      .filter(
        (c) =>
          c.sourceType === CitationSourceType.Filing &&
          !seenDocumentIds.includes(c.documentId),
      )
      .map((c) => c.documentId);
    if (filingsToGet.length > 0) {
      appendFilingIds(...filingsToGet);
      appendSeenDocumentIds(...filingsToGet);
    }

    const transcriptsToGet = contentWithCitations.citations
      .filter(
        (c) =>
          c.sourceType === CitationSourceType.Transcript &&
          !seenDocumentIds.includes(c.documentId),
      )
      .map((c) => parseInt(c.documentId));
    if (transcriptsToGet.length > 0) {
      appendTranscriptIds(...transcriptsToGet);
      appendSeenDocumentIds(...transcriptsToGet.map((id) => id.toString()));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <UserDocumentLinkGroup
      filingIds={filingIds}
      transcriptIds={transcriptIds}
      offset={false}
    />
  );
}
