import { ChatProcessingStep } from "../ProcessingStep";

export class GetMarketDataSqlQueryStep extends ChatProcessingStep {
  public sqlQuery: string;
  public sqlQueryResult: string;

  constructor(step: any) {
    super(step);
    this.sqlQuery = step.data.sqlQuery;
    this.sqlQueryResult = step.data.sqlQueryResult;
  }

  public get textContent() {
    return `${this.sqlQuery}  "\n=================\n" ${this.sqlQueryResult}`;
  }
}
