import { ChatProcessingStep } from "../ProcessingStep";
import { UserContent } from "../UserContent";

export class UserContentStep extends ChatProcessingStep {
  public userContent: UserContent[];

  constructor(step: any) {
    super(step);
    this.userContent = step.data.userContent.map(
      (data: any) => new UserContent(data),
    );
  }
}
