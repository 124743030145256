import { useDisclosure } from "@mantine/hooks";
import { Button, Collapse, Group, Text } from "@mantine/core";
import { CodeHighlight } from "@mantine/code-highlight";
import { DocumentSearchUserContentStep } from "../../models/chat-steps/DocumentSearchUserContentStep";

interface DocumentSearchUserContentProps {
  step: DocumentSearchUserContentStep;
}

export default function DocumentSearchUserContent({
  step,
}: DocumentSearchUserContentProps) {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <>
      <Group justify="space-between" mt={10} mb={10}>
        <Text>Search performed against the document </Text>
        <Button variant="tertiary" size="xs" onClick={toggle}>
          {opened ? "Hide details" : "Show details"}
        </Button>
      </Group>
      <Collapse in={opened}>
        <CodeHighlight
          maw="100%"
          language="markdown"
          code={step.query + "\n=================\n" + step.results}
        />
      </Collapse>
    </>
  );
}
