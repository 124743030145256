import { Select } from "@mantine/core";
import classes from "../styles/Settings.module.css";
import { SettingsDataItem } from "../models/UserSettings";
import { useUpdateSettings } from "../api/useUpdateSettings";
import { useState } from "react";

interface SettingsItemProps {
  setting: SettingsDataItem;
  onChange: (title: string, value: string) => void;
}

export default function SettingsItem({ setting, onChange }: SettingsItemProps) {
  const [, setValue] = useState<string>(setting.value);
  const { updateSettings } = useUpdateSettings(setting.key);

  const handleChange = async (newValue: string | null) => {
    if (!newValue) return;
    setValue(newValue);
    onChange(setting.key, newValue);
    await updateSettings(newValue);
  };

  return (
    <Select
      classNames={{
        input: classes["select-input"],
      }}
      label={setting.label}
      data={setting.possibleValues ?? setting.possibleStringValues}
      value={setting.value}
      onChange={handleChange}
      allowDeselect={false}
    />
  );
}
