import { CompanyDocumentAnswer } from "../CompanyDocumentAnswer";
import { FunctionCallMessageData } from "./FunctionCallMessageData";

export class AskCompanyDocumentsMessageData extends FunctionCallMessageData {
  public answers: CompanyDocumentAnswer[];

  constructor(data: any) {
    super(data);
    this.answers = data.answers.map(
      (answer: any) => new CompanyDocumentAnswer(answer),
    );
  }
}
