import { Flex, Notification } from "@mantine/core";
import StepList from "./components/step-related/StepList";
import { useGetChat } from "./api/useGetChat";
import { useEffect, useState } from "react";
import { useChatUpdates } from "../../shared/hooks/useChatUpdates";
import { ChatUpdateType } from "../../shared/enums/ChatUpdateType";
import ChatFooter from "../chat-footer/ChatFooter";
import { useSendUserInput } from "./api/useSendUserInput";
import AnalysisHeader from "./components/AnalysisHeader";
import { ChatMode } from "../../shared/enums/ChatMode";
import { useHasAdminRole, useHasRole } from "../../shared/hooks/useHasRole";
import { ChatProcessingStep } from "./models/ProcessingStep";
import { Chat } from "../../shared/models/Chat";
import MessagesList from "./components/MessagesList";
import { ChatMessage } from "./models/ChatMessage";
import { MessageProcessor } from "./models/MessageDataProcessor";
import { useGetStep } from "./api/useGetStep";
import { StepProcessor } from "../../shared/utils/StepProcessor";
import { ModelOutputStep } from "./models/chat-steps/ModelOutputStep";
import { useSendUserInputV2 } from "./api/useSendUserInputV2";
import { useGetMessages } from "./api/useGetMessages";
import { UserRole } from "../../shared/enums/UserRole";
import CenterLoader from "../../shared/components/loader/CenterLoader";
import classes from "./styles/ExistingChat.module.css";
import { useLocalStorage } from "@mantine/hooks";
import { useExportChat } from "./api/useExportChat";
import SideDrawer from "./components/SideDrawer";
import { LocalStorageKeys } from "../../shared/enums/LocalStorageKeys";
import AdminViewSwitch from "./components/AdminViewSwitch";

interface ExistingChatProps {
  chatId: string;
  userId?: string;
}

export default function ExistingChat({ chatId, userId }: ExistingChatProps) {
  const chatDisabled = useHasRole(UserRole.ChatDisabled);
  const { chat, getChat } = useGetChat(chatId, userId);
  const [inProgress, setInProgress] = useState(chat?.inProgress || false);
  const [steps, setSteps] = useState<ChatProcessingStep[]>([]);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { messages: updatedMessages, getMessages } = useGetMessages(chatId);
  const { step: updatedStep, getStep } = useGetStep(chatId);
  const [hasError, setHasError] = useState(false);
  const isAdmin = useHasAdminRole();
  const [download, , clearDownload] = useLocalStorage({
    key: LocalStorageKeys.DownloadChatOnFinish,
  });
  const { exportChat } = useExportChat();

  const setChatProps = function (chat?: Chat) {
    setInProgress(chat?.inProgress || false);
    setSteps(chat?.steps || []);
    setMessages(chat?.messages || []);
    setHasError(chat?.steps.some((step) => step.failed) || false);
    if (chat?.finishedAt && download === chatId) {
      clearDownload();
    }
  };

  useEffect(() => {
    // Clear all the states before fetching the chat
    setChatProps();
    getChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (!chat) return;
    setChatProps(chat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  useEffect(() => {
    if (!updatedMessages) return;
    setMessages(updatedMessages);
  }, [updatedMessages]);

  useEffect(() => {
    if (!updatedStep) return;
    setSteps((prevSteps) =>
      StepProcessor.ProccessUpdatesStep(prevSteps, updatedStep),
    );
    setHasError(updatedStep.failed);
  }, [updatedStep]);

  const { sendUserInput } = useSendUserInput(chatId);
  const { sendUserInputV2 } = useSendUserInputV2(chatId);

  useEffect(() => {
    if (!chat) return;
    if (!chat.finishedAt && !inProgress && download === chatId) {
      exportChat(chatId);
      clearDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  useChatUpdates(chatId, async (type, data) => {
    switch (type) {
      case ChatUpdateType.ChatStarted:
        setInProgress(true);
        break;
      case ChatUpdateType.ChatCompleted:
        setInProgress(false);
        break;

      case ChatUpdateType.ChatStepUpdated:
        await getStep(data.id);
        break;
      case ChatUpdateType.StepContentUpdated: {
        const stepId = data.id;
        // Get step from steps by id
        const updatedStep = steps.find(
          (step) => step.id === stepId,
        ) as ModelOutputStep;
        if (!updatedStep) return;
        updatedStep.completionPercentage = 50;
        updatedStep.output = (updatedStep?.output || "") + data.content;
        setSteps((prevSteps) =>
          StepProcessor.ProccessUpdatesStep(prevSteps, updatedStep),
        );
        break;
      }

      case ChatUpdateType.MessageUpdated:
        await getMessages();
        break;
      case ChatUpdateType.MessageContentUpdated: {
        const messageId = data.id;
        const content = data.content;
        setMessages((prev) =>
          MessageProcessor.ProcessContentUpdate(prev, messageId, content),
        );
        break;
      }
    }
  });

  return (
    <Flex className={classes["root"]}>
      <Flex className={classes["main"]}>
        {!chat && <CenterLoader />}
        {chat && (
          <>
            {chat.mode === ChatMode.Analysis && (
              <AnalysisHeader
                chat={chat}
                steps={steps}
                hasError={hasError}
                inProgress={inProgress}
              />
            )}
            {isAdmin && <AdminViewSwitch />}
            {download === chatId && (
              <Notification
                loading
                withBorder
                onClose={clearDownload}
                title="Playbook will be downloaded once completed"
              />
            )}
            {steps.length > 0 && <StepList steps={steps} chat={chat} />}
            {messages.length > 0 && (
              <MessagesList messages={messages} chat={chat} />
            )}
            {!userId && !chatDisabled && (
              <ChatFooter
                chatId={chat.id}
                inProgress={inProgress}
                disabled={chat.mode === ChatMode.Analysis && hasError}
                processUserInput={
                  messages.length > 0 ? sendUserInputV2 : sendUserInput
                }
                useNewsDefault={chat.metadata.useNews}
              />
            )}
          </>
        )}
      </Flex>
      <SideDrawer chatId={chatId} />
    </Flex>
  );
}
