import { StepType } from "../enums/StepType";
import { enumToString } from "../../../shared/utils/Enums";
import { AnalysisProgressBarValue } from "../enums/AnalysisProgressBarValue";

export const analysisProgressBarMap = new Map<
  StepType,
  AnalysisProgressBarValue
>([
  [
    StepType.AnalysisInputsIdentification,
    AnalysisProgressBarValue.AnalysingRequest,
  ],
  [StepType.DataLoading, AnalysisProgressBarValue.DataSources],
  [StepType.CodeGeneration, AnalysisProgressBarValue.ProducingChart],
  [StepType.CodeExecution, AnalysisProgressBarValue.ProducingChart],
  [StepType.Interpretation, AnalysisProgressBarValue.Interpretation],
]);

export const analysisProgressBarData = Object.values(
  AnalysisProgressBarValue,
).map((value) => ({
  label: enumToString(value, true, true),
  value,
}));
