export class Filing {
  public id: string;
  public title: string;
  public filingDate?: Date;
  public link?: string;
  public fullText?: string;

  constructor(filing: any) {
    this.id = filing.id;
    this.title = filing.title;
    this.filingDate = filing.filingDate
      ? new Date(filing.filingDate)
      : undefined;
    this.link = filing.link;
    this.fullText = filing.fullText;
  }
}
