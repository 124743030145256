import { useEffect, useState } from "react";
import {
  rem,
  Box,
  Text,
  Group,
  Stack,
  Center,
  HoverCard,
  ScrollArea,
} from "@mantine/core";
import classes from "./styles/CitationHoverCard.module.css";
import CompanyDocumentLink from "./components/CompanyDocumentLink";
import { Citation } from "./models/Citation";
import { useGetSource } from "./api/useGetSource";
import { useGetNews } from "./api/useGetNews";
import NewsLink from "./components/NewsLink";
import { CitationSourceType } from "../../shared/enums/CitationSourceType";
import CenterLoader from "../../shared/components/loader/CenterLoader";

interface CitationHoverCardProps {
  index: number;
  citation: Citation;
}

export default function CitationHoverCard({
  index,
  citation,
}: CitationHoverCardProps) {
  const [isHoverCardOpen, setIsHoverCardOpen] = useState(false);
  const { sourceType, documentId, chunkId } = citation;
  const { source, getSource, isLoading: isSourceLoading } = useGetSource();
  const { news, getNews, isLoading: isNewsLoading } = useGetNews();

  useEffect(() => {
    // Fetch source only when hover card is open and source is not already fetched
    if (!isHoverCardOpen || source) return;
    if (sourceType === CitationSourceType.News) {
      getNews(documentId);
    } else {
      getSource(sourceType, documentId, chunkId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHoverCardOpen, sourceType, documentId, chunkId]);

  return (
    <HoverCard
      onOpen={() => setIsHoverCardOpen(true)}
      onClose={() => setIsHoverCardOpen(false)}
      width={rem(571)}
      closeDelay={100}
      position="right"
      radius={rem(6)}
      keepMounted
      classNames={{
        dropdown: classes["hovercard-dropdown"],
      }}
    >
      <HoverCard.Target>
        <Box className={classes["box"]} data-active={isHoverCardOpen}>
          {index + 1}
        </Box>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Group className={classes["group"]}>
          <Center className={`${classes["center"]} ${classes["text"]}`}>
            {index + 1}
          </Center>
          <Stack className={classes["stack"]}>
            {(isSourceLoading || isNewsLoading) && <CenterLoader />}
            {source && (
              <>
                <ScrollArea.Autosize
                  mah={rem(300)}
                  scrollbars="y"
                  offsetScrollbars
                  scrollbarSize={4}
                >
                  <Text
                    className={`${classes["citation-text"]} ${classes["text"]}`}
                  >
                    {`“${source.content}”`}
                  </Text>
                </ScrollArea.Autosize>
                <Box py={rem(4)}>
                  <CompanyDocumentLink document={source} chunkId={chunkId} />
                </Box>
              </>
            )}
            {news && (
              <>
                <Text
                  className={`${classes["news-source-text"]} ${classes["text"]}`}
                >
                  {`${news.source}`}
                </Text>
                <ScrollArea.Autosize
                  mah={rem(300)}
                  scrollbars="y"
                  offsetScrollbars
                  scrollbarSize={4}
                >
                  <Text
                    className={`${classes["citation-text"]} ${classes["text"]}`}
                  >
                    {`“${news.summary}”`}
                  </Text>
                </ScrollArea.Autosize>
                <Box py={rem(4)}>
                  <NewsLink title={news.title} url={news.sourceUrl} />
                </Box>
              </>
            )}
          </Stack>
        </Group>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
