import {
  rem,
  Pill,
  Menu,
  Input,
  Badge,
  Button,
  Select,
  ActionIcon,
  Autocomplete,
  SegmentedControl,
  createTheme,
} from "@mantine/core";
import { variantColorResolver } from "./variantColorResolver";
import inputClasses from "./Input.module.css";
import buttonClasses from "./Button.module.css";
import badgeClasses from "./Badge.module.css";
import selectAndAutocompleteClasses from "./SelectAndAutocomplete.module.css";
import actionIconClasses from "./ActionIcon.module.css";
import segmentedControlClasses from "./SegmentedControl.module.css";
import pillClasses from "./Pill.module.css";
import menuClasses from "./Menu.module.css";

export const quantlyTheme = createTheme({
  fontFamily: "Inter, sans-serif",
  fontSizes: {
    xxs: rem(9),
    xs: rem(10),
    sm: rem(12),
    md: rem(14),
    lg: rem(16),
    xl: rem(18),
    xxl: rem(20),
  },
  defaultRadius: "sm",
  radius: {
    xxs: rem(4),
    xs: rem(8),
    sm: rem(18),
    md: rem(46),
    lg: rem(57),
    xl: rem(77),
    xxl: rem(93),
  },
  headings: {
    sizes: {
      h4: {
        fontWeight: "700",
        fontSize: rem(18),
        lineHeight: "normal",
      },
      h5: {
        fontWeight: "700",
        fontSize: rem(16),
        lineHeight: "normal",
      },
      h6: {
        fontWeight: "700",
        fontSize: rem(14),
        lineHeight: "normal",
      },
    },
  },
  breakpoints: {
    xs: "36em",
    sm: "48em",
    md: "62em",
    lg: "75em",
    xl: "107em",
  },
  colors: {
    base: [
      "#ffffff",
      "#d2d2d2",
      "#b3b3b3",
      "#686868",
      "#424242",
      "#1c1c1c",
      "#171717",
      "#131313",
      "#0e0e0e",
      "#090909",
    ],
    primary: [
      "#80bce5",
      "#6ea8d3",
      "#5d95c1",
      "#4c82af",
      "#3a6f9d",
      "#295c8b",
      "#234f79",
      "#1d4267",
      "#163554",
      "#102843",
    ],
    "primary-hover": [
      "#99cdf1",
      "#85bfe8",
      "#71b1de",
      "#5ca3d4",
      "#4884b1",
      "#295c8b",
      "#1f456d",
      "#193859",
      "#132b45",
      "#0d1f32",
    ],
    secondary: [
      "#fefefe",
      "#fdfdfd",
      "#fbfbfc",
      "#fafafc",
      "#f9f9fb",
      "#f8f8fa",
      "#cfcfd0",
      "#a5a5a7",
      "#7c7c7d",
      "#535353",
    ],
    "secondary-hover": [
      "#f5f6f7",
      "#eff1f2",
      "#e7e9ec",
      "#dfe2e6",
      "#d7dbdf",
      "#cfd4d9",
      "#acb1b5",
      "#8a8d91",
      "#686a6d",
      "#454748",
    ],
    tertiary: [
      "#e6e6e6",
      "#d5d5d5",
      "#bfbfbf",
      "#aaaaaa",
      "#959595",
      "#808080",
      "#6b6b6b",
      "#555555",
      "#404040",
      "#2b2b2b",
    ],
    success: [
      "#dafccc",
      "#c1faaa",
      "#a2f780",
      "#83f455",
      "#64f22b",
      "#45ef00",
      "#3ac700",
      "#2e9f00",
      "#237800",
      "#175000",
    ],
    error: [
      "#ffe8e6",
      "#ffd9d5",
      "#ffc5bf",
      "#ffb2aa",
      "#ff9f95",
      "#ff8c80",
      "#d4756b",
      "#aa5d55",
      "#804640",
      "#552f2b",
    ],
    generic: [
      "#f5f6f7",
      "#eff1f2",
      "#e7e9ec",
      "#dfe2e6",
      "#d7dbdf",
      "#cfd4d9",
      "#acb1b5",
      "#8a8d91",
      "#686a6d",
      "#454748",
    ],
    analytic: [
      "#fffdfb",
      "#fffcf9",
      "#fffaf5",
      "#fff9f2",
      "#fff7ef",
      "#fff6ec",
      "#ffefde",
      "#aaa49d",
      "#807b76",
      "#55524f",
    ],
    sec: [
      "#fbfffb",
      "#f9fff9",
      "#f6fff6",
      "#f3fff2",
      "#f0ffef",
      "#edffec",
      "#c5d4c5",
      "#9eaa9d",
      "#778076",
      "#2f332f",
    ],
    pdf: [
      "#fcfcff",
      "#fafaff",
      "#f7f7ff",
      "#f5f4ff",
      "#f2f2ff",
      "#f0efff",
      "#e0dfff",
      "#a09faa",
      "#787880",
      "#505055",
    ],
    "text-primary": [
      "#ffffff",
      "#d2d2d2",
      "#b3b3b3",
      "#686868",
      "#424242",
      "#1c1c1c",
      "#171717",
      "#131313",
      "#0e0e0e",
      "#090909",
    ],
    "text-secondary": [
      "#e6e6e6",
      "#d5d5d5",
      "#bfbfbf",
      "#aaaaaa",
      "#959595",
      "#808080",
      "#6b6b6b",
      "#555555",
      "#404040",
      "#2b2b2b",
    ],
    "text-disabled": [
      "#f5f6f7",
      "#eff1f2",
      "#e7e9ec",
      "#dfe2e6",
      "#d7dbdf",
      "#cfd4d9",
      "#acb1b5",
      "#8a8d91",
      "#686a6d",
      "#454748",
    ],
    "text-link": [
      "#73A7D8",
      "#6797C5",
      "#5B86B1",
      "#4F769E",
      "#43668B",
      "#365577",
      "#2A4564",
      "#1E3551",
      "#12243D",
      "#06142A",
    ],
  },
  black: "#1c1c1c",
  primaryColor: "primary",
  primaryShade: 5,
  components: {
    Button: Button.extend({
      classNames: buttonClasses,
      vars: (theme, props) => {
        if (props.size === "sm") {
          return {
            root: {
              "--button-height": rem(40),
              "--button-padding-x": rem(12),
              "--button-fz": rem(12),
            },
          };
        }
        if (props.size === "md") {
          return {
            root: {
              "--button-height": rem(48),
              "--button-padding-x": rem(20),
              "--button-fz": rem(14),
            },
          };
        }
        if (props.size === "lg") {
          return {
            root: {
              "--button-height": rem(64),
              "--button-padding-x": rem(20),
              "--button-fz": rem(14),
            },
          };
        }
        return { root: {} };
      },
    }),
    Input: Input.extend({ classNames: inputClasses }),
    ActionIcon: ActionIcon.extend({ classNames: actionIconClasses }),
    Badge: Badge.extend({ classNames: badgeClasses }),
    Select: Select.extend({ classNames: selectAndAutocompleteClasses }),
    Autocomplete: Autocomplete.extend({
      classNames: selectAndAutocompleteClasses,
    }),
    SegmentedControl: SegmentedControl.extend({
      classNames: segmentedControlClasses,
    }),
    Pill: Pill.extend({ classNames: pillClasses }),
    Menu: Menu.extend({ classNames: menuClasses }),
  },
  variantColorResolver,
});
