import { PromptVersion } from "../models/PromptVersion";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { PromptType } from "../enums/PromptType";

export const useGetLatestVersion = () => {
  const {
    data: latestVersion,
    call,
    isLoading,
    isFailed,
    setData: setLatestVersion,
  } = useApiPrivateRequest<PromptVersion>(
    (response) => new PromptVersion(response),
  );

  const getLatestVersion = async (name: PromptType) => {
    await call(`/admin/prompt/${name}`, "GET");
  };

  return {
    latestVersion,
    getLatestVersion,
    isLoading,
    isFailed,
    setLatestVersion,
  };
};
