import { StepType } from "../../features/existing-chat/enums/StepType";
import { ChatProcessingStep } from "../../features/existing-chat/models/ProcessingStep";
import { AnalysisInputsIdentificationStep } from "../../features/existing-chat/models/chat-steps/AnalysisInputsIdentificationStep";
import { CodeExecutionStep } from "../../features/existing-chat/models/chat-steps/CodeExecutionStep";
import { CodeGenerationStep } from "../../features/existing-chat/models/chat-steps/CodeGenerationStep";
import { DataLoadingStep } from "../../features/existing-chat/models/chat-steps/DataLoadingStep";
import { GenericProgressStep } from "../../features/existing-chat/models/chat-steps/GenericProgressStep";
import { GetMarketDataSqlQueryStep } from "../../features/existing-chat/models/chat-steps/GetMarketDataSqlQueryStep";
import { InterpretationStep } from "../../features/existing-chat/models/chat-steps/InterpretationStep";
import { ModelOutputStep } from "../../features/existing-chat/models/chat-steps/ModelOutputStep";
import { NewsLoadingStep } from "../../features/existing-chat/models/chat-steps/NewsLoadingStep";
import { SecFilingSectionLoadingStep } from "../../features/existing-chat/models/chat-steps/SecFilingSectionLoadingStep";
import { UnhandledErrorStep } from "../../features/existing-chat/models/chat-steps/UnhandledErrorStep";
import { UserContentStep } from "../../features/existing-chat/models/chat-steps/UserContentStep";
import { UserInputStep } from "../../features/existing-chat/models/chat-steps/UserInputStep";
import { ExternalContextUserContentStep } from "../../features/existing-chat/models/chat-steps/ExternalContextUserContentStep";
import { ExternalContextMultipleModelsUserContentStep } from "../../features/existing-chat/models/chat-steps/ExternalContextMultipleModelsUserContentStep";
import { ExternalContextSeparateQuestionsUserContentStep } from "../../features/existing-chat/models/chat-steps/ExternalContextSeparateQuestionsUserContentStep";
import { DocumentSearchUserContentStep } from "../../features/existing-chat/models/chat-steps/DocumentSearchUserContentStep";

export class StepProcessor {
  public static parse(step: any): ChatProcessingStep {
    if (step.completionPercentage !== 100) return new GenericProgressStep(step);

    switch (step.stepType as StepType) {
      case StepType.DataLoading:
        return new DataLoadingStep(step);
      case StepType.CodeGeneration:
        return new CodeGenerationStep(step);
      case StepType.CodeExecution:
        return new CodeExecutionStep(step);
      case StepType.Interpretation:
        return new InterpretationStep(step);
      case StepType.UnhandledError:
        return new UnhandledErrorStep(step);
      case StepType.AnalysisInputsIdentification:
        return new AnalysisInputsIdentificationStep(step);
      case StepType.UserInput:
        return new UserInputStep(step);
      case StepType.ModelOutput:
        return new ModelOutputStep(step);
      case StepType.NewsLoading:
        return new NewsLoadingStep(step);
      case StepType.SecFilingSectionLoading:
        return new SecFilingSectionLoadingStep(step);
      case StepType.UserContent:
        return new UserContentStep(step);
      case StepType.MarketDataSqlQuery:
        return new GetMarketDataSqlQueryStep(step);
      case StepType.ExternalContextUserContent:
        return new ExternalContextUserContentStep(step);
      case StepType.ExternalContextMultipleModelsUserContent:
        return new ExternalContextMultipleModelsUserContentStep(step);
      case StepType.ExternalContextSeparateQuestionsUserContent:
        return new ExternalContextSeparateQuestionsUserContentStep(step);
      case StepType.DocumentSearch:
        return new DocumentSearchUserContentStep(step);
    }
  }

  public static ProccessUpdatesStep(
    prevSteps: ChatProcessingStep[],
    updatedStep: ChatProcessingStep,
  ): ChatProcessingStep[] {
    const existingStepIndex = prevSteps.findIndex(
      (s) => s.id === updatedStep.id,
    );
    if (existingStepIndex !== -1) {
      prevSteps[existingStepIndex] = updatedStep;
    } else {
      prevSteps.push(updatedStep);
    }
    const sortedSteps = prevSteps.sort(
      (a, b) => +new Date(a.startedAt) - +new Date(b.startedAt),
    );
    return [...sortedSteps];
  }

  public static IsVisible(step: ChatProcessingStep): boolean {
    switch (step.stepType as StepType) {
      case StepType.MarketDataSqlQuery:
      case StepType.ExternalContextUserContent:
      case StepType.ExternalContextMultipleModelsUserContent:
      case StepType.ExternalContextSeparateQuestionsUserContent:
      case StepType.DocumentSearch:
      case StepType.CodeGeneration:
        return step.inProgress || step.failed;
      case StepType.ModelOutput: {
        const modelOutputStep = step as ModelOutputStep;
        return (
          modelOutputStep.inProgress ||
          modelOutputStep.failed ||
          !modelOutputStep.functionCall
        );
      }
      default:
        return true;
    }
  }
}
