import { rem, Center, Switch } from "@mantine/core";
import { useAdminView } from "../../../shared/hooks/useAdminView";

export default function AdminViewSwitch() {
  const [isAdminView, toggleAdminView] = useAdminView();

  return (
    <Center py={rem(5)}>
      <Switch
        checked={isAdminView}
        onChange={() => toggleAdminView(!isAdminView)}
        label={isAdminView ? "Admin view on" : "Admin view off"}
      />
    </Center>
  );
}
