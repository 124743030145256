import { ChatMessageData } from "../ChatMessageData";

export class FunctionCallMessageData extends ChatMessageData {
  public functionCall: any;

  constructor(data: any) {
    super(data);
    this.functionCall = data;
  }
}
