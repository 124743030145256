import { useApiPrivateRequest } from "../hooks/useApi";
import { CompanyInfo } from "../components/company-info/models/CompanyInfo";

export const useGetCompany = () => {
  const {
    data: company,
    call,
    isLoading,
    setData: setCompany,
  } = useApiPrivateRequest<CompanyInfo>(
    (response) => new CompanyInfo(response),
  );

  const getCompany = async (companyId: number) => {
    await call(`/marketdata/companies/${companyId}`, "GET");
  };

  return { company, getCompany, isLoading, setCompany };
};
