import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiPrivateRequest } from "../shared/hooks/useApi";

export default function AdminChatLayer() {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { data: userId, call } = useApiPrivateRequest<string>();
  const getUserId = async () => {
    await call(`/admin/chat/${chatId}/userId`, "GET");
  };

  useEffect(() => {
    getUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId) {
      navigate(`/admin/user/${userId}/chat/${chatId}`);
    }
  }, [userId, navigate, chatId]);

  return <></>;
}
