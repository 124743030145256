export const toRecord = (props: any): Record<string, unknown> => {
  return Object.entries(props).reduce(
    (acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>,
  );
};
