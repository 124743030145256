import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";

export const useGetTranscripts = () => {
  const { data: transcripts, call } = useApiPrivateRequest<
    CompanyDocumentView[]
  >((response) =>
    response.map((document: any) => new CompanyDocumentView(document)),
  );
  const getTranscripts = async (transcriptIds: number[]) => {
    await call(`/marketData/transcript`, "GET", { transcriptIds });
  };

  return { transcripts, getTranscripts };
};
