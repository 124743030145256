import { VariableMarketData } from "../../../../models/VariableMarketData";
import { FunctionCallMessageData } from "./FunctionCallMessageData";

export class GetMarketDataMessageData extends FunctionCallMessageData {
  public data: VariableMarketData[];

  constructor(data: any) {
    super(data);
    this.data = data.data.map(
      (variableMarketData: any) => new VariableMarketData(variableMarketData),
    );
  }
}
