import { useState } from "react";
import { Stack, Group, Textarea, ActionIcon, rem } from "@mantine/core";
import classes from "../styles/PlaybookStep.module.css";
import { IconTrash } from "@tabler/icons-react";
import { EditableTextInput } from "../../../shared/components/editable-text/EditableTextInput";
import { PlaybookStepShort } from "../model/Playbook";

interface PlaybookStepProps {
  step: PlaybookStepShort;
  initialStep: PlaybookStepShort;
  updateSection: (id: string, newTitle: string, newPrompt: string) => void;
  removeSection: () => void;
}

export default function PlaybookStepEdit({
  step,
  initialStep,
  updateSection,
  removeSection,
}: PlaybookStepProps) {
  const [newTitle, setNewTitle] = useState(step.title);
  const [newPrompt, setNewPrompt] = useState(step.prompt);

  return (
    <Stack gap={rem(8)} p={rem(8)}>
      <Group justify="space-between">
        <EditableTextInput
          text={newTitle}
          placeholder="Please enter a valid title"
          textClassname={classes["title-text"]}
          inputClassname={classes["editable-textinput"]}
          onSave={(value: string) => {
            if (value !== step.title) {
              setNewTitle(value);
              updateSection(step.id, value, newPrompt);
            }
          }}
        />
        <ActionIcon
          variant="light"
          radius="xs"
          bg="var(--mantine-color-secondary-5)"
          onClick={removeSection}
        >
          <IconTrash
            size="1.125rem"
            stroke={1.5}
            color="var(--mantine-color-black)"
          />
        </ActionIcon>
      </Group>
      <Textarea
        value={newPrompt}
        onChange={(event) => {
          setNewPrompt(event.currentTarget.value);
          updateSection(step.id, newTitle, event.currentTarget.value);
        }}
        placeholder="Please enter a valid prompt"
        radius="xxs"
        autosize
        minRows={1}
        maxRows={6}
        error={newPrompt === ""}
        data-haschanged={newPrompt !== initialStep.prompt && newPrompt !== ""}
        classNames={{
          input: classes["textarea-input"],
        }}
        autoFocus
      />
    </Stack>
  );
}
