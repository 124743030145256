import { Text, Center } from "@mantine/core";
import classes from "../styles/ModeBadge.module.css";
import { chatModeAttributes } from "../../../shared/utils/ChatModeAttributes";
import { ChatMode } from "../../../shared/enums/ChatMode";

interface ModeBadgeProps {
  isPlaybook: boolean;
  playbookTitle: string | null;
  showText?: boolean;
}

export default function ModeBadgePlaybook({
  isPlaybook,
  playbookTitle,
  showText = true,
}: ModeBadgeProps) {
  return (
    <Center className={classes["container"]}>
      {isPlaybook
        ? chatModeAttributes[ChatMode.Playbook].icon
        : chatModeAttributes[ChatMode.Generic].icon}
      {showText && (
        <Text className={classes["title"]}>
          {isPlaybook ? playbookTitle : "Chat"}
        </Text>
      )}
    </Center>
  );
}
