import { useEffect, useState } from "react";
import { useGetChats } from "./api/useGetChats";
import { ChatMode } from "../../shared/enums/ChatMode";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Group,
  TextInput,
  rem,
  Center,
  ScrollArea,
  Flex,
  Text,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import ChatItemList from "./components/ChatItemList";
import CollapseBurger from "./components/CollapseBurger";
import NewChatButton from "./components/NewChatButton";
import classes from "./styles/ChatManagement.module.css";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../shared/utils/ResponsiveStyles";
import {
  CustomEvent,
  useEventListener,
} from "../../shared/hooks/useEventManagement";

interface ChatHistoryProps {
  chatId?: string;
  userId?: string;
  opened: boolean;
  toggle: () => void;
}

export default function ChatManagement({
  chatId,
  userId,
  opened,
  toggle,
}: ChatHistoryProps) {
  const navigate = useNavigate();
  const { chats, getChats } = useGetChats(userId);
  const [value, setValue] = useState("");
  // This prevent chat list from flickering when the chat list is updated
  const [cachedChats, setCachedChats] = useState(chats);
  const [filteredCachedChats, setFilteredCachedChats] = useState(chats);
  const chatModes: ChatMode[] =
    import.meta.env.VITE_QUANTLY_MODE === "DOCS"
      ? [
          ChatMode.CompanyDocs,
          ChatMode.EarningsCall,
          ChatMode.Sec,
          ChatMode.Pdf,
          ChatMode.CompanyDocsV2,
        ]
      : [ChatMode.Generic, ChatMode.Analysis];

  useEffect(() => {
    getChats({ chatModes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (!chats) return;
    setCachedChats(chats);
    setFilteredCachedChats(chats);
  }, [chats]);

  const newChatHandler = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!cachedChats || cachedChats.length === 0) return;

    if (value === "") {
      setFilteredCachedChats(cachedChats);
      return;
    }

    setFilteredCachedChats(
      cachedChats.filter((chat) =>
        chat.input.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  }, [value, cachedChats]);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  useEventListener(CustomEvent.RefreshChats, () => {
    getChats({ chatModes });
  });

  return (
    <nav className={classes["history-navbar"]} data-collapsed={!opened}>
      {opened ? (
        <>
          <CollapseBurger opened={opened} toggle={toggle} />
          <Flex className={classes["flex-history-container"]}>
            <Group className={classes["group-button-container"]}>
              {!userId && <NewChatButton onClick={newChatHandler} />}
              <TextInput
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
                w={{ base: rem(165), xl: rem(201) }}
                radius={0}
                placeholder="Search"
                leftSectionWidth={isLargeScreen ? rem(50) : rem(30)}
                leftSection={
                  <IconSearch
                    color="var(--mantine-color-black)"
                    size={isLargeScreen ? 18 : 15}
                  />
                }
                size={isLargeScreen ? "md" : "sm"}
                classNames={{
                  input: classes["textinput-input"],
                }}
              />
            </Group>
            {filteredCachedChats &&
              filteredCachedChats.length === 0 &&
              value !== "" && (
                <Center w="100%" mt={rem(10)}>
                  <Text>No chats found</Text>
                </Center>
              )}

            {filteredCachedChats && filteredCachedChats.length > 0 && (
              <ScrollArea.Autosize h={`calc(100% - ${rem(90)})`} scrollbars="y">
                <ChatItemList
                  chats={filteredCachedChats}
                  chatId={chatId}
                  userId={userId}
                />
              </ScrollArea.Autosize>
            )}
          </Flex>
        </>
      ) : (
        <Box className={classes["box-button-container"]}>
          <CollapseBurger opened={opened} toggle={toggle} />
          <Flex className={classes["new-chat-button-container"]}>
            {!userId && <NewChatButton onClick={newChatHandler} />}
          </Flex>
        </Box>
      )}
    </nav>
  );
}
