import { SearchResult } from "../SearchResult";
import { FunctionCallMessageData } from "./FunctionCallMessageData";

export class FTSMessageData extends FunctionCallMessageData {
  public searchResults: SearchResult[];

  constructor(data: any) {
    super(data);
    this.searchResults = data.searchResults.map(
      (searchResult: any) => new SearchResult(searchResult),
    );
  }
}
