import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";
import { useGetSource } from "../../sources/api/useGetSource";
import { CitationIndex } from "../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import {
  Group,
  Center,
  Stack,
  ScrollArea,
  rem,
  Box,
  Text,
  ActionIcon,
} from "@mantine/core";
import CompanyDocumentLink from "../../sources/components/CompanyDocumentLink";
import classes from "../../../features/sources/styles/CitationHoverCard.module.css";
import { IconX } from "@tabler/icons-react";

interface CitationOverviewProps {
  citation: CitationIndex;
  close: () => void;
}

export function CitationOverview({ citation, close }: CitationOverviewProps) {
  const { source, getSource } = useGetSource();
  const [, , clearSidebarCitation] = useLocalStorage<CitationIndex>({
    key: LocalStorageKeys.SidebarCitation,
  });
  const memoizedCitation = useMemo(() => citation, [citation]);

  const handleCloseClick = () => {
    clearSidebarCitation();
    close();
  };

  useEffect(() => {
    getSource(
      memoizedCitation.sourceType,
      memoizedCitation.documentId,
      memoizedCitation.chunkId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <Stack h="97%">
      <Group justify="space-between">
        <Center className={`${classes["center"]} ${classes["text"]}`}>
          {memoizedCitation.index + 1}
        </Center>
        {source && (
          <CompanyDocumentLink
            document={source}
            chunkId={memoizedCitation.chunkId}
          />
        )}
        <ActionIcon onClick={handleCloseClick} variant="simple">
          <IconX />
        </ActionIcon>
      </Group>
      <Stack h="100%">
        {source && (
          <>
            <ScrollArea.Autosize
              scrollbars="y"
              offsetScrollbars
              scrollbarSize={4}
            >
              <Text fz="sm">{source.content}</Text>
            </ScrollArea.Autosize>
            <Box py={rem(4)}></Box>
          </>
        )}
      </Stack>
    </Stack>
  );
}
