import { ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, Avatar } from "@mantine/core";
import classes from "../../styles/UserInputMessageShell.module.css";

interface UserInputStepShellProps {
  chatId: string;
  children: ReactNode;
}

export default function UserInputStepShell({
  children,
}: UserInputStepShellProps) {
  const { user } = useAuth0();
  return (
    <Flex className={classes["flex-container"]}>
      <Avatar variant="avatar" radius="xxs" size={24}>
        {user!.name!.charAt(0).toUpperCase()}
      </Avatar>
      <Box className={classes["text-container"]}>{children}</Box>
    </Flex>
  );
}
