import { useLocation, useParams } from "react-router-dom";
import TranscriptBody from "../features/transcripts/TranscriptBody";
import CustomAppShell from "../shared/components/app-shell/CustomAppShell";

export default function Transcript() {
  const { transcriptId } = useParams();

  const location = useLocation();

  // Function to get chunkId from the URL query parameters
  const getChunkIdFromQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    const chunkId = searchParams.get("chunkId");
    return chunkId ? parseInt(chunkId) : undefined;
  };

  const chunkId = getChunkIdFromQuery();

  return (
    <CustomAppShell>
      {transcriptId && (
        <TranscriptBody id={parseInt(transcriptId)} chunkId={chunkId} />
      )}
    </CustomAppShell>
  );
}
