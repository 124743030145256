import { ChatMode } from "../../../shared/enums/ChatMode";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";

interface GetChatProps {
  chatModes?: ChatMode[];
  page?: number;
  perPage?: number;
  companyId?: number;
}

export const useGetChats = (userId?: string) => {
  const { data: chats, call } = useApiPrivateRequest<ChatShort[]>((response) =>
    response.map((chat: any) => new ChatShort(chat)),
  );
  const getChats = async (props: GetChatProps) => {
    await call(`${userId ? "/admin" : ""}/chat`, "GET", {
      userId,
      ...props,
    });
  };

  return { chats, getChats };
};
