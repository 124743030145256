export class NewsResult {
  public imageUrl: string;
  public source: string;
  public sourceUrl: string;
  public summary: string;
  public title: string;
  public timePublished: Date;

  constructor(response: any) {
    this.imageUrl = response.imageUrl;
    this.source = response.source;
    this.sourceUrl = response.sourceUrl;
    this.summary = response.summary;
    this.title = response.title;
    this.timePublished = new Date(response.timePublished);
  }
}
