import { ActionIcon } from "@mantine/core";
import { IconFileDownload } from "@tabler/icons-react";
import { ChatProcessingStep } from "../../models/ProcessingStep";
import { useGetMarketDataFile } from "../../api/useGetMarketDataFile";

interface DownloadButtonProps {
  chatId: string;
  step: ChatProcessingStep;
}

export default function DownloadButton({ chatId, step }: DownloadButtonProps) {
  const { isLoading, getFileData } = useGetMarketDataFile();

  return (
    <ActionIcon
      variant="simple"
      size="sm"
      disabled={step.failed || step.inProgress}
      loading={isLoading}
      onClick={() => getFileData(chatId, step.id)}
    >
      <IconFileDownload size="1.25rem" />
    </ActionIcon>
  );
}
