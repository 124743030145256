import { ChatMessageData } from "./ChatMessageData";
import { MessageProcessor } from "./MessageDataProcessor";

export class ChatMessage {
  public id: string;
  public error?: string;
  public startedAt: Date;
  public finishedAt?: Date;
  public data?: ChatMessageData;

  constructor(message: any, messages: any) {
    this.id = message.id;
    this.error = message.error;
    this.startedAt = new Date(message.createdAt);
    if (message.finishedAt) {
      this.finishedAt = new Date(message.finishedAt);
    }
    if (message.data) {
      this.data = MessageProcessor.ParseData(message.data, messages);
    }
  }

  public get inProgress() {
    return this.finishedAt === undefined;
  }

  public get failed() {
    return !!this.error;
  }

  public get timeInterval() {
    //plus operator converts Date to number
    return this.finishedAt ? +this.finishedAt - +this.startedAt : undefined;
  }

  public get textContent(): string | null {
    return null;
  }
}
