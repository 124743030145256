import { useEffect } from "react";

export function useResetHash(dependancy: any): void {
  useEffect(() => {
    if (!dependancy) return;
    setTimeout(() => {
      const hash = window.location.hash;
      if (hash) {
        window.location.hash = ""; // Clear the hash
        window.location.hash = hash; // Reset it to trigger fragment scroll
      }
    }, 0);
  }, [dependancy]);
}
