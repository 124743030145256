import { rem, AppShell } from "@mantine/core";
import { HeaderMenu } from "../../../features/header/HeaderMenu";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../utils/ResponsiveStyles";

const HEADER_HEIGHT = rem(52);
const BIG_SCREEN_HEADER_HEIGHT = rem(67);

interface CustomAppShellProps {
  navBar?: React.ReactNode;
  children: React.ReactNode;
}

export default function CustomAppShell({
  navBar: _navBar,
  children,
}: CustomAppShellProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);
  return (
    <AppShell
      header={{
        height: isLargeScreen ? BIG_SCREEN_HEADER_HEIGHT : HEADER_HEIGHT,
      }}
    >
      <AppShell.Header
        style={{ borderColor: "var(--mantine-color-secondary-hover-5)" }}
      >
        <HeaderMenu />
      </AppShell.Header>
      <AppShell.Main
        h={`calc(100vh - ${isLargeScreen ? BIG_SCREEN_HEADER_HEIGHT : HEADER_HEIGHT})`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {children}
      </AppShell.Main>
    </AppShell>
  );
}
