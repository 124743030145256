import { rem, Text, Image, Group, Center, SimpleGrid } from "@mantine/core";
import ModeSelector from "./components/ModeSelector";
import classes from "./styles/NewDataChat.module.css";
import logo from "../../../public/images/logoDark.svg";
import ChatFooter from "../chat-footer/ChatFooter";
import {
  newDataChatOptionAttributes,
  NewDataChatOption,
} from "./enums/NewDataChatOption";

export default function NewDataChat() {
  return (
    <>
      <Center className={classes["header"]}>
        <Group gap={rem(32)}>
          <Image className={classes["header-logo"]} src={logo} />
          <Text className={classes["header-title"]}>
            Choose the type of Chat you’d like to have
          </Text>
        </Group>
      </Center>
      <Center className={classes["mode-buttons-container"]}>
        <SimpleGrid
          cols={{ base: 1, md: 2 }}
          spacing={rem(32)}
          verticalSpacing={rem(32)}
        >
          {Object.entries(newDataChatOptionAttributes).map(
            ([modeKey, modeValue], index) => (
              <ModeSelector
                key={index}
                variant={modeKey as NewDataChatOption}
                title={modeValue.title}
                description={modeValue.description}
                icon={modeValue.icon}
              />
            ),
          )}
        </SimpleGrid>
      </Center>
      <ChatFooter disabled />
    </>
  );
}
