import { Flex, Image } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatFooter from "../../chat-footer/ChatFooter";
import AvailableDataAlert from "./AvailableDataAlert";
import ChatModeSuggestions from "./ChatModeSuggestions";
import logo from "../../../../public/images/logoMonoBlack.svg";
import { ChatMode } from "../../../shared/enums/ChatMode";
import { ChatModeSuggestion } from "../models/ChatModeSuggestion";
import classes from "../styles/NewGenericAndAnalysisChat.module.css";
import { useCreateAnalysisV2Chat } from "../api/useCreateAnalysisV2Chat";

export default function NewAnalysisChatV2() {
  const { chat, createChat } = useCreateAnalysisV2Chat();
  const navigate = useNavigate();

  useEffect(() => {
    if (!chat) return;
    navigate(`/c/${chat.id}`);
  }, [chat, navigate]);

  const processSuggestionSend = async (suggestion: ChatModeSuggestion) => {
    await createChat(suggestion.input);
  };

  return (
    <>
      <Flex className={classes["root-container"]}>
        <Image
          src={logo}
          alt="Quantly black monochrome logo"
          className={classes["logo"]}
        />
        <AvailableDataAlert />
      </Flex>
      <ChatFooter
        processUserInput={createChat}
        children={
          <ChatModeSuggestions
            mode={ChatMode.Analysis}
            processSuggestionSend={processSuggestionSend}
          />
        }
      />
    </>
  );
}
