import { useAuth0 } from "@auth0/auth0-react";
import Axios, { AxiosHeaders } from "axios";
import { useState } from "react";
import { errorNotification } from "../utils/Notifications";
import { logger } from "../utils/Logger";
import { ApiResponse } from "../models/ApiResponse";
import qs from "qs";

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

export const useApiPrivateRequest = <T>(mapper?: (response: any) => T) => {
  const [data, setData] = useState<T | null>(null);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently, logout } = useAuth0();

  const call = async (
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    params?: Record<string, unknown>,
    body?: any,
    headers?: AxiosHeaders,
  ) => {
    try {
      setData(null);
      setIsFailed(false);
      setIsLoading(true);
      const response = await axios.request<ApiResponse>({
        url: url,
        method: method,
        params: params,
        data: body,
        headers: {
          ...headers,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        // This is needed to prevent Axios from throwing an error on non-200 responses
        validateStatus: (_) => true,
        paramsSerializer: (params) => {
          // This is needed to stringify arrays properly
          return qs.stringify(params);
        },
      });

      switch (response.status) {
        case 200:
          if (mapper) setData(mapper(response.data.data));
          else setData(response.data.data);
          break;
        case 401:
          logout();
          break;
        case 400: {
          const errors = response.data.data as string[];
          errors.forEach((error) => {
            errorNotification(response.data.message, error);
          });
          setIsFailed(true);
          break;
        }
        case 404:
          errorNotification(
            "Resource requested was not found",
            response.data.message,
          );
          setIsFailed(true);
          break;
        case 403:
          errorNotification(
            "User is not authorized to perform this action",
            response.data.message,
          );
          setIsFailed(true);
          break;
        default:
          errorNotification(
            "Unexpected server response",
            response.data.message,
          );
          setIsFailed(true);
          break;
      }
    } catch (error) {
      errorNotification("No connection to the server");
      logger.error(error);
      console.trace(error);
      setIsFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, setData, isFailed, isLoading, call };
};
