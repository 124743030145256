import {
  Flex,
  rem,
  Avatar,
  ActionIcon,
  ScrollArea,
  Space,
  Stack,
  Group,
  Text,
  Button,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { IconX } from "@tabler/icons-react";
import { useEffect } from "react";
import { useGetCompany } from "../../../shared/api/useGetCompany";
import { useSearchCompanyDocs } from "../../../shared/api/useSearchCompanyDocs";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";
import { formatText } from "../../../shared/utils/Strings";
import { useGetChats } from "../../chat-management/api/useGetChats";
import ChatItemList from "../../chat-management/components/ChatItemList";
import CompanyDocumentLink from "../../sources/components/CompanyDocumentLink";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";

interface CompanyOverviewProps {
  companyId: number;
  close: () => void;
}

export function CompanyOverview({ companyId, close }: CompanyOverviewProps) {
  const { company, getCompany } = useGetCompany();
  const { results: documents, searchCompanyDocs } = useSearchCompanyDocs();
  const { chats, getChats } = useGetChats();
  const [, setExternalCompanyDoc] = useLocalStorage({
    key: LocalStorageKeys.ExternalCompanyDoc,
  });
  const [, , clearSidebarCompanyId] = useLocalStorage({
    key: LocalStorageKeys.SidebarCompanyId,
  });

  useEffect(() => {
    if (!companyId) return;
    getCompany(companyId);
    searchCompanyDocs(companyId);
    getChats({ companyId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleAddToContextClick = (doc: CompanyDocumentView) => {
    setExternalCompanyDoc(JSON.stringify(doc));
  };

  const handleCloseClick = () => {
    clearSidebarCompanyId();
    close();
  };

  return (
    <>
      {!company && <CenterLoader />}
      {company && (
        <>
          <Flex gap="xs" align="center" justify="space-between">
            <Flex align="center" gap="xs">
              <Text maw={rem(290)} truncate="end" fw={700}>
                {company.companyName}
              </Text>
              <Avatar src={company.logo} size={rem(24)} />
            </Flex>
            <ActionIcon onClick={handleCloseClick} variant="simple">
              <IconX />
            </ActionIcon>
          </Flex>
          <Text fz="sm">
            {company.exchange}:{company.tickerSymbol}
          </Text>

          {company.description && (
            <ScrollArea mih={rem(24)}>
              <Text fz="sm">{formatText(company.description)}</Text>
            </ScrollArea>
          )}
          <Space h={rem(10)} />
          <Text fw={700}>Company documents</Text>
          <ScrollArea.Autosize
            mah={rem(200)}
            bg="var(--mantine-color-white)"
            p={rem(12)}
          >
            <Stack gap="xs">
              {documents?.map((doc) => (
                <Group gap="xs" key={doc.id} justify="space-between">
                  <CompanyDocumentLink
                    document={doc}
                    includeCompanyName={false}
                  />
                  <Button
                    variant="secondary"
                    px={8}
                    radius="xxs"
                    h={24}
                    onClick={() => handleAddToContextClick(doc)}
                  >
                    <Text fz={12}>Add to context</Text>
                  </Button>
                </Group>
              ))}
            </Stack>
          </ScrollArea.Autosize>
          <Space h={rem(10)} />
          <Text fw={700}>Related research</Text>
          {chats && chats.length > 0 && (
            <ScrollArea.Autosize
              h={`calc(100% - ${rem(154)})`}
              scrollbars="y"
              mt={rem(10)}
            >
              <ChatItemList chats={chats} />
            </ScrollArea.Autosize>
          )}
          {chats && chats.length === 0 && (
            <Text>No researches found for {company.companyName}</Text>
          )}
        </>
      )}
    </>
  );
}
