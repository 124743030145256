import { Group, Avatar, rem, Stack, Text } from "@mantine/core";
import classes from "./styles/CompanyProfile.module.css";
import { CompanyInfo } from "./models/CompanyInfo";

interface CompanyProfileBlock {
  company: CompanyInfo;
}

export default function CompanyProfile({ company }: CompanyProfileBlock) {
  return (
    <Group className={classes["group"]}>
      <Avatar
        src={company.logo}
        size={rem(32)}
        classNames={{
          root: classes["avatar-root"],
          image: classes["avatar-image"],
        }}
      />
      <Stack className={classes["stack"]}>
        <Text className={classes["company-name-text"]} lineClamp={2}>
          {company.companyName}
        </Text>
        <Text className={classes["company-ticker-text"]}>
          {company.tickerSymbol}
        </Text>
      </Stack>
    </Group>
  );
}
