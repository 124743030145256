import { ScrollArea, Stack } from "@mantine/core";
import { useRef, useState, useEffect } from "react";
import { useAdminView } from "../../../../shared/hooks/useAdminView";
import { useHasAdminRole } from "../../../../shared/hooks/useHasRole";
import { ChatShort } from "../../../../shared/models/Chat";
import { StepType } from "../../enums/StepType";
import { ChatProcessingStep } from "../../models/ProcessingStep";
import ChatInfo from "../ChatInfo";
import ProcessingStepShell from "./ProcessingStepShell";
import AnalysisStep from "./ProcessingStep";
import UserInputStepShell from "./UserInputStepShell";
import classes from "../../styles/ExistingChat.module.css";

interface StepListProps {
  chat: ChatShort;
  steps: ChatProcessingStep[];
}

export default function StepList({ steps, chat }: StepListProps) {
  const isAdmin = useHasAdminRole();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [isAdminView] = useAdminView();

  useEffect(() => {
    if (!isAtBottom || !scrollRef.current) return;

    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: "instant",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  useEffect(() => {
    const handleWheel = (event: any) => {
      if (!scrollRef.current) return;

      const isUserAtBottom =
        scrollRef.current.scrollHeight -
          scrollRef.current.scrollTop -
          event.deltaY <=
        scrollRef.current.clientHeight;

      if (isUserAtBottom === isAtBottom) return;
      setIsAtBottom(isUserAtBottom);
    };

    const scrollElement = scrollRef.current;
    scrollElement?.addEventListener("wheel", handleWheel);

    return () => scrollElement?.removeEventListener("wheel", handleWheel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollArea
      classNames={{
        root: classes["scroll-area-root"],
        viewport: classes["scroll-area-viewport"],
        thumb: classes["scroll-area-thumb"],
      }}
      viewportRef={scrollRef}
    >
      <Stack className={classes["chat-stack"]}>
        {isAdmin && isAdminView && <ChatInfo chat={chat} />}
        <Stack>
          {steps.map((step, i) => {
            switch (step.stepType) {
              case StepType.UserInput:
                return (
                  <UserInputStepShell key={step.id} chatId={chat.id}>
                    <AnalysisStep step={step} />
                  </UserInputStepShell>
                );
              case StepType.UserContent:
                // No need to render user content steps as it will be displayed as header
                return <div key={step.id}></div>;
              default:
                return (
                  <ProcessingStepShell
                    key={step.id}
                    step={step}
                    chatId={chat.id}
                    isLast={i === steps.length - 1}
                  >
                    <AnalysisStep step={step} />
                  </ProcessingStepShell>
                );
            }
          })}
        </Stack>
      </Stack>
    </ScrollArea>
  );
}
