import { Button, Flex, Modal, rem, Tabs } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import {
  IconFileAnalytics,
  IconComponents,
  IconFileTypePdf,
} from "@tabler/icons-react";
import { UploadDocuments } from "./components/UploadDocuments";
import { AddDocumentsTabType } from "./models/AddDocumentsTabType";
import { FileWithPath } from "@mantine/dropzone";
import { DocumentFilter } from "./components/DocumentFilter";
import { CompanyDocumentView } from "../../shared/models/CompanyDocument";
import { CompanyInfo } from "../../shared/components/company-info/models/CompanyInfo";
import CompanyFilter from "./components/CompanyFilter";
import { useHasRole } from "../../shared/hooks/useHasRole";
import { UserRole } from "../../shared/enums/UserRole";
import { LocalStorageKeys } from "../../shared/enums/LocalStorageKeys";

interface AddDocumentsModalProps {
  opened: boolean;
  close: () => void;
  tabType?: AddDocumentsTabType;
  userFiles: FileWithPath[];
  setUserFiles: (files: FileWithPath[]) => void;
  companyDocs: CompanyDocumentView[];
  setCompanyDocs: (companyDocs: CompanyDocumentView[]) => void;
  companies: CompanyInfo[];
  setCompanies: (companies: CompanyInfo[]) => void;
}

const iconStyle = { width: rem(20), height: rem(20) };

export default function AddDocumentsModal({
  opened,
  close,
  userFiles,
  setUserFiles,
  companyDocs,
  setCompanyDocs,
  companies,
  setCompanies,
}: AddDocumentsModalProps) {
  const [savedLastTab, saveLastTab] = useLocalStorage<string>({
    key: LocalStorageKeys.AddDocumentsTab,
    defaultValue: AddDocumentsTabType.CompanyFilter,
  });
  const uploadPDFDisabled = useHasRole(UserRole.UploadPDFDisabled);

  const handleTabChange = (value: string | null) => {
    if (!value) return;
    saveLastTab(value);
  };

  return (
    <Modal
      size="xxl"
      radius="xs"
      opened={opened}
      onClose={close}
      centered
      title="Add context"
    >
      <Tabs
        defaultValue={savedLastTab}
        inverted
        onChange={handleTabChange}
        h={rem(700)}
        w={rem(1000)}
      >
        <Tabs.List grow>
          {!uploadPDFDisabled && (
            <Tabs.Tab
              value={AddDocumentsTabType.UploadDocuments}
              leftSection={<IconFileTypePdf style={iconStyle} />}
            >
              Upload PDF
            </Tabs.Tab>
          )}

          <Tabs.Tab
            value={AddDocumentsTabType.DocumentFilter}
            leftSection={<IconFileAnalytics style={iconStyle} />}
          >
            Add specific documents
          </Tabs.Tab>

          <Tabs.Tab
            value={AddDocumentsTabType.CompanyFilter}
            leftSection={<IconComponents style={iconStyle} />}
          >
            Add company (without specifying document)
          </Tabs.Tab>
        </Tabs.List>

        {!uploadPDFDisabled && (
          <Tabs.Panel value={AddDocumentsTabType.UploadDocuments}>
            <UploadDocuments files={userFiles} setFiles={setUserFiles} />
          </Tabs.Panel>
        )}

        <Tabs.Panel value={AddDocumentsTabType.DocumentFilter}>
          <DocumentFilter
            companyDocs={companyDocs}
            setCompanyDocs={setCompanyDocs}
          />
        </Tabs.Panel>

        <Tabs.Panel value={AddDocumentsTabType.CompanyFilter}>
          <CompanyFilter companies={companies} setCompanies={setCompanies} />
        </Tabs.Panel>
      </Tabs>
      <Flex justify="flex-end">
        <Button variant="light" radius="xxs" onClick={close}>
          Done
        </Button>
      </Flex>
    </Modal>
  );
}
