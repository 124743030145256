import { DocumentQuestion } from "../../../../models/DocumentQuestion";
import { ChatProcessingStep } from "../ProcessingStep";

export class ExternalContextSeparateQuestionsUserContentStep extends ChatProcessingStep {
  public documentQuestions: DocumentQuestion[];
  public answer: string;

  constructor(step: any) {
    super(step);
    this.documentQuestions = step.data.externalModelDocumentQuestions.map(
      (question: any) => new DocumentQuestion(question),
    );
    this.answer = step.data.externalModelAnswer;
  }
}
