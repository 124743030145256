import { useState, useEffect } from "react";

const ONE_SECOND = 1000;

export function useTimer(startedAt: Date, finishedAt: Date | undefined) {
  const [timerValue, setTimerValue] = useState(0);

  useEffect(() => {
    setTimerValue(0);
    if (finishedAt) {
      setTimerValue(Math.floor(+finishedAt - +startedAt));
      return;
    }
    // + operator converts Date to number
    setTimerValue(Math.floor(Date.now() - +startedAt));
    const intervalId = setInterval(
      () => setTimerValue((prevTimerValue) => prevTimerValue + ONE_SECOND),
      ONE_SECOND,
    );

    // clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [startedAt, finishedAt]);

  return {
    timerValue,
  };
}
