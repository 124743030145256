import { Button, Center } from "@mantine/core";
import { UserSorting, useSearchUsers } from "./api/useSearchUsers";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTriggerPasswordChange } from "./api/useTriggerPasswordChange";
import { IconCoin, IconEye, IconLockOff } from "@tabler/icons-react";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { dateTimeToString } from "../../shared/utils/Dates";
import { useSetUserCreditLimit } from "./api/useSetUserCreditLimit";

export default function AdminUsersList() {
  const { users, searchUsers, isLoading } = useSearchUsers();
  const { isSet, setCreditLimit } = useSetUserCreditLimit();
  const navigate = useNavigate();
  const { triggerPasswordChange } = useTriggerPasswordChange();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );

  useEffect(() => {
    const sortingAsc = !sorting[0]?.desc;
    const sortingField = sorting[0]?.id as UserSorting;
    const emailFilter = columnFilters.find((filter) => filter.id === "Email")
      ?.value as string;
    const userIdFilter = columnFilters.find((filter) => filter.id === "UserId")
      ?.value as string;

    searchUsers({
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sorting: sortingField,
      sortingAsc,
      email: emailFilter,
      userId: userIdFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    columnFilters,
    isSet,
  ]);

  const columns = [
    {
      accessorKey: "UserId",
      header: "UserId",
      enableSorting: false,
    },
    {
      accessorKey: "Email",
      header: "Email",
    },
    {
      accessorKey: "CreatedAt",
      header: "Created At",
      enableColumnFilter: false,
    },
    {
      accessorKey: "CreditLimit",
      header: "Credit Limit",
      enableColumnFilter: false,
    },
    {
      accessorKey: "CreditsUsed",
      header: "Credits Used",
      enableColumnFilter: false,
    },
    {
      accessorKey: "ChatsCount",
      header: "Chats Count",
      enableColumnFilter: false,
    },
    {
      accessorKey: "ViewChats",
      header: "View chats",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "ResetPassword",
      header: "Reset password",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "SetCreditLimit",
      header: "Set credit limit",
      enableSorting: false,
      enableColumnFilter: false,
    },
  ];

  const data = useMemo(() => {
    const handleViewChatsClick = (userId: string) => {
      navigate(`/admin/user/${userId}/chats`);
    };

    const handleResetPasswordClick = (email: string) => {
      if (window.confirm(`Reset password for ${email}?`)) {
        triggerPasswordChange(email);
        alert(`Password reset email sent to ${email}`);
      }
    };

    if (!users) return [];
    return users.map((user) => {
      return {
        UserId: user.id,
        Email: user.email,
        CreatedAt: dateTimeToString(user.createdAt),
        CreditLimit: user.creditLimit,
        CreditsUsed: user.creditsUsed,
        ChatsCount: user.chatsCount,
        ViewChats: (
          <Button onClick={() => handleViewChatsClick(user.id)}>
            <IconEye />
          </Button>
        ),
        ResetPassword: (
          <Button onClick={() => handleResetPasswordClick(user.email)}>
            <IconLockOff />
          </Button>
        ),
        SetCreditLimit: (
          <Center>
            <Button
              onClick={() => {
                const newCreditLimit = prompt(
                  `Enter new credit limit for ${user.email}`,
                  user.creditLimit.toString(),
                );
                if (newCreditLimit) {
                  setCreditLimit(user.id, parseInt(newCreditLimit));
                }
              }}
            >
              <IconCoin />
            </Button>
          </Center>
        ),
      };
    });
  }, [users, navigate, triggerPasswordChange, setCreditLimit]);

  const table = useMantineReactTable({
    columns,
    data,
    enableTopToolbar: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    initialState: {
      showColumnFilters: true,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    state: { pagination, sorting, columnFilters, isLoading },
    rowCount: 1000,
  });

  return <MantineReactTable table={table} />;
}
