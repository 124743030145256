export class CompanyInfo {
  id: number;
  tickerSymbol: string;
  companyName: string;
  currency: string;
  logo: string;
  description?: string;
  exchange?: string;

  constructor(companyInfo: any) {
    this.id = companyInfo.id;
    this.tickerSymbol = companyInfo.tickerSymbol;
    this.companyName = companyInfo.companyName;
    this.currency = companyInfo.currency;
    this.logo = companyInfo.logo;
    this.description = companyInfo.description;
    this.exchange = companyInfo.exchange;
  }
}
