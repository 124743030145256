import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { PromptType } from "../enums/PromptType";
import { PromptVersion } from "../models/PromptVersion";

export const useGetAllPromptVersions = () => {
  const {
    data: promptVersions,
    call,
    isLoading: isLoadingPromptVersions,
    isFailed: isPromptVersionsFailed,
  } = useApiPrivateRequest<PromptVersion[]>((response) =>
    response.map((data: any) => new PromptVersion(data)),
  );

  const getAllPromptVersions = async (name: PromptType) => {
    await call(`/admin/prompt/${name}/history`, "GET");
  };

  return {
    promptVersions,
    getAllPromptVersions,
    isLoadingPromptVersions,
    isPromptVersionsFailed,
  };
};
