import { Group, Text, Box, Stack } from "@mantine/core";
import FunctionCall from "../FunctionCall";
import { ExecuteCodeMessageData } from "../../models/message-data/ExecuteCodeMessageData";
import ChartVisualisation from "../../../../shared/components/chart/ChartVisualisation";

interface ExecuteCodeMessageProps {
  data: ExecuteCodeMessageData;
}

export default function ExecuteCodeMessage({ data }: ExecuteCodeMessageProps) {
  return (
    <>
      <Box>
        {data.chart && (
          <Stack gap={0} align="center">
            <ChartVisualisation chart={data.chart} />
          </Stack>
        )}
        <Group justify="space-between">
          {!data.chart && data.data && (
            <Box maw="90%">
              <Text>Data that was returned by execution server:</Text>
              <Text style={{ whiteSpace: "pre-wrap" }} size="sm">
                {data.data}
              </Text>
            </Box>
          )}
        </Group>
      </Box>
      <FunctionCall functionCall={data.functionCall} />
    </>
  );
}
