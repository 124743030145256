import { PlaybookStepShort } from "../model/Playbook";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import { IconMenuOrder } from "@tabler/icons-react";
import { Button, Center, rem, Stack } from "@mantine/core";
import cx from "clsx";
import classes from "../styles/PlaybookStepsManagement.module.css";
import PlaybookStepEdit from "./PlaybookStepEdit";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface PlaybookStepsManagementProps {
  playbookSteps: PlaybookStepShort[];
  draggableSteps: PlaybookStepShort[];
  setState: React.Dispatch<React.SetStateAction<PlaybookStepShort[]>>;
  reorder: (args: { from: number; to: number }) => void;
}

export default function PlaybookStepsManagement({
  playbookSteps,
  draggableSteps,
  setState,
  reorder,
}: PlaybookStepsManagementProps) {
  const addSection = () => {
    setState((steps) => {
      const newStep = {
        id: uuidv4(),
        title: `Step ${draggableSteps.length + 1}`,
        prompt: "",
        order: draggableSteps.length,
      };

      const updatedSections = [...(steps || [])];
      updatedSections.push(newStep);

      return updatedSections;
    });
  };

  const updateSection = (id: string, newTitle: string, newPrompt: string) => {
    setState((steps) =>
      steps?.map((step) =>
        step.id === id ? { ...step, title: newTitle, prompt: newPrompt } : step,
      ),
    );
  };

  const removeSection = (id: string) => {
    setState((steps) => steps.filter((step) => step.id !== id));
  };

  const items = draggableSteps.map((step, index) => (
    <Draggable key={step.id} index={index} draggableId={step.id}>
      {(provided, snapshot) => (
        <div
          className={cx(classes["item"], {
            [classes["item-dragging"]]: snapshot.isDragging,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes["drag-handle"]}>
            <IconMenuOrder size={16} color="var(--mantine-color-tertiary-5)" />
          </div>
          <Stack gap={rem(8)} style={{ flex: 1 }}>
            <PlaybookStepEdit
              key={step.id}
              step={step}
              initialStep={
                playbookSteps.find(
                  (s: PlaybookStepShort) => s.id === step.id,
                ) || new PlaybookStepShort({})
              }
              updateSection={updateSection}
              removeSection={() => removeSection(step.id)}
            />
          </Stack>
        </div>
      )}
    </Draggable>
  ));

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) =>
        reorder({
          from: source.index,
          to: destination?.index || 0,
        })
      }
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Center>
        <Button
          variant="secondary"
          radius="xs"
          size={isLargeScreen ? "sm" : "xs"}
          onClick={() => addSection()}
        >
          Add new step
        </Button>
      </Center>
    </DragDropContext>
  );
}
