import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatMessage } from "../models/ChatMessage";

export const useGetMessages = (chatId: string) => {
  const { data: messages, call } = useApiPrivateRequest<ChatMessage[]>(
    (response) =>
      response.map((message: any) => new ChatMessage(message, response)),
  );
  const getMessages = async () => {
    await call(`/chat/${chatId}/message`, "GET");
  };

  return { messages, getMessages };
};
