import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";

export const useGetFilings = () => {
  const { data: filings, call } = useApiPrivateRequest<CompanyDocumentView[]>(
    (response) =>
      response.map((document: any) => new CompanyDocumentView(document)),
  );
  const getFilings = async (filingIds: string[]) => {
    await call(`/marketData/filing`, "GET", { filingIds });
  };

  return { filings, getFilings };
};
