import { useParams } from "react-router-dom";
import FilingBody from "../features/filings/FilingBody";
import CustomAppShell from "../shared/components/app-shell/CustomAppShell";

export default function Filing() {
  const { filingId } = useParams();
  return (
    <CustomAppShell>{filingId && <FilingBody id={filingId} />}</CustomAppShell>
  );
}
