import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, Avatar, Text, Stack, Group } from "@mantine/core";
import classes from "../../styles/UserInputMessageShell.module.css";
import { UserInputMessageData } from "../../models/message-data/UserInputMessageData";
import AddQuestionIcon from "../../../question-bank/components/AddQuestionIcon";
import UserDocumentLinkGroup from "../../../sources/UserDocumentLinkGroup";
import React from "react";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";

interface UserInputMessageProps {
  data: UserInputMessageData;
  chatId: string;
}

export default function UserInputMessage({
  data,
  chatId,
}: UserInputMessageProps) {
  const { user } = useAuth0();

  const formattedText = data.input.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Group className={classes["root"]}>
      <Stack className={classes["main-stack"]}>
        <Flex className={classes["flex-container"]}>
          <Avatar variant="avatar" radius="xxs" size={isLargeScreen ? 24 : 20}>
            {user!.name!.charAt(0).toUpperCase()}
          </Avatar>
          <Box className={classes["text-container"]}>
            <Text
              fw={500}
              fz={{
                base: "var(--mantine-font-size-sm)",
                xl: "var(--mantine-font-size-md)",
              }}
            >
              {formattedText}
            </Text>
          </Box>
        </Flex>
        <UserDocumentLinkGroup
          userContentIds={data.userContentIds}
          chatId={chatId}
          filingIds={data.filingIds}
          transcriptIds={data.transcriptIds}
          companyIds={data.companyIds}
        />
      </Stack>
      <AddQuestionIcon question={data.input} />
    </Group>
  );
}
