import { useEffect } from "react";
import CompanySearch from "../../company-search/CompanySearch";
import { useSearchCompanyDocs } from "../../../shared/api/useSearchCompanyDocs";
import {
  Checkbox,
  Loader,
  rem,
  ScrollArea,
  Space,
  Table,
  Text,
} from "@mantine/core";
import { dateTimeToString } from "../../../shared/utils/Dates";
import DocumentContentOpenerIcon from "../../new-chat/components/DocumentContentOpenerIcon";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";
import { useGetCompany } from "../../../shared/api/useGetCompany";

interface DocumentFilterProps {
  companyDocs: CompanyDocumentView[];
  setCompanyDocs: (companyDocs: CompanyDocumentView[]) => void;
  companyId?: number;
}

export function DocumentFilter({
  companyDocs,
  setCompanyDocs,
  companyId,
}: DocumentFilterProps) {
  const { results, searchCompanyDocs, isSearching } = useSearchCompanyDocs();
  const { company, getCompany, setCompany } = useGetCompany();

  useEffect(() => {
    if (!company) return;
    searchCompanyDocs(company.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (!companyId) return;
    getCompany(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleDocumentSelected = (doc: CompanyDocumentView) => {
    if (companyDocs.find((d) => d.id === doc.id)) {
      setCompanyDocs(companyDocs.filter((d) => d.id !== doc.id));
    } else {
      setCompanyDocs([...companyDocs, doc]);
    }
  };

  const rows = results?.map((doc) => (
    <Table.Tr key={doc.id}>
      <Table.Td>
        <Checkbox
          onChange={(_) => handleDocumentSelected(doc)}
          checked={companyDocs.find((d) => d.id === doc.id) !== undefined}
        />
      </Table.Td>
      <Table.Td>{doc.type}</Table.Td>
      <Table.Td>{doc.period}</Table.Td>
      <Table.Td>{doc.formType}</Table.Td>
      <Table.Td>{dateTimeToString(doc.filedAt)}</Table.Td>
      <Table.Td>
        <DocumentContentOpenerIcon id={doc.id} type={doc.type} />
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <CompanySearch onCompanySelected={setCompany} />
      <Space h="lg" />
      {isSearching && <Loader />}
      {company && <Text>Selected company: {company.companyName}</Text>}
      {results && results.length === 0 && <>No results</>}
      {results && results.length > 0 && (
        <ScrollArea.Autosize mah={rem(580)} mx="auto">
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Select</Table.Th>
                <Table.Th>Document type</Table.Th>
                <Table.Th>Period</Table.Th>
                <Table.Th>Form type</Table.Th>
                <Table.Th>Filed at</Table.Th>
                <Table.Th>Document</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </ScrollArea.Autosize>
      )}
    </>
  );
}
