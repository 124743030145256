import { ChatProcessingStep } from "../ProcessingStep";

export class ExternalContextUserContentStep extends ChatProcessingStep {
  public question: string;
  public answer: string;

  constructor(step: any) {
    super(step);
    this.question = step.data.externalModelQuestion;
    this.answer = step.data.externalModelAnswer;
  }
}
