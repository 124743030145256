import { HoverCard, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";

interface ExecutionPlanFailedIconProps {
  error: string;
}

export function ExecutionPlanFailedIcon({
  error,
}: ExecutionPlanFailedIconProps) {
  return (
    <HoverCard radius="xs">
      <HoverCard.Target>
        <IconX />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="xs">{error}</Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
