import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { User } from "../models/User";

export const useGetUser = () => {
  const {
    data: user,
    call,
    isLoading,
  } = useApiPrivateRequest<User>((response) => new User(response));
  const getUser = async () => {
    await call("/user", "GET");
  };
  return { user, getUser, isLoading };
};
