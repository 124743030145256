import ReactApexChart from "react-apexcharts";
import { Box, ScrollArea, Table, Text } from "@mantine/core";
import { ChartType } from "./enums/ChartType";
import classes from "./styles/ChartVisualisation.module.css";
import { Chart, HeatmapChart, TableChart, SimpleChart } from "./models/Chart";

interface ChartVisualisationProps {
  chart: Chart;
  small?: boolean;
}

export default function ChartVisualisation({
  chart,
  small = false,
}: ChartVisualisationProps) {
  if (!chart) {
    return null;
  }

  switch (chart.type) {
    case ChartType.HeatMap: {
      const heatmapChart = chart as HeatmapChart;
      return (
        <>
          {heatmapChart.options.title.text && (
            <Text fw={600} ta="center">
              {heatmapChart.options.title.text}
            </Text>
          )}
          <Box className={classes["chart-container"]} visibleFrom="lg">
            <ReactApexChart
              series={heatmapChart.series as any}
              options={heatmapChart.options as any}
              type={heatmapChart.type as any}
            />
          </Box>
        </>
      );
    }
    case ChartType.Table: {
      const tableChart = chart as TableChart;
      const headers = (
        <tr>
          {tableChart.options.map((option: string, index: number) => (
            <th key={index}>{option}</th>
          ))}
        </tr>
      );
      const rows = tableChart.series.map((row: string[], index: number) => (
        <tr key={index}>
          {row.map((value, subIndex) => (
            <td key={subIndex}>{value}</td>
          ))}
        </tr>
      ));

      return (
        <ScrollArea mah={400}>
          <Table withTableBorder withColumnBorders>
            <caption>{tableChart.caption}</caption>
            <thead>{headers}</thead>
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
      );
    }
    default: {
      const simpleChart = chart as SimpleChart;
      return (
        <>
          {simpleChart.options.title.text && (
            <Text fw={600} ta="center">
              {simpleChart.options.title.text}
            </Text>
          )}
          <Box
            className={classes["chart-container"]}
            data-small={small}
            visibleFrom="md"
          >
            <ReactApexChart
              series={simpleChart.series as any}
              options={simpleChart.options as any}
              type={simpleChart.type as any}
            />
          </Box>
        </>
      );
    }
  }
}
