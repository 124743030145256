import { ChatProcessingStep } from "../ProcessingStep";
import { Chart } from "../../../../shared/components/chart/models/Chart";

export class CodeExecutionStep extends ChatProcessingStep {
  public code: string;
  public responseData: string;
  public chartData: Chart;

  constructor(step: any) {
    super(step);
    this.code = step.data.code as string;
    this.responseData = step.data.responseData as string;
    this.chartData = Chart.parse(step.data.chartData);
  }

  public get textContent() {
    return this.responseData;
  }
}
