import { ActionIcon } from "@mantine/core";
import { IconThumbUp, IconThumbDown } from "@tabler/icons-react";
import { useSendStepFeedback } from "../../api/useSendStepFeedback";
import { useState } from "react";

interface StepFeedbackProps {
  chatId: string;
  stepId: string;
  rating?: number;
  disabled?: boolean;
}

export default function StepFeedback({
  chatId,
  stepId,
  rating: initialRating,
  disabled,
}: StepFeedbackProps) {
  const { sendStepFeedback } = useSendStepFeedback(chatId, stepId);
  const [rating, setRating] = useState(initialRating);

  const handleThumbClick = async (newRating: 1 | -1) => {
    if (rating === newRating) {
      setRating(undefined);
      await sendStepFeedback(undefined);
    } else {
      setRating(newRating);
      await sendStepFeedback(newRating);
    }
  };

  return (
    <>
      <ActionIcon
        variant="simple"
        size="sm"
        onClick={() => handleThumbClick(1)}
        data-clicked={rating === 1}
        disabled={disabled}
      >
        <IconThumbUp size="1.25rem" />
      </ActionIcon>
      <ActionIcon
        variant="simple"
        size="sm"
        onClick={() => handleThumbClick(-1)}
        data-clicked={rating === -1}
        disabled={disabled}
      >
        <IconThumbDown size="1.25rem" />
      </ActionIcon>
    </>
  );
}
