import { ChatProcessingStep } from "../ProcessingStep";
import { VariableMarketData } from "../../../../models/VariableMarketData";
import { SimpleChart } from "../../../../shared/components/chart/models/Chart";

export class DataLoadingStep extends ChatProcessingStep {
  public data: [VariableMarketData];
  public chart: SimpleChart | undefined;

  constructor(step: any) {
    super(step);
    this.data = step.data.data.map(
      (variableMarketData: any) => new VariableMarketData(variableMarketData),
    );
    if (step.data.chart) {
      this.chart = new SimpleChart(step.data.chart);
    }
  }
}
