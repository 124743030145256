import { Button, Center, Table } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetChatsFeedback } from "./api/useGetChatsFeedback";
import { IconEye } from "@tabler/icons-react";
import { ChatFeedback } from "./models/ChatFeedback";

export default function AdminChatsFeedback() {
  const { chatsFeedback, getChatsFeedback } = useGetChatsFeedback();
  const navigate = useNavigate();

  useEffect(() => {
    getChatsFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewChatClick = (feedback: ChatFeedback) => {
    navigate(`/admin/user/${feedback.userId}/chat/${feedback.chatId}`);
  };

  return (
    <Center>
      <Table w="90%">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>UserId</Table.Th>
            <Table.Th>Chat title</Table.Th>
            <Table.Th>Comment</Table.Th>
            <Table.Th>See chat</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {chatsFeedback &&
            chatsFeedback.map((feedback) => (
              <Table.Tr key={feedback.userId}>
                <Table.Td>{feedback.userId}</Table.Td>
                <Table.Td>{feedback.chatTitle}</Table.Td>
                <Table.Td>{feedback.comment}</Table.Td>
                <Table.Td>
                  <Button onClick={() => handleViewChatClick(feedback)}>
                    <IconEye />
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>
    </Center>
  );
}
