import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { toRecord } from "../../../shared/utils/Record";
import { User } from "../models/User";

interface SearchUserProps extends GetPaginationProps {
  userId?: string;
  email?: string;
  sorting?: UserSorting;
  sortingAsc?: boolean;
}

export const useSearchUsers = () => {
  const { data, call, isLoading } = useApiPrivateRequest<User[]>((response) =>
    response.map((user: any) => new User(user)),
  );
  const searchUsers = async (props: SearchUserProps) => {
    const queryParams = toRecord(props);
    await call(`/admin/user`, "GET", queryParams);
  };
  return { users: data, searchUsers, isLoading };
};

export enum UserSorting {
  Email = "email",
  CreatedAt = "createdAt",
  CreditLimit = "creditLimit",
  CreditsUsed = "creditsUsed",
  ChatsCount = "chatsCount",
}
