import { ChatProcessingStep } from "../ProcessingStep";

export class CodeGenerationStep extends ChatProcessingStep {
  public code: string;

  constructor(step: any) {
    super(step);
    this.code = step.data.code as string;
  }

  public get textContent() {
    return this.code;
  }
}
