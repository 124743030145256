import { Box, Textarea, Group, ActionIcon, Text } from "@mantine/core";
import { IconSend, IconPlayerStop } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import classes from "../styles/UserInput.module.css";
import QuestionBank from "../../question-bank/QuestionBank";
import { useStopChat } from "../../existing-chat/api/useStopChat";
import QuestionBankButton from "../../question-bank/components/QuestionBankButton";
import AddDocumentsButton from "../../add-documents-modal/components/AddDocumentsButton";
import AddDocumentsModal from "../../add-documents-modal/AddDocumentsModal";
import { useDisclosure, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { FileWithPath } from "@mantine/dropzone";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";
import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";
import { CompanyDocumentType } from "../../../shared/enums/CompanyDocumentType";
import { AddedDocuments } from "../../add-documents-modal/components/AddedDocuments";
import InlineCompanyFilter from "../../add-documents-modal/components/InlineCompanyFilter";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";
import UseNewsCheckbox from "./UseNewsCheckbox";

interface UserInputProps {
  inProgress?: boolean;
  chatId?: string;
  processUserInput?: (
    input: string,
    companyIds?: number[],
    filingIds?: string[],
    transcriptIds?: number[],
    files?: File[],
    useNews?: boolean,
  ) => Promise<void>;
  disabled?: boolean;
  allowEmpty: boolean;
  setShowChildren?: (show: boolean) => void;
  useNewsDefault?: boolean;
}

export default function UserInput({
  chatId,
  inProgress,
  processUserInput,
  disabled,
  allowEmpty,
  setShowChildren = () => {},
  useNewsDefault,
}: UserInputProps) {
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [openedQuestionBank, setOpenedQuestionBank] = useState(false);
  const [
    openedAddDocuments,
    { open: openAddDocuments, close: closeAddDocuments },
  ] = useDisclosure(false);
  const { stopChat } = useStopChat(chatId || "");
  const [userFiles, setUserFiles] = useState<FileWithPath[]>([]);
  const [companyDocs, setCompanyDocs] = useState<CompanyDocumentView[]>([]);
  const [companies, setCompanies] = useState<CompanyInfo[]>([]);
  const [externalCompanyDoc, , clearExternalCompanyDoc] = useLocalStorage({
    key: LocalStorageKeys.ExternalCompanyDoc,
  });
  const [useNews, setUseNews] = useState(useNewsDefault ?? false);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!externalCompanyDoc) return;
    const parsedCompanyDoc = JSON.parse(
      externalCompanyDoc,
    ) as CompanyDocumentView;
    // Check if the document is already added
    if (
      !companyDocs.some(
        (c) => c.id === parsedCompanyDoc.id && c.type === parsedCompanyDoc.type,
      )
    ) {
      setCompanyDocs([...companyDocs, parsedCompanyDoc]);
    }
    clearExternalCompanyDoc();
  }, [externalCompanyDoc, companyDocs, clearExternalCompanyDoc]);

  useEffect(() => {
    setShowChildren(!openedQuestionBank);
  }, [openedQuestionBank, setShowChildren]);

  const handleSendClick = async (input: string) => {
    if (!processUserInput) return;
    setIsLoading(true);
    await processUserInput(
      input,
      companies.map((company) => company.id),
      companyDocs
        .filter((d) => d.type === CompanyDocumentType.Filing)
        .map((doc) => doc.id),
      companyDocs
        .filter((d) => d.type === CompanyDocumentType.Transcript)
        .map((doc) => parseInt(doc.id)),
      userFiles,
      useNews,
    );
    setInput("");
    setCompanies([]);
    setUserFiles([]);
    setCompanyDocs([]);
    setIsLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey && !isSearching) {
      event.preventDefault();
      handleSendClick(input);
    }
  };

  const inputPlaceholder = inProgress
    ? "...."
    : chatId
      ? "Ask a follow up question"
      : "Add context and then ask a question";

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Box className={classes["box-container"]}>
      <AddedDocuments
        userFiles={userFiles}
        setUserFiles={setUserFiles}
        companyDocs={companyDocs}
        setCompanyDocs={setCompanyDocs}
        companies={companies}
        setCompanies={setCompanies}
      />
      {!openedQuestionBank && (
        <InlineCompanyFilter
          inputRef={inputRef}
          input={input}
          setInput={setInput}
          companies={companies}
          setCompanies={setCompanies}
          setIsSearching={setIsSearching}
        >
          <Textarea
            ref={inputRef}
            value={input}
            variant="unstyled"
            aria-label="User input"
            placeholder={inputPlaceholder}
            onChange={(event) => setInput(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
            radius={0}
            autosize
            minRows={2}
            maxRows={5}
            classNames={{
              input: classes["user-input-input"],
            }}
            disabled={disabled || isLoading}
          />
        </InlineCompanyFilter>
      )}
      {openedQuestionBank && (
        <QuestionBank
          opened={openedQuestionBank}
          setOpened={setOpenedQuestionBank}
          setInput={setInput}
        />
      )}
      <Group className={classes["buttons-container"]}>
        <Group gap={isLargeScreen ? 5 : 3}>
          <AddDocumentsButton
            disabled={disabled || isLoading}
            open={openAddDocuments}
          />
          <QuestionBankButton
            disabled={disabled || isLoading}
            toggleQuestionBank={() =>
              setOpenedQuestionBank((opened) => !opened)
            }
          />
          <UseNewsCheckbox checked={useNews} setChecked={setUseNews} />
          <Text className={classes["text-warning"]} data-disabled={disabled}>
            Quantly may produce inaccurate information. Please verify output
          </Text>
        </Group>
        {!inProgress && (
          <ActionIcon
            variant="gradient"
            gradient={{
              from: "#295c8b 11.28%",
              to: "#295c8b 91.87%",
              deg: 307,
            }}
            size={isLargeScreen ? 28 : 22}
            disabled={disabled || isLoading || (!allowEmpty && !input)}
            loading={isLoading}
            onClick={() => handleSendClick(input)}
          >
            <IconSend size={isLargeScreen ? 18 : 14} />
          </ActionIcon>
        )}
        {inProgress && (
          <ActionIcon
            variant="gradient"
            gradient={{
              from: "#295c8b 11.28%",
              to: "#295c8b 91.87%",
              deg: 307,
            }}
            size={isLargeScreen ? 28 : 22}
            loading={isLoading}
            onClick={stopChat}
          >
            <IconPlayerStop size={isLargeScreen ? 18 : 14} />
          </ActionIcon>
        )}
      </Group>
      <AddDocumentsModal
        opened={openedAddDocuments}
        close={closeAddDocuments}
        userFiles={userFiles}
        setUserFiles={setUserFiles}
        companyDocs={companyDocs}
        setCompanyDocs={setCompanyDocs}
        companies={companies}
        setCompanies={setCompanies}
      />
    </Box>
  );
}
