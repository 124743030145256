import { Box, Text, Group, Stack, ActionIcon } from "@mantine/core";
import { useState } from "react";
import { IconFileDownload } from "@tabler/icons-react";
import { downloadFile } from "../../../../shared/utils/Files";
import { CodeExecutionStep } from "../../models/chat-steps/CodeExecutionStep";
import ChartVisualisation from "../../../../shared/components/chart/ChartVisualisation";

interface CodeExecutionProps {
  step: CodeExecutionStep;
}

export default function CodeExecution({ step }: CodeExecutionProps) {
  const [clicked, setClicked] = useState(false);

  function handleFileDownload() {
    const timestamp = Date.now();
    const content = step.responseData;
    const filename = `response_data_${timestamp}.csv`;
    downloadFile(content, filename, "text/plain");
  }

  return (
    <>
      <Box>
        {step.chartData && (
          <Stack gap={0} align="center">
            <ChartVisualisation chart={step.chartData} />
          </Stack>
        )}
        <Group justify="space-between">
          {!step.chartData && step.responseData && (
            <Box maw="90%">
              <Text>Data that was returned by execution server:</Text>
              <Text style={{ whiteSpace: "pre-wrap" }} size="sm">
                {step.responseData}
              </Text>
            </Box>
          )}
          <Stack align="flex-start" justify="flex-start">
            {!step.chartData && step.responseData && (
              <ActionIcon
                variant="active"
                onClick={() => {
                  setClicked(true);
                  handleFileDownload();
                }}
                data-clicked={clicked}
              >
                <IconFileDownload size="1.625rem" />
              </ActionIcon>
            )}
          </Stack>
        </Group>
      </Box>
    </>
  );
}
