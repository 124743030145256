import { useDisclosure } from "@mantine/hooks";
import { Button, Collapse, Group, Text } from "@mantine/core";
import { CodeHighlight } from "@mantine/code-highlight";
import { ExternalContextSeparateQuestionsUserContentStep } from "../../models/chat-steps/ExternalContextSeparateQuestionsUserContentStep";

interface ExternalContextSeparateQuestionsUserContentProps {
  step: ExternalContextSeparateQuestionsUserContentStep;
}

export default function ExternalContextSeparateQuestionsUserContent({
  step,
}: ExternalContextSeparateQuestionsUserContentProps) {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <>
      <Group justify="space-between" mt={10} mb={10}>
        <Text>Search performed against the document </Text>
        <Button variant="tertiary" size="xs" onClick={toggle}>
          {opened ? "Hide details" : "Show details"}
        </Button>
      </Group>
      <Collapse in={opened}>
        <CodeHighlight
          maw="100%"
          language="plaintext"
          code={
            step.documentQuestions
              .map(
                (question) =>
                  "Document title:" +
                  question.documentTitle +
                  "\nQuestion:" +
                  question.question,
              )
              .join("\n\n") +
            "\n=================\n" +
            step.answer
          }
        />
      </Collapse>
    </>
  );
}
