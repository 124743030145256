import { Badge, Stack, Group, Text } from "@mantine/core";
import { DataLoadingStep } from "../../models/chat-steps/DataLoadingStep";
import ChartVisualisation from "../../../../shared/components/chart/ChartVisualisation";

interface DataLoadingProps {
  step: DataLoadingStep;
}

export default function DataLoading({ step }: DataLoadingProps) {
  return (
    <>
      {step.chart ? (
        <Stack gap={0} align="center">
          <ChartVisualisation chart={step.chart} />
        </Stack>
      ) : (
        step.data.map((data, index) => (
          <Group key={index}>
            <Text>{data.toViewString()}</Text>
            <Badge color="primary" variant="filled">
              {data.source}
            </Badge>
          </Group>
        ))
      )}
    </>
  );
}
