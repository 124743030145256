import { FileWithPath } from "@mantine/dropzone";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";
import { CompanyInfo } from "../../../shared/components/company-info/models/CompanyInfo";
import classes from "../styles/AddedDocuments.module.css";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";

interface AddedDocumentsProps {
  userFiles: FileWithPath[];
  setUserFiles: (files: FileWithPath[]) => void;
  companyDocs: CompanyDocumentView[];
  setCompanyDocs: (docs: CompanyDocumentView[]) => void;
  companies: CompanyInfo[];
  setCompanies: (companies: CompanyInfo[]) => void;
}

export function AddedDocuments({
  userFiles,
  setUserFiles,
  companyDocs,
  setCompanyDocs,
  companies,
  setCompanies,
}: AddedDocumentsProps) {
  const handleRemoveUserFiles = () => {
    setUserFiles([]);
  };

  const handleRemoveCompanyDocs = (companyName: string) => {
    setCompanyDocs(
      companyDocs.filter((doc) => doc.companyName !== companyName),
    );
  };

  const companyDocsByCompany = companyDocs.reduce(
    (acc, doc) => {
      if (!acc[doc.companyName]) {
        acc[doc.companyName] = [];
      }
      acc[doc.companyName].push(doc);
      return acc;
    },
    {} as Record<string, CompanyDocumentView[]>,
  );

  const handleRemoveCompanies = () => {
    setCompanies([]);
  };

  return (
    <>
      {(companies.length > 0 ||
        companyDocs.length > 0 ||
        userFiles.length > 0) && (
        <Box className={classes["box-documents"]} id="added-documents">
          {companies.length > 0 && (
            <Group justify="space-between">
              <Text w="95%" lineClamp={1}>
                {companies.map((c) => c.companyName).join(", ")}
              </Text>
              <ActionIcon
                size="sm"
                color="generic"
                onClick={handleRemoveCompanies}
              >
                <IconX />
              </ActionIcon>
            </Group>
          )}
          {companyDocs.length > 0 && (
            <>
              {Object.entries(companyDocsByCompany).map(
                ([companyName, docs]) => (
                  <Group key={companyName} justify="space-between">
                    <Text w="95%" lineClamp={1}>
                      {companyName}:{" "}
                      {docs.map((d) => `${d.formType} ${d.period}`).join(", ")}
                    </Text>
                    <ActionIcon
                      size="sm"
                      color="generic"
                      onClick={() => handleRemoveCompanyDocs(companyName)}
                    >
                      <IconX />
                    </ActionIcon>
                  </Group>
                ),
              )}
            </>
          )}
          {userFiles.length > 0 && (
            <Group justify="space-between">
              <Text w="95%" lineClamp={1}>
                {userFiles.map((f) => f.name).join(", ")}
              </Text>
              <ActionIcon
                size="sm"
                color="generic"
                onClick={handleRemoveUserFiles}
              >
                <IconX />
              </ActionIcon>
            </Group>
          )}
        </Box>
      )}
    </>
  );
}
