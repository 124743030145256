import { ChartType } from "../enums/ChartType";
import { ChartDot } from "./ChartDot";

export class SeriesElement {
  public name: string;
  public data: ChartDot[];

  constructor(serie: any) {
    this.name = serie.name;
    this.data = serie.data.map((dot: any) => new ChartDot(dot));
  }
}

export class SimpleSeriesElement extends SeriesElement {
  public type: ChartType;

  constructor(serie: any) {
    super(serie);
    this.type = serie.type as ChartType;
  }
}
