import React from "react";

interface GradientIconProps {
  icon: React.ReactElement;
  size?: string;
}

export default function GradientIcon({ icon, size = "24" }: GradientIconProps) {
  const iconWithGradient = React.cloneElement(icon, {
    style: { stroke: "url(#primary-gradient)" },
  });

  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id="primary-gradient"
          x1="25.0465"
          y1="17.2759"
          x2="5.85859"
          y2="2.63668"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.157586" stopColor="#295c8b" />
          <stop offset="0.6" stopColor="#295c8b" />
        </linearGradient>
      </defs>
      {iconWithGradient}
    </svg>
  );
}
