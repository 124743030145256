import { useEffect } from "react";
import { dateTimeToString } from "../../../shared/utils/Dates";
import { Transcript } from "../models/Transcript";
import { Mark } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";

interface TranscriptContentProps {
  transcript: Transcript;
  chunkId?: number;
}

export default function TranscriptContent({
  transcript,
  chunkId,
}: TranscriptContentProps) {
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 80,
    duration: 0,
  });
  useEffect(() => {
    if (!chunkId) return;
    scrollIntoView();
  }, [chunkId, scrollIntoView]);

  return (
    <>
      <h2>{transcript.title}</h2>
      <p>{dateTimeToString(transcript.date)}</p>
      {transcript.components.map((component) => (
        <div
          key={component.id}
          ref={component.id === chunkId ? targetRef : null}
        >
          <b>{component.person}</b>
          {component.id === chunkId && (
            <p>
              <Mark>{component.text}</Mark>
            </p>
          )}
          {component.id !== chunkId && <p>{component.text}</p>}
        </div>
      ))}
    </>
  );
}
