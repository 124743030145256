import { Anchor, Group, Text } from "@mantine/core";
import { IconComponents } from "@tabler/icons-react";
import classes from "../styles/CompanyLink.module.css";
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";

interface CompanyLinkProps {
  companyName: string;
  companyId: number;
}

export default function CompanyLink({
  companyName,
  companyId,
}: CompanyLinkProps) {
  const [, setSidebarCompanyId] = useLocalStorage({
    key: LocalStorageKeys.SidebarCompanyId,
  });

  const handleClick = () => {
    setSidebarCompanyId(companyId.toString());
  };

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Group className={classes["group"]}>
      <IconComponents
        color="var(--mantine-color-primary-5)"
        size={isLargeScreen ? 20 : 16}
      />
      <Anchor onClick={handleClick} underline="always">
        <Text className={classes["text"]}>{companyName}</Text>
      </Anchor>
    </Group>
  );
}
