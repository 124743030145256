import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Filing } from "../models/Filing";

export const useGetFiling = () => {
  const {
    data: filing,
    call,
    isLoading,
  } = useApiPrivateRequest<Filing>((response) => new Filing(response));

  const getFiling = async (id: string) => {
    await call(`/marketData/filing/${id}`, "GET");
  };

  return { filing, getFiling, isLoading };
};
