import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatFeedback } from "../models/ChatFeedback";

export const useGetChatsFeedback = () => {
  const { data, call } = useApiPrivateRequest<ChatFeedback[]>((response) =>
    response.map((feedback: any) => new ChatFeedback(feedback)),
  );
  const getChatsFeedback = async () => {
    await call(`/admin/chat/feedback`, "GET");
  };
  return { chatsFeedback: data, getChatsFeedback };
};
