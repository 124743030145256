import { Text, Loader, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface StepProgressProps {
  className?: string;
}

export default function ProgressMessage({ className }: StepProgressProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);
  return (
    <Stack align="center" className={className}>
      <Loader size={isLargeScreen ? "lg" : "md"} color="primary" />
      <Text>This may take a moment...</Text>
    </Stack>
  );
}
