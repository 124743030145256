import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useSetUserCreditLimit = () => {
  const { data, call } = useApiPrivateRequest<boolean>((_) => true);
  const setCreditLimit = async (userId: string, creditLimit: number) => {
    await call(`/admin/user/creditLimit`, "PUT", undefined, {
      userId,
      creditLimit,
    });
  };
  return { isSet: data, setCreditLimit };
};
