import { ChatProcessingStep } from "../ProcessingStep";

export class SecFilingSectionLoadingStep extends ChatProcessingStep {
  public filingSection: string;
  public filingUrl: string;
  public companyName: string;
  public formType: string;
  public formSection: string;

  constructor(step: any) {
    super(step);
    this.filingSection = step.data.filingSection;
    this.filingUrl = step.data.filingUrl;
    this.companyName = step.data.companyName;
    this.formType = step.data.formType;
    this.formSection = step.data.formSection;
  }
}
