import { CompanyDocumentType } from "../../../shared/enums/CompanyDocumentType";

export class SearchResult {
  public documentId: string;
  public documentType: CompanyDocumentType;
  public chunkId: number;
  public text: string;
  public order: number;

  constructor(data: any) {
    this.documentId = data.documentId;
    this.documentType = data.documentType;
    this.chunkId = data.chunkId;
    this.text = data.text;
    this.order = data.order;
  }
}
