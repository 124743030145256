import { ReactNode } from "react";
import {
  rem,
  Image,
  Paper,
  Group,
  Text,
  Box,
  Flex,
  Button,
  Space,
  Collapse,
} from "@mantine/core";
import StepComment from "./StepComment";
import StepFeedback from "./StepFeedback";
import ProgressMessage from "../ProgressMessage";
import Timer from "../../../../shared/components/timer/Timer";
import { enumToString } from "../../../../shared/utils/Enums";
import { ChatProcessingStep } from "../../models/ProcessingStep";
import logo from "../../../../../public/images/logoMonoBlack.svg";
import classes from "../../styles/MessageShell.module.css";
import { useHasAdminRole } from "../../../../shared/hooks/useHasRole";
import { StepProcessor } from "../../../../shared/utils/StepProcessor";
import { useDisclosure } from "@mantine/hooks";
import StepModelCalls from "./StepModelCalls";
import FailedStep from "./FailedStep";
import CustomCopyButton from "./CustomCopyButton";
import { StepType } from "../../enums/StepType";
import DownloadButton from "./DownloadButton";
import { useAdminView } from "../../../../shared/hooks/useAdminView";

interface ProcessingStepShellProps {
  step: ChatProcessingStep;
  chatId: string;
  children: ReactNode;
  isLast: boolean;
}

export default function ProcessingStepShell({
  step,
  chatId,
  children,
  isLast,
}: ProcessingStepShellProps) {
  const isAdmin = useHasAdminRole();
  const [opened, { toggle }] = useDisclosure(false);
  const [isAdminView] = useAdminView();

  return (
    ((isAdmin && isAdminView) || StepProcessor.IsVisible(step)) && (
      <Flex justify="flex-start" align="flex-start" direction="row">
        <Paper className={classes["paper"]}>
          <Group align="flex-start" gap={rem(19)}>
            <Image className={classes["logo"]} src={logo} />
            <Box className={classes["content-container"]}>
              {step.completionPercentage !== 0 ? (
                <>
                  <Group justify="space-between">
                    <Text className={classes["title"]}>
                      {enumToString(step.stepType)}
                    </Text>
                    {isAdmin && isAdminView && (
                      <Timer
                        startedAt={step.startedAt}
                        finishedAt={
                          !step.inProgress ? step.updatedAt : undefined
                        }
                        variant="neutral"
                      />
                    )}
                  </Group>
                  {!step.failed && children}
                  {step.failed && (
                    <FailedStep chatId={chatId} step={step} isLast={isLast} />
                  )}
                  {!step.inProgress && !step.failed && (
                    <StepComment content={step.comment} />
                  )}
                  {isAdmin && isAdminView && (
                    <>
                      <Space h="md" />
                      <Button variant="tertiary" size="xs" onClick={toggle}>
                        {opened ? "Hide LLM calls" : "Show LLM calls"}
                      </Button>
                      <Collapse in={opened}>
                        <StepModelCalls chatId={chatId} stepId={step.id} />
                      </Collapse>
                    </>
                  )}
                </>
              ) : (
                <Group align="flex-start">
                  <ProgressMessage className={classes["progress"]} />
                  {isAdmin && isAdminView && (
                    <Timer
                      startedAt={step.startedAt}
                      finishedAt={!step.inProgress ? step.updatedAt : undefined}
                      variant="neutral"
                    />
                  )}
                </Group>
              )}
            </Box>
          </Group>
        </Paper>
        <Group className={classes["buttons-group"]}>
          {step.stepType === StepType.DataLoading ? (
            <DownloadButton chatId={chatId} step={step} />
          ) : (
            <CustomCopyButton step={step} />
          )}
          <StepFeedback
            chatId={chatId}
            stepId={step.id}
            rating={step.feedback?.rating}
            disabled={step.inProgress}
          />
        </Group>
      </Flex>
    )
  );
}
