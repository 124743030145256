import { notifications } from "@mantine/notifications";
import errorClasses from "../../styles/ErrorNotifications.module.css";
import successClasses from "../../styles/SuccessNotifications.module.css";

export function errorNotification(title: string, message?: string) {
  notifications.show({
    title: title,
    message: message,
    color: "red",
    classNames: errorClasses,
  });
}

export function successNotification(title: string, message?: string) {
  notifications.show({
    title: title,
    message: message,
    color: "var(--mantine-color-success-1)",
    classNames: successClasses,
  });
}
