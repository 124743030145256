import { NewsResult } from "../../../../models/NewsResult";
import { ChatProcessingStep } from "../ProcessingStep";

export class NewsLoadingStep extends ChatProcessingStep {
  public news: NewsResult[];

  constructor(step: any) {
    super(step);
    this.news = step.data.news.map((result: any) => new NewsResult(result));
  }

  public get textContent() {
    const news = this.news
      .map((result) => {
        return `• ${result.source}: ${result.title}: ${result.sourceUrl}`;
      })
      .join("\n");
    return "Related news:\n\n" + news;
  }
}
