import { Center, Loader, ScrollArea, rem, Stack, Text } from "@mantine/core";
import { Question } from "../models/Question";
import QuestionItem from "./QuestionItem";
import classes from "../styles/QuestionBank.module.css";

interface QuestionListProps {
  questions: Question[] | null;
  emptyMessage: string;
  isLoading: boolean;
  isFailed: boolean;
  isDisabled?: boolean;
  setInput: (input: string) => void;
}

export default function QuestionList({
  questions,
  emptyMessage,
  isLoading,
  isFailed,
  setInput,
}: QuestionListProps) {
  return (
    <>
      <Center>
        {isLoading && <Loader />}
        {isFailed && (
          <Text c="red">Failed to load questions, try again later</Text>
        )}
        {questions && questions.length === 0 && <Text>{emptyMessage}</Text>}
      </Center>
      {questions && questions.length > 0 && (
        <ScrollArea.Autosize mah={rem(300)} scrollbarSize={8}>
          <Stack className={classes["question-stack"]}>
            {questions.map((question) => (
              <QuestionItem
                key={question.id}
                id={question.id}
                question={question.question}
                setInput={setInput}
              />
            ))}
          </Stack>
        </ScrollArea.Autosize>
      )}
    </>
  );
}
