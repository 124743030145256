import {
  rem,
  Box,
  Text,
  List,
  Group,
  Modal,
  Space,
  Button,
} from "@mantine/core";
import classes from "../styles/NewDataChat.module.css";
import { useDisclosure } from "@mantine/hooks";

export default function AvailableDataAlert() {
  const [opened, { close }] = useDisclosure(true);
  return (
    <Modal
      w={rem(414)}
      padding={rem(12)}
      opened={opened}
      onClose={close}
      withCloseButton={false}
      trapFocus={false}
      returnFocus={false}
      radius="xs"
      centered
    >
      <Box className={classes["alert-modal-content"]}>
        <Text fw={600}>Data that Quantly currently supports</Text>
        <Text c="text-secondary" size="xs">
          (powered by AlphaVantage):
        </Text>
        <br />
        <List>
          <List.Item>
            <Text fw={600}>Equities</Text>
            <Text c="text-secondary" className={classes["alert-modal-text"]}>
              Daily/weekly/monthly/quarterly/annual historical prices and
              trading volumes on US and EU public equities and US ETFs
            </Text>
          </List.Item>
          <Space h="xs" />
          <List.Item>
            <Text fw={600}>Company fundamentals</Text>
            <Text c="text-secondary" className={classes["alert-modal-text"]}>
              Income statement, balance sheet, cash flow statement figures and
              derived financial ratios & metrics
            </Text>
          </List.Item>
          <Space h="xs" />
          <List.Item>
            <Text fw={600}>Economic indicators</Text>
            <Text c="text-secondary" className={classes["alert-modal-text"]}>
              US economic indicators, including Real GDP, Real GDP per Capital,
              Treasury Yields, Federal Funds (Interest) Rate, CPI, Inflation,
              Retail Sales, Durable Goods Orders, Unemployment Rate, Non-farm
              Payroll
            </Text>
          </List.Item>
        </List>
        <br />
        <Text>More data providers and availability coming soon.</Text>
      </Box>
      <Group justify="flex-end">
        <Button
          className={classes["alert-modal-button"]}
          variant="primary"
          size="md"
          onClick={close}
        >
          Get Started
        </Button>
      </Group>
    </Modal>
  );
}
