import {
  ActionIcon,
  Box,
  Group,
  Menu,
  rem,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";
import cx from "clsx";
import { useEffect, useState, useRef } from "react";
import classes from "../styles/ChatItem.module.css";
import { useDeleteChat } from "../api/useDeleteChat";
import { useNavigate } from "react-router-dom";
import { ChatShort } from "../../../shared/models/Chat";
import { useRenameChat } from "../api/useRenameChat";
import {
  useClickOutside,
  useDebouncedState,
  useHover,
  useMediaQuery,
} from "@mantine/hooks";
import ModeBadgePlaybook from "./ModeBadgePlaybook";
import { useExportChat } from "../../existing-chat/api/useExportChat";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import {
  CustomEvent,
  useEventTrigger,
} from "../../../shared/hooks/useEventManagement";

interface ChatItemProps {
  chat: ChatShort;
  isActive: boolean;
  userId?: string;
}

export default function ChatItem({ chat, isActive, userId }: ChatItemProps) {
  const navigate = useNavigate();
  const { deleteChat } = useDeleteChat(chat.id);
  const { isFailed, isLoading, renameChat } = useRenameChat(chat.id);
  const [optionsOpened, setOptionsOpened] = useState(false);
  const [chatName, setChatName] = useState(chat.input);
  const [newChatName, setNewChatName] = useState(chatName);
  const [isEditing, setIsEditing] = useDebouncedState(false, 100);
  const { hovered, ref: chatItemRef } = useHover();
  const menuTargetRef = useRef<HTMLDivElement | null>(null);
  const menuDropdownRef = useRef<HTMLDivElement | null>(null);
  const { isExporting, exportChat } = useExportChat();
  const dispatchEvent = useEventTrigger();

  const deleteChatHandler = async () => {
    if (window.confirm("Are you sure you want to delete this chat?")) {
      await deleteChat();
      sendEvent(EventName.ButtonClick, {
        [EventParameter.Action]: EventTitle.DeleteChat,
      });
      dispatchEvent(CustomEvent.RefreshChats);
      navigate("/");
    }
  };

  const isNewChatNameValid = () => newChatName.trim() !== "";

  const renameChatHandler = async () => {
    if (!isNewChatNameValid() || newChatName.trim() === chatName.trim()) {
      // If the new chat name is invalid or the same as the old one, reset the input
      setNewChatName(chatName);
    } else {
      await renameChat(newChatName.trim());
    }

    setIsEditing(false);
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      renameChatHandler();
    }
  };

  const handleChatItemClick = (e: React.MouseEvent) => {
    const refs = [menuTargetRef.current, menuDropdownRef.current];
    if (isEditing || refs.some((ref) => ref?.contains(e.target as Node))) {
      return;
    }

    if (userId) {
      navigate(`/admin/user/${userId}/chat/${chat.id}`);
      return;
    }

    if (!isActive) navigate(`/c/${chat.id}`);
  };

  const textInputRef = useClickOutside(() => {
    renameChatHandler();
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }
    // If the chat rename request failed, reset the input to the old chat name
    // Otherwise, update the chat name
    if (isFailed) {
      setNewChatName(chatName);
    } else {
      setChatName(newChatName.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isEditing && textInputRef.current) {
      textInputRef.current.focus();
      textInputRef.current.select();
    }
  }, [isEditing, textInputRef]);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Group
      ref={chatItemRef}
      className={cx(classes["chat-item"], {
        [classes["item-active"]]: isActive,
      })}
      key={chat.id}
      onClick={handleChatItemClick}
      data-testid="chat-item"
    >
      <Stack className={classes["stack"]}>
        {isEditing ? (
          <TextInput
            ref={textInputRef}
            value={newChatName}
            onChange={(event) => setNewChatName(event.currentTarget.value)}
            onKeyDown={handleEnterPress}
            radius="xxs"
            placeholder="Chat Name"
          />
        ) : (
          <>
            <Text className={classes["title"]} data-testid="chat-name">
              {chatName}
            </Text>
            <Text fz={{ base: 8, xl: 10 }}>
              {chat.finishedAt?.toLocaleString() || "In Progress"}
            </Text>
            <Group>
              <ModeBadgePlaybook
                isPlaybook={!!chat.playbookId}
                playbookTitle={chat.playbookTitle}
              />
            </Group>
          </>
        )}
      </Stack>
      <Box w={rem(24)}>
        {!userId && (hovered || isActive || optionsOpened) && (
          <Group align="flex-start" h="100%">
            <Menu
              position="bottom-end"
              opened={optionsOpened}
              onChange={setOptionsOpened}
            >
              <Menu.Target ref={menuTargetRef}>
                <ActionIcon
                  size={isLargeScreen ? rem(24) : rem(18)}
                  variant="transparent"
                >
                  <IconDotsVertical color="var(--mantine-color-tertiary-5)" />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown ref={menuDropdownRef}>
                <Menu.Item
                  leftSection={
                    <IconDownload size={isLargeScreen ? "1.5rem" : "1rem"} />
                  }
                  onClick={async () => {
                    await exportChat(chat.id);
                  }}
                  className={classes["menu-item"]}
                  px={isLargeScreen ? 8 : 0}
                  disabled={isExporting}
                >
                  Export
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconEdit size={isLargeScreen ? "1.5rem" : "1rem"} />
                  }
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  className={classes["menu-item"]}
                  px={isLargeScreen ? 8 : 0}
                >
                  Rename
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconTrash size={isLargeScreen ? "1.5rem" : "1rem"} />
                  }
                  onClick={deleteChatHandler}
                  className={classes["menu-item"]}
                  px={isLargeScreen ? 8 : 0}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        )}
      </Box>
    </Group>
  );
}
