import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { NewsEntry } from "../models/NewsEntry";

export const useGetNews = () => {
  const {
    data: news,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<NewsEntry>((response) => new NewsEntry(response));
  const getNews = async (newsId: string) => {
    await call(`/marketData/news/${newsId}`, "GET");
  };

  return { news, getNews, isLoading, isFailed };
};
