import { useDisclosure } from "@mantine/hooks";
import { Button, Collapse, Group, Text } from "@mantine/core";
import { CodeHighlight } from "@mantine/code-highlight";
import { ExternalContextMultipleModelsUserContentStep } from "../../models/chat-steps/ExternalContextMultipleModelsUserContentStep";

interface ExternalContextMultipleModelsUserContentProps {
  step: ExternalContextMultipleModelsUserContentStep;
}

export default function ExternalContextMultipleModelsUserContent({
  step,
}: ExternalContextMultipleModelsUserContentProps) {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <>
      <Group justify="space-between" mt={10} mb={10}>
        <Text>Search performed against the document </Text>
        <Button variant="tertiary" size="xs" onClick={toggle}>
          {opened ? "Hide details" : "Show details"}
        </Button>
      </Group>
      <Collapse in={opened}>
        <CodeHighlight
          maw="100%"
          language="markdown"
          code={
            step.question +
            "\n\n" +
            step.documentTitles +
            "\n=================\n" +
            step.answer
          }
        />
      </Collapse>
    </>
  );
}
