import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { Tooltip, ActionIcon, rem, Group } from "@mantine/core";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface CopyModelOutputIconProps {
  content: string;
}

export default function CopyModelOutputIcon({
  content,
}: CopyModelOutputIconProps) {
  const clipboard = useClipboard({ timeout: 2000 });
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);
  return (
    <Group>
      {clipboard.copied && (
        <ActionIcon
          variant="simple"
          size={isLargeScreen ? 20 : 16}
          radius={rem(4)}
        >
          <IconCheck size={isLargeScreen ? 20 : 16} />
        </ActionIcon>
      )}
      {!clipboard.copied && (
        <Tooltip label="Copy to clipboard" withArrow radius="xxs">
          <ActionIcon
            variant="simple"
            size={isLargeScreen ? 20 : 16}
            radius={rem(4)}
            onClick={() => clipboard.copy(content)}
          >
            <IconCopy size={isLargeScreen ? 20 : 16} />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
}
