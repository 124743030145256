import { Group, Text } from "@mantine/core";
import classes from "../styles/DocumentLink.module.css";
import { IconFileAnalytics } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface NewsLinkProps {
  title: string;
  url: string;
}

export default function NewsLink({ title, url }: NewsLinkProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Group className={classes["link-group"]}>
        <IconFileAnalytics
          color="var(--mantine-color-primary-5)"
          size={isLargeScreen ? 20 : 16}
        />
        <Text className={classes["link-text"]}>{title}</Text>
      </Group>
    </a>
  );
}
