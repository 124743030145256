import { Button, Center, Stack } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { ChatMessage } from "../models/ChatMessage";
import { useRetryChat } from "../api/useRetryChat";

interface FailedMessageProps {
  chatId: string;
  message: ChatMessage;
  isLast: boolean;
}

export default function FailedMessage({
  chatId,
  message,
  isLast,
}: FailedMessageProps) {
  const { retryChat } = useRetryChat(chatId);

  return (
    <Center>
      <Stack>
        {message.error}
        {isLast && (
          <Center>
            <Button onClick={retryChat} size="md" leftSection={<IconRefresh />}>
              Try again
            </Button>
          </Center>
        )}
      </Stack>
    </Center>
  );
}
