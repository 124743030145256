import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useDeleteQuestion = () => {
  const {
    data: isDeleted,
    call,
    isLoading: isDeleting,
    isFailed: isDeleteFailed,
  } = useApiPrivateRequest<boolean>((_) => true);
  const deleteQuestion = async (userQuestionId: string) => {
    await call("/questionbank", "DELETE", { userQuestionId });
  };

  return { isDeleted, deleteQuestion, isDeleting, isDeleteFailed };
};
