import { ChatMessageData } from "../ChatMessageData";
import { FunctionCall } from "../FunctionCall";

export class ModelOutputMessageData extends ChatMessageData {
  public content: string;
  public functionCall?: FunctionCall;

  constructor(data: any) {
    super(data);
    this.content = data.content;
    if (data.functionCall) {
      this.functionCall = new FunctionCall(data.functionCall);
    }
  }
}
