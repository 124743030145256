export class ChatModeSuggestion {
  input: string;
  secondaryMode: string;
  data?: Record<string, string>;

  constructor(response: any) {
    this.input = response.input;
    this.secondaryMode = response.secondaryMode;
    this.data = response.data ? JSON.parse(response.data) : undefined;
  }
}
