import { Group, rem, Text } from "@mantine/core";
import classes from "./styles/CompanyItem.module.css";
import { CompanyInfo } from "./models/CompanyInfo";
import CompanyProfile from "./CompanyProfile";

interface CompanyItemProps {
  company: CompanyInfo;
  onCompanySelected: (company: CompanyInfo) => void;
  shortVersion?: boolean;
}

export default function CompanyItem({
  company,
  onCompanySelected,
  shortVersion,
}: CompanyItemProps) {
  return (
    <Group
      className={classes["root-group"]}
      onClick={() => {
        onCompanySelected(company);
      }}
    >
      <CompanyProfile company={company} />
      {!shortVersion && (
        <Group gap={rem(10)} miw={rem(200)} justify="flex-end">
          <Text fz="xs" fw={400} c="text-secondary">
            {company.exchange}
          </Text>
        </Group>
      )}
    </Group>
  );
}
