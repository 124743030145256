import { CopyButton, ActionIcon, Tooltip } from "@mantine/core";
import { IconClipboard } from "@tabler/icons-react";
import { ChatProcessingStep } from "../../models/ProcessingStep";

interface CopyButtonProps {
  step: ChatProcessingStep;
}

export default function CustomCopyButton({ step }: CopyButtonProps) {
  return (
    <CopyButton value={step.textContent ?? ""} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip
          label={copied ? "Copied" : "Copy"}
          withArrow
          position="bottom"
          hidden={step.failed || step.inProgress || step.textContent === null}
        >
          <ActionIcon
            variant="simple"
            size="sm"
            onClick={copy}
            disabled={
              step.failed || step.inProgress || step.textContent === null
            }
          >
            <IconClipboard size="1.25rem" />
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  );
}
