export class TranscriptComponent {
  public id: number;
  public person?: string;
  public text?: string;

  constructor(transcriptComponent: any) {
    this.id = transcriptComponent.id;
    this.person = transcriptComponent.person;
    this.text = transcriptComponent.text;
  }
}
