import { SettingsData } from "../models/UserSettings";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useGetSettings = () => {
  const {
    data: settings,
    call,
    setData: setSettings,
  } = useApiPrivateRequest<SettingsData>();
  const getSettings = async () => {
    await call("/user/settings", "GET");
  };
  return { settings, getSettings, setSettings };
};
