import { Tabs } from "@mantine/core";
import {
  IconUser,
  IconMessages,
  IconScript,
  IconPlayerPlay,
  IconTextPlus,
} from "@tabler/icons-react";
import AdminChatsFeedback from "../features/admin-chats-feedback/AdminChatsFeedback";
import AdminUsersList from "../features/admin-users-list/AdminUsersList";
import AdminEarningsCalls from "../features/adming-earnings-calls/AdminEarningsCalls";
import CustomAppShell from "../shared/components/app-shell/CustomAppShell";
import AdminPlaybooks from "../features/admin-playbooks/AdminPlaybooks";
import AdminPrompts from "../features/admin-prompts/AdminPrompts";

export default function AdminDashboard() {
  return (
    <CustomAppShell>
      <Tabs defaultValue="users">
        <Tabs.List>
          <Tabs.Tab value="users" leftSection={<IconUser />}>
            Users
          </Tabs.Tab>
          <Tabs.Tab value="feedback" leftSection={<IconMessages />}>
            Chat feedback
          </Tabs.Tab>
          <Tabs.Tab value="earningsCalls" leftSection={<IconScript />}>
            Earnings calls
          </Tabs.Tab>
          <Tabs.Tab value="playbooks" leftSection={<IconPlayerPlay />}>
            Playbooks
          </Tabs.Tab>
          <Tabs.Tab value="prompts" leftSection={<IconTextPlus />}>
            Prompts
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="users">
          <AdminUsersList />
        </Tabs.Panel>
        <Tabs.Panel value="feedback">
          <AdminChatsFeedback />
        </Tabs.Panel>
        <Tabs.Panel value="earningsCalls">
          <AdminEarningsCalls />
        </Tabs.Panel>
        <Tabs.Panel value="playbooks">
          <AdminPlaybooks />
        </Tabs.Panel>
        <Tabs.Panel value="prompts">
          <AdminPrompts />
        </Tabs.Panel>
      </Tabs>
    </CustomAppShell>
  );
}
