import {
  Button,
  Center,
  Group,
  Loader,
  Modal,
  Table,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { dateTimeToString } from "../../shared/utils/Dates";
import { IconEye } from "@tabler/icons-react";
import { useSearchEarningsCalls } from "./api/useSearchEarningsCalls";
import { useDisclosure } from "@mantine/hooks";
import ScrollableText from "../../shared/components/scrollable-text/ScrollableText";

export default function AdminEarningsCalls() {
  const { earningsCalls, searchEarningsCalls } = useSearchEarningsCalls();
  const [symbol, setSymbol] = useState<string>();
  const [year, setYear] = useState<number>();
  const [quarter, setQuarter] = useState<number>();
  const [page, setPage] = useState(1);
  const [opened, { open, close }] = useDisclosure(false);
  const [content, setContent] = useState("");

  const triggerSearch = () => {
    searchEarningsCalls(symbol, year, quarter, page);
  };

  useEffect(() => {
    triggerSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Center>
        <Group>
          <TextInput
            placeholder="Symbol"
            value={symbol}
            onChange={(event) => {
              setSymbol(event.currentTarget.value);
            }}
          />
          <TextInput
            placeholder="Year"
            value={year}
            onChange={(event) => {
              setYear(parseInt(event.currentTarget.value));
            }}
          />
          <TextInput
            placeholder="Quarter"
            value={quarter}
            onChange={(event) => {
              setQuarter(parseInt(event.currentTarget.value));
            }}
          />
          <Button onClick={triggerSearch}>Search</Button>
        </Group>
      </Center>
      {!earningsCalls ? (
        <Loader />
      ) : (
        <>
          <Center>
            <Table w="90%">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Id</Table.Th>
                  <Table.Th>Symbol</Table.Th>
                  <Table.Th>Year</Table.Th>
                  <Table.Th>Quarter</Table.Th>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Content</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {earningsCalls &&
                  earningsCalls.map((ec) => (
                    <Table.Tr key={ec.id}>
                      <Table.Td>{ec.id}</Table.Td>
                      <Table.Td>{ec.symbol}</Table.Td>
                      <Table.Td>{ec.year}</Table.Td>
                      <Table.Td>{ec.quarter}</Table.Td>
                      <Table.Td>{dateTimeToString(ec.date)}</Table.Td>
                      <Table.Td>
                        <Button
                          onClick={() => {
                            setContent(ec.content);
                            open();
                          }}
                        >
                          <IconEye />
                        </Button>
                      </Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </Center>

          <Center>
            <Button
              onClick={() => {
                setPage(page - 1);
              }}
            >
              Previous Page
            </Button>
            <Button
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next Page
            </Button>
          </Center>

          <Modal opened={opened} onClose={close} title="Content" size="auto">
            <ScrollableText content={content} />
          </Modal>
        </>
      )}
    </>
  );
}
