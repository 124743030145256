import { Frequency } from "../shared/enums/Frequency";

export class Timeframe {
  startDate: Date;
  endDate: Date;
  frequency: Frequency;

  constructor(timeframe: any) {
    this.startDate = new Date(timeframe.startDate);
    this.endDate = new Date(timeframe.endDate);
    this.frequency = timeframe.frequency as Frequency;
  }
}
