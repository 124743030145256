import { Timeframe } from "./Timeframe";
import { VariableInfo } from "./VariableInfo";

export class VariableMarketData extends VariableInfo {
  source: string;
  timeframe: Timeframe;
  columns: string[];

  constructor(variableMarketData: any) {
    super(variableMarketData);
    this.source = variableMarketData.source;
    this.timeframe = new Timeframe(variableMarketData.timeframe);
    this.columns = variableMarketData.columns as string[];
  }
}
