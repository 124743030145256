import { ChartType } from "../enums/ChartType";
import { XAxisDatetimeOptions, XAxisOptions } from "./XAxisOptions";
import { generalColors, heatmapColors } from "./ColorsForCharts";
import { YAxisItem } from "./YAxisItem";
import { numberToString } from "../../../utils/Number";

export abstract class Options {
  public title: {
    text: string;
    align: "center" | "left" | "right";
    offsetY: number;
  };
  public tooltip: {
    y: {
      formatter: (value: number) => string;
    };
  };
  public dataLabels: {
    enabled: boolean;
    formatter: (value: number) => string;
  };
  public chart: {
    type: ChartType;
    toolbar: {
      show: boolean;
      tools: {
        download: boolean;
        selection: boolean;
        zoom: boolean;
        zoomin: boolean;
        zoomout: boolean;
        pan: boolean;
        reset: boolean;
      };
    };
    animations: {
      enabled: boolean;
    };
  };

  public noData = {
    text: "No data available",
    align: "center",
    verticalAlign: "middle",
  };

  constructor(options: any, type: ChartType, withLabels: boolean = false) {
    const showToolbar = options.showToolbar ?? true;
    this.chart = {
      type: type,
      toolbar: {
        show: showToolbar,
        tools: {
          download: showToolbar,
          selection: showToolbar,
          zoom: showToolbar,
          zoomin: showToolbar,
          zoomout: showToolbar,
          pan: showToolbar,
          reset: showToolbar,
        },
      },
      animations: {
        enabled: false,
      },
    };
    this.title = {
      text: options.titleText,
      align: "left",
      offsetY: -100,
    };
    this.tooltip = {
      y: {
        formatter: (value: number) => {
          return numberToString(value, options.decimalsInFloat);
        },
      },
    };
    this.dataLabels = {
      enabled: withLabels,
      formatter: (value: number) => {
        return numberToString(value, options.decimalsInFloat);
      },
    };
  }
}

export class SimpleOptions extends Options {
  public xaxis: XAxisOptions;
  public yaxis: YAxisItem[];
  public grid: {
    xaxis: { lines: { show: boolean } };
    yaxis: { lines: { show: boolean } };
  };
  public colors: string[] = generalColors;
  public stroke = {
    show: true,
    width: 2,
    curve: "straight",
  };
  public markers: {
    size: number[];
  };
  public fill: {
    colors: string[] | undefined;
    gradient: {
      type: "vertical" | "horizontal" | "diagonal1" | "diagonal2";
      shadeIntensity: number;
      opacityFrom: number;
      opacityTo: number;
    };
  };

  constructor(options: any, type: ChartType) {
    super(options, type);
    // plainAxis is a boolean that determines whether the axis should be styled with a plain style
    // It is set on the frontend only in src/features/company-overview/components/ChartBlock.tsx : 52
    if (options.xaxisType === "datetime") {
      this.xaxis = new XAxisDatetimeOptions(
        options.xaxisTitle,
        options.xaxisType,
        options.tickAmount,
        options.plainAxis,
      );
    } else {
      this.xaxis = new XAxisOptions(
        options.xaxisTitle,
        options.xaxisType,
        options.plainAxis,
      );
    }
    this.yaxis =
      options.yaxis.length > 0
        ? options.yaxis.map(
            (item: any) =>
              new YAxisItem(
                options.decimalsInFloat,
                options.plainAxis,
                item,
                "",
              ),
          )
        : [
            new YAxisItem(
              options.decimalsInFloat,
              options.plainAxis,
              null,
              options.yaxisTitle,
            ),
          ];
    if (options.colors) {
      this.colors = options.colors;
    }
    this.markers = {
      size: options.markersSize,
    };
    this.grid = {
      xaxis: {
        lines: {
          show: options.showGridLines ?? true,
        },
      },
      yaxis: {
        lines: {
          show: options.showGridLines ?? true,
        },
      },
    };
    this.fill = {
      colors: type === ChartType.Area ? this.colors : undefined,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.7,
        opacityFrom: 0.7,
        opacityTo: 0,
      },
    };
  }
}

export class HeatmapOptions extends Options {
  public xaxis: {
    type: "category" | "datetime" | "numeric";
  };
  public yaxis: {
    decimalsInFloat: number;
  };
  public colors: string[] = heatmapColors;

  constructor(options: any) {
    super(options, ChartType.HeatMap, true);
    this.xaxis = {
      type: "category",
    };
    this.yaxis = {
      decimalsInFloat: options.decimalsInFloat,
    };
  }
}
