// No need to test this function because it uses built-in methods
export function dateTimeToString(date?: Date): string {
  if (!date) {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString(
    undefined,
    options,
  )}`;
}

export function timeIntervalToString(
  timeInterval: number,
  includeMs?: boolean,
): string {
  const minutes = Math.floor(timeInterval / (60 * 1000));
  const remainingSeconds = Math.floor((timeInterval / 1000) % 60);
  const remainingMs = Math.floor(timeInterval % 1000);
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}${includeMs ? `.${remainingMs}` : ""}`;
}

export function generateYearArray(startYear: number): string[] {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year.toString());
  }

  return years;
}
