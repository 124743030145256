import { useApiPrivateRequest } from "../hooks/useApi";
import { CompanyDocumentView } from "../models/CompanyDocument";

export const useSearchCompanyDocs = () => {
  const {
    data: results,
    call,
    isLoading: isSearching,
  } = useApiPrivateRequest<CompanyDocumentView[]>((response) =>
    response.map((result: any) => new CompanyDocumentView(result)),
  );
  const searchCompanyDocs = async (
    companyId: number,
    page?: number,
    pageSize?: number,
    yearStart?: string,
    yearEnd?: string,
    type?: string,
  ) => {
    await call(`/marketdata/companies/${companyId}/documents`, "GET", {
      yearStart,
      yearEnd,
      type,
      page,
      pageSize,
    });
  };

  return { results, searchCompanyDocs, isSearching };
};
