import { Checkbox, Group, rem, Text } from "@mantine/core";

interface UseNewsCheckboxProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

export default function UseNewsCheckbox({
  checked,
  setChecked,
}: UseNewsCheckboxProps) {
  return (
    <Group
      gap={rem(5)}
      onClick={() => {
        setChecked(!checked);
      }}
      style={{ cursor: "pointer" }}
      ml={rem(5)}
    >
      <Checkbox checked={checked} readOnly radius="xxs" size="xs" />
      <Text fz={{ base: 11, xl: 14 }}>Use news</Text>
    </Group>
  );
}
