import NewAnalysisChat from "../components/NewAnalysisChat";
import NewAnalysisChatV2 from "../components/NewAnalysisChatV2";
import NewGenericChat from "../components/NewGenericChat";
import { IconFileAnalytics, IconFileInfo } from "@tabler/icons-react";

export enum NewDataChatOption {
  Generic = "Generic",
  Analysis = "Analysis",
  AnalysisV2 = "AnalysisV2",
}

type NewChatOptionsInfoDictionary = {
  [key in NewDataChatOption]: {
    component: JSX.Element;
    description: string;
    title: string;
    icon: JSX.Element;
  };
};

export const newDataChatOptionAttributes: NewChatOptionsInfoDictionary = {
  [NewDataChatOption.Generic]: {
    component: <NewGenericChat />,
    icon: <IconFileInfo />,
    title: "Simple",
    description:
      "Ask questions about SEC-registered companies and get summarized news and qualitative insights. Quickly access key company info and market sentiment.",
  },
  [NewDataChatOption.Analysis]: {
    component: <NewAnalysisChat />,
    icon: <IconFileAnalytics />,
    title: "Advanced",
    description:
      "Create comprehensive analytical reports utilizing both qualitative and quantitative data sources. Integrate financial metrics, market trends, and expert perspectives into detailed reports.",
  },
  [NewDataChatOption.AnalysisV2]: {
    component: <NewAnalysisChatV2 />,
    icon: <IconFileAnalytics />,
    title: "Advanced V2",
    description:
      "Create comprehensive analytical reports utilizing both qualitative and quantitative data sources. Integrate financial metrics, market trends, and expert perspectives into detailed reports.",
  },
};
