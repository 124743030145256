import { Flex, ActionIcon, Text } from "@mantine/core";
import { IconMenu2 } from "@tabler/icons-react";
import classes from "../styles/CollapseBurger.module.css";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface CollapseBurgerProps {
  opened: boolean;
  toggle: () => void;
}

export default function CollapseBurger({
  opened,
  toggle,
}: CollapseBurgerProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <Flex className={classes["burger-container"]} data-collapsed={!opened}>
      <ActionIcon
        variant="transparent"
        color="black"
        size={isLargeScreen ? 22 : 18}
        onClick={toggle}
      >
        <IconMenu2 />
      </ActionIcon>
      {opened && (
        <Text className={classes["burger-history-text"]}>History</Text>
      )}
    </Flex>
  );
}
