import { useAuth0 } from "@auth0/auth0-react";
import { UserRole } from "../enums/UserRole";

const namespace = import.meta.env.VITE_AUTH0_AUDIENCE as string;

export const useHasRole = (role: UserRole) => {
  const { user } = useAuth0();
  if (!user) return false;
  const roles = user[`${namespace}roles`] || [];
  return roles.includes(role);
};

export const useHasAdminRole = () => useHasRole(UserRole.Admin);
