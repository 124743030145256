import { Badge } from "@mantine/core";
import { useTimer } from "./useTimer";
import { timeIntervalToString } from "../../utils/Dates";

interface TimerProps {
  startedAt: Date;
  finishedAt: Date | undefined;
  variant: "neutral" | "success";
  hasError?: boolean;
}

export default function Timer({
  startedAt,
  finishedAt,
  variant,
  hasError,
}: TimerProps) {
  const { timerValue } = useTimer(startedAt, finishedAt);

  return (
    <Badge variant={variant} data-error={hasError}>
      {timeIntervalToString(timerValue)}
    </Badge>
  );
}
