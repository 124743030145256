export enum ChatMode {
  Generic = "Generic",
  Playbook = "Playbook",
  Analysis = "Analysis",
  Pdf = "Pdf",
  Sec = "Sec",
  EarningsCall = "EarningsCall",
  CompanyDocs = "CompanyDocs",
  CompanyDocsV2 = "CompanyDocsV2",
}
