export enum ChartType {
  Bar = "bar",
  Line = "line",
  Area = "area",
  Table = "table",
  Scatter = "scatter",
  BoxPlot = "boxPlot",
  HeatMap = "heatmap",
}

export enum ChartClassification {
  Table = "table",
  Simple = "simple",
  BoxPlot = "boxPlot",
  Heatmap = "heatmap",
}
