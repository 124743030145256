import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserRole } from "../../enums/UserRole";
import { useHasRole } from "../../hooks/useHasRole";

interface RoleProtectedRouteProps {
  role: UserRole;
}

export default function RoleProtectedRoute({ role }: RoleProtectedRouteProps) {
  const isInRole = useHasRole(role);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInRole) {
      navigate("");
    }
  }, [isInRole, navigate]);

  return <Outlet />;
}
