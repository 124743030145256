import logoDataBeta from "/images/fullLogoDataDarkBeta.svg";
import logoBeta from "/images/fullLogoDarkBeta.svg";
import Settings from "./components/Settings";
import { useAuth0 } from "@auth0/auth0-react";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { IconUserCircle } from "@tabler/icons-react";
import { ActionIcon, Group, Image, Menu } from "@mantine/core";
import classes from "./styles/HeaderMenu.module.css";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../shared/utils/UsageAnalytics";
import { useHasAdminRole, useHasRole } from "../../shared/hooks/useHasRole";
import { useEffect } from "react";
import { CreditsUsage } from "./components/CreditsUsage";
import GradientIcon from "../../shared/components/gradient/GradientIcon";
import { useGetUser } from "./api/useGetUser";
import { UserRole } from "../../shared/enums/UserRole";

export function HeaderMenu() {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { user, getUser } = useGetUser();
  const [settingsOpened, { open: settingsOpen, close: settingsClose }] =
    useDisclosure(false);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.customLogoPath) {
      localStorage.setItem("customLogoPath", user.customLogoPath);
    } else if (user && localStorage.getItem("customLogoPath") !== null) {
      localStorage.removeItem("customLogoPath");
    }
  }, [user?.customLogoPath, user]);

  const customLogoPath =
    user?.customLogoPath || localStorage.getItem("customLogoPath");
  const isAdmin = useHasAdminRole();
  const modelSettingsEnabled = useHasRole(UserRole.ModelSettings);

  const handleSettingsClick = () => {
    settingsOpen();
    sendEvent(EventName.ButtonClick, {
      [EventParameter.Action]: EventTitle.OpenSettings,
    });
  };

  const handleLogoutClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleAdminClick = () => {
    navigate("/admin");
  };

  const handleGrafanaClick = () => {
    window.open(import.meta.env.VITE_GRAFANA_URL as string, "_blank")?.focus();
  };

  return (
    <Group className={classes["header"]}>
      <Image
        src={
          customLogoPath
            ? customLogoPath
            : import.meta.env.VITE_QUANTLY_MODE === "DOCS"
              ? logoBeta
              : logoDataBeta
        }
        className={classes["logo"]}
        alt="Full Quantly Beta dark logo"
        onClick={() => navigate("/")}
      />
      <Group className={classes["right-group"]}>
        <CreditsUsage />
        <Menu position="bottom-end">
          <Menu.Target>
            <ActionIcon radius="50%" variant="transparent">
              <GradientIcon icon={<IconUserCircle />} />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            {(modelSettingsEnabled || isAdmin) && (
              <Menu.Item
                className={classes["menu-item"]}
                px={8}
                onClick={handleSettingsClick}
              >
                Settings
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                className={classes["menu-item"]}
                px={8}
                onClick={handleAdminClick}
              >
                Admin
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item
                className={classes["menu-item"]}
                px={8}
                onClick={handleGrafanaClick}
              >
                Metabase
              </Menu.Item>
            )}
            <Menu.Item
              className={classes["menu-item"]}
              px={8}
              onClick={handleLogoutClick}
            >
              Log out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Settings opened={settingsOpened} close={settingsClose} />
    </Group>
  );
}
