import { useState } from "react";
import { ActionIcon, Button, Code, Collapse, Group, Text } from "@mantine/core";
import { SecFilingSectionLoadingStep } from "../../models/chat-steps/SecFilingSectionLoadingStep";
import { useDisclosure } from "@mantine/hooks";
import { IconFileText } from "@tabler/icons-react";

interface SecFilingSectionLoadingProps {
  step: SecFilingSectionLoadingStep;
}

export default function SecFilingSectionLoading({
  step,
}: SecFilingSectionLoadingProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Text>
        Company name: <b>{step.companyName}</b>
        <br />
        Form type: <b>{step.formType}</b>
        <br />
        Filing Section: <b>{step.formSection}</b>
        <br />
      </Text>
      <Group justify="space-between" mt={10} mb={10}>
        <a
          href={step.filingUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setClicked(true)}
        >
          <ActionIcon variant="active" data-clicked={clicked}>
            <IconFileText size="1.625rem" />
          </ActionIcon>
        </a>
        <Button
          variant="tertiary"
          size="xs"
          onClick={() => {
            toggle();
          }}
        >
          {opened ? "Hide Filing Section" : "Show Filing Section"}
        </Button>
      </Group>
      <Collapse in={opened}>
        <Code block mah={600} maw="100%">
          {step.filingSection}
        </Code>
      </Collapse>
    </>
  );
}
