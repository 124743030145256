import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useSendStepFeedback = (chatId: string, stepId: string) => {
  const { call } = useApiPrivateRequest<void>();
  const sendStepFeedback = async (rating: -1 | 1 | undefined) => {
    await call(`/chat/${chatId}/step/${stepId}/feedback`, "PUT", undefined, {
      rating,
    });
  };

  return { sendStepFeedback };
};
