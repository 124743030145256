import { Group, Text } from "@mantine/core";
import { AnalysisInputsIdentificationStep } from "../../models/chat-steps/AnalysisInputsIdentificationStep";
import { enumToString } from "../../../../shared/utils/Enums";

interface AnalysisInputsIdentificationStepProps {
  step: AnalysisInputsIdentificationStep;
}

export default function AnalysisInputsIdentification({
  step,
}: AnalysisInputsIdentificationStepProps) {
  return (
    <>
      <Group>
        <Text>
          <ul>
            {step.variables.map((variable, index) => (
              <li key={index}>{variable.toViewString()}</li>
            ))}
          </ul>
        </Text>
      </Group>
      <Group>
        <Text>{step.timeframe.startDate.toLocaleDateString()}</Text>
        <Text>—</Text>
        <Text>{step.timeframe.endDate.toLocaleDateString()}</Text>
        <Text>Frequency: {enumToString(step.timeframe.frequency)}</Text>
      </Group>
      <Group>
        <Text>
          <ul>
            {step.steps.map(({ description, explanation }, index) => (
              <li key={index}>
                {description} ({explanation})
              </li>
            ))}
          </ul>
        </Text>
      </Group>
    </>
  );
}
