import { ChatMode } from "../enums/ChatMode";
import {
  IconFileAnalytics,
  IconFileDatabase,
  IconFileUpload,
  IconMessage,
  IconPlayerPlay,
} from "@tabler/icons-react";

type ChatModeAttributesType = {
  [key in ChatMode]: {
    title: string;
    icon: JSX.Element;
  };
};

export const chatModeAttributes: ChatModeAttributesType = {
  [ChatMode.Generic]: {
    title: "Simple",
    icon: <IconMessage size={16} />,
  },
  [ChatMode.Playbook]: {
    title: "Playbook",
    icon: <IconPlayerPlay size={16} />,
  },
  [ChatMode.Analysis]: {
    title: "Advanced",
    icon: <IconFileAnalytics size={16} />,
  },

  [ChatMode.Sec]: {
    title: "SEC",
    icon: <IconFileDatabase size={16} />,
  },
  [ChatMode.EarningsCall]: {
    title: "Earnings Call",
    icon: <IconFileDatabase size={16} />,
  },
  [ChatMode.Pdf]: {
    title: "PDF",
    icon: <IconFileUpload size={16} />,
  },
  [ChatMode.CompanyDocs]: {
    title: "Company Docs",
    icon: <IconFileDatabase size={16} />,
  },
  [ChatMode.CompanyDocsV2]: {
    title: "Company Docs",
    icon: <IconFileDatabase size={16} />,
  },
};
