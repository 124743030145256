import { CompanyDocumentType } from "../../../shared/enums/CompanyDocumentType";

export class CompanyDocument {
  public id: string;
  public type: CompanyDocumentType;
  public ticker: string;
  public title: string;
  public companyId: number;
  public companyName: string;

  constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.ticker = data.ticker;
    this.title = data.title;
    this.companyId = data.companyId;
    this.companyName = data.companyName;
  }
}
