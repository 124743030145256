export enum StepType {
  DataLoading = "DataLoading",
  CodeGeneration = "CodeGeneration",
  CodeExecution = "CodeExecution",
  Interpretation = "Interpretation",
  UnhandledError = "UnhandledError",
  AnalysisInputsIdentification = "AnalysisInputsIdentification",
  UserInput = "UserInput",
  ModelOutput = "ModelOutput",
  NewsLoading = "NewsLoading",
  SecFilingSectionLoading = "SecFilingSectionLoading",
  UserContent = "UserContent",
  MarketDataSqlQuery = "MarketDataSqlQuery",
  ExternalContextUserContent = "ExternalContextUserContent",
  ExternalContextMultipleModelsUserContent = "ExternalContextMultipleModelsUserContent",
  ExternalContextSeparateQuestionsUserContent = "ExternalContextSeparateQuestionsUserContent",
  DocumentSearch = "DocumentSearch",
}
