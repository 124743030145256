import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook, PlaybookStepShort } from "../../playbook/model/Playbook";

export const useUpdateDefaultPlaybook = () => {
  const {
    data: updatedPlaybook,
    call,
    isLoading: isUpdating,
    isFailed: isUpdateFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const updatePlaybook = async (
    id: string,
    title: string,
    steps: PlaybookStepShort[],
  ) => {
    await call(`/admin/playbook/${id}`, "PUT", undefined, {
      title,
      steps,
    });
  };

  return {
    updatedPlaybook,
    updatePlaybook,
    isUpdating,
    isUpdateFailed,
  };
};
