import { Group, Text } from "@mantine/core";
import classes from "../styles/DocumentLink.module.css";
import { IconFileAnalytics } from "@tabler/icons-react";
import { useMemo } from "react";
import { generateDocumentLink } from "../../../shared/utils/Strings";
import { CompanyDocumentView } from "../../../shared/models/CompanyDocument";
import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

interface DocumentLinkProps {
  document: CompanyDocumentView;
  chunkId?: number;
  includeCompanyName?: boolean;
}

export default function CompanyDocumentLink({
  document,
  chunkId,
  includeCompanyName = true,
}: DocumentLinkProps) {
  const link = useMemo(() => {
    return generateDocumentLink(document.id, document.type, chunkId);
  }, [document, chunkId]);

  const title = !includeCompanyName
    ? `${document.formType} ${document.period}`
    : document.title;

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Group className={classes["link-group"]}>
        <IconFileAnalytics
          color="var(--mantine-color-primary-5)"
          size={isLargeScreen ? 20 : 16}
        />
        <Text className={classes["link-text"]}>{title}</Text>
      </Group>
    </a>
  );
}
