import { useState } from "react";
import { Text, TextInput, Group, Center, rem } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

interface EditableTextInputProps {
  text: string;
  isGradient?: boolean;
  textClassname?: string;
  placeholder: string;
  inputSize?: "xs" | "sm" | "md" | "lg" | "xl";
  inputClassname?: string;
  onSave: (value: string) => void;
}

export function EditableTextInput({
  text,
  isGradient = false,
  textClassname,
  placeholder,
  inputSize = "xs",
  inputClassname,
  onSave,
}: EditableTextInputProps) {
  const [editMode, setEditMode] = useState(text === "");
  const [value, setValue] = useState(text);

  const handleEdit = () => setEditMode(true);
  const handleSave = () => {
    onSave(value);
    if (value.replace(/\s/g, "") !== "") setEditMode(false);
  };

  return (
    <Group align="center">
      {editMode && (
        <TextInput
          value={value}
          placeholder={placeholder}
          error={value.replace(/\s/g, "") === ""}
          size={inputSize}
          radius="xxs"
          c="text-secondary"
          autoFocus
          onChange={(event) => setValue(event.currentTarget.value)}
          onBlur={handleSave}
          classNames={{
            input: inputClassname,
          }}
        />
      )}
      {!editMode && (
        <>
          {isGradient ? (
            <Center
              onClick={handleEdit}
              h={rem(36)}
              style={{ gap: 10, cursor: "pointer" }}
            >
              <Text
                fz="xxl"
                fw={700}
                variant="gradient"
                gradient={{
                  from: "#295c8b 11.28%",
                  to: "#295c8b 91.87%",
                  deg: 307,
                }}
                className={textClassname}
              >
                {value}
              </Text>
              <IconEdit color="var(--mantine-color-tertiary-5)" size={24} />
            </Center>
          ) : (
            <Center
              onClick={handleEdit}
              h={rem(30)}
              style={{ gap: 10, cursor: "pointer" }}
            >
              <Text className={textClassname}>{value}</Text>
              <IconEdit size={16} color="var(--mantine-color-tertiary-5)" />
            </Center>
          )}
        </>
      )}
    </Group>
  );
}
