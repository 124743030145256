import {
  rem,
  VariantColorsResolver,
  defaultVariantColorsResolver,
} from "@mantine/core";

// Responsible only for colours in element's variants. If other changes needed, use data-variant attribute.
// Only for Alert, Avatar, Button, Badge and ActionIcon.
export const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  if (input.variant === "default") {
    return {
      background: "var(--mantine-color-base-0)",
      hover: "var(--mantine-color-secondary-5)",
      color: "var(--mantine-color-text-primary-5)",
      border: `${rem(2)} solid var(--mantine-color-secondary-hover-5)`,
    };
  }

  // primarly for Button

  if (input.variant === "primary") {
    return {
      background: "var(--mantine-color-primary-5)",
      hover: "var(--mantine-color-primary-hover-5)",
      color: "var(--mantine-color-text-primary-0)",
      border: "none",
    };
  }

  if (input.variant === "secondary") {
    return {
      background: "var(--mantine-color-secondary-5)",
      hover: "var(--mantine-color-secondary-hover-5)",
      color: "var(--mantine-color-text-primary-5)",
      border: `${rem(2)} solid var(--mantine-color-secondary-hover-5)`,
    };
  }

  if (input.variant === "tertiary") {
    return {
      background: "var(--mantine-color-base-0)",
      hover: "var(--mantine-color-secondary-5)",
      color: "var(--mantine-color-text-secondary-5)",
      border: `${rem(2)} solid var(--mantine-color-secondary-hover-5)`,
    };
  }

  if (input.variant === "plain-dark") {
    return {
      background: "transparent",
      hover: "none",
      color: "var(--mantine-color-black)",
      border: "none",
    };
  }

  if (input.variant === "plain-light") {
    return {
      background: "transparent",
      hover: "none",
      color: "var(--mantine-color-text-secondary-5)",
      border: "none",
    };
  }

  // primarly for Badge

  if (input.variant === "neutral") {
    return {
      background: "transparent",
      hover: "none",
      color: "var(--mantine-color-tertiary-5)",
      border: `${rem(1)} solid var(--mantine-color-tertiary-5)`,
    };
  }

  if (input.variant === "success") {
    return {
      background: "transparent",
      hover: "none",
      color: "var(--mantine-color-success-5)",
      border: `${rem(1)} solid var(--mantine-color-success-5)`,
    };
  }

  if (input.variant === "positive") {
    return {
      background: "#1C8D77",
      hover: "none",
      color: "var(--mantine-color-white)",
      border: "none",
    };
  }

  if (input.variant === "negative") {
    return {
      background: "#EC3540",
      hover: "none",
      color: "var(--mantine-color-white)",
      border: "none",
    };
  }

  // primarly for ActionIcon

  if (input.variant === "simple") {
    return {
      background: "transparent",
      hover: "var(--mantine-color-secondary-5)",
      color: "var(--mantine-color-tertiary-5)",
      border: "none",
    };
  }

  if (input.variant === "active") {
    return {
      background: "transparent",
      hover: "var(--mantine-color-secondary-hover-2)",
      color: "var(--mantine-color-primary-5)",
      border: "none",
    };
  }

  // primarly for Avatar

  if (input.variant === "avatar") {
    return {
      background: "#295c8b",
      hover: "none",
      color: "var(--mantine-color-text-primary-0)",
      border: `${rem(1)} solid var(--mantine-color-primary-5)`,
    };
  }

  return defaultResolvedColors;
};
