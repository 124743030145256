import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatMode } from "../../../shared/enums/ChatMode";
import { ChatModeSuggestion } from "../models/ChatModeSuggestion";

export const useGetChatModeSuggestions = (chatMode: ChatMode) => {
  const { data: suggestions, call } = useApiPrivateRequest<
    ChatModeSuggestion[]
  >((response) => response.map((item: any) => new ChatModeSuggestion(item)));
  const getSuggestions = async () => {
    await call(`/chat/suggestions/${chatMode}`, "GET");
  };

  return { suggestions, getSuggestions };
};
