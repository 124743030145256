import { rem, AppShell } from "@mantine/core";
import { HeaderMenu } from "../features/header/HeaderMenu";
import ChatManagement from "../features/chat-management/ChatManagement";
import ExistingChat from "../features/existing-chat/ExistingChat";
import { useParams } from "react-router-dom";
import NewDataChat from "../features/new-chat/NewDataChat";
import {
  NewDataChatOption,
  newDataChatOptionAttributes,
} from "../features/new-chat/enums/NewDataChatOption";
import { useDisclosure } from "@mantine/hooks";
import { UserRole } from "../shared/enums/UserRole";
import { useHasRole } from "../shared/hooks/useHasRole";

const HEADER_HEIGHT = rem(67);

export default function DataChat() {
  const chatDisabled = useHasRole(UserRole.ChatDisabled);
  const { chatId, mode } = useParams();
  const [opened, { toggle }] = useDisclosure(true);

  return (
    <AppShell
      header={{ height: HEADER_HEIGHT }}
      navbar={{
        width: opened ? rem(272) : rem(60),
        breakpoint: "xs",
      }}
    >
      <AppShell.Header
        style={{ borderColor: "var(--mantine-color-secondary-hover-5" }}
      >
        <HeaderMenu />
      </AppShell.Header>

      <AppShell.Navbar>
        <ChatManagement chatId={chatId} opened={opened} toggle={toggle} />
      </AppShell.Navbar>

      <AppShell.Main
        h={`calc(100vh - ${HEADER_HEIGHT})`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {chatId ? (
          <ExistingChat chatId={chatId} />
        ) : mode ? (
          newDataChatOptionAttributes[mode as NewDataChatOption]?.component
        ) : (
          !chatDisabled && <NewDataChat />
        )}
      </AppShell.Main>
    </AppShell>
  );
}
