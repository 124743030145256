export class StepModelCall {
  public createdAt: Date;
  public prompt: string;
  public output: string;
  public durationMs: number;

  constructor(response: any) {
    this.createdAt = new Date(response.createdAt);
    this.prompt = response.prompt as string;
    this.output = response.output as string;
    this.durationMs = response.durationMs as number;
  }
}
