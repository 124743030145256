import { Space, Code } from "@mantine/core";
import { ModelOutputStep } from "../../models/chat-steps/ModelOutputStep";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import markdownClasses from "../../../../shared/styles/Markdown.module.css";

interface ModelOutputProps {
  step: ModelOutputStep;
}

export default function ModelOutput({ step }: ModelOutputProps) {
  return (
    <>
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        className={markdownClasses["markdown"]}
      >
        {step.output}
      </Markdown>
      {step.functionCall && (
        <>
          <Space h="md" />
          <Code maw="100%">{step.functionCall}</Code>
        </>
      )}
    </>
  );
}
