import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { StepProcessor } from "../../../shared/utils/StepProcessor";
import { ChatProcessingStep } from "../models/ProcessingStep";

export const useGetStep = (chatId: string) => {
  const { data: step, call } = useApiPrivateRequest<ChatProcessingStep>(
    (response) => StepProcessor.parse(response),
  );
  const getStep = async (stepId: string) => {
    await call(`/chat/${chatId}/step/${stepId}`, "GET");
  };

  return { step, getStep };
};
