import { ChatMessageData } from "../ChatMessageData";

export class UserInputMessageData extends ChatMessageData {
  public input: string;
  public companyIds?: number[];
  public filingIds?: string[];
  public transcriptIds?: number[];
  public userContentIds?: string[];

  constructor(data: any) {
    super(data);
    this.input = data.input;
    this.companyIds = data.companyIds;
    this.filingIds = data.filingIds;
    this.transcriptIds = data.transcriptIds;
    this.userContentIds = data.userContentIds;
  }
}
