import { Button, Center, Loader, rem, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { dateTimeToString } from "../../shared/utils/Dates";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useGetDefaultPlaybooks } from "./api/useGetDefaultPlaybooks";
import { useDeleteDefaultPlaybook } from "./api/useDeleteDefaultPlaybook";
import EditPlaybookModal from "./components/EditPlaybookModal";

export default function AdminPlaybooks() {
  const { defaultPlaybooks, getDefaultPlaybooks } = useGetDefaultPlaybooks();
  const { isDeleted, deletePlaybook } = useDeleteDefaultPlaybook();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedPlaybookId, setSelectedPlaybookId] = useState<string>();

  useEffect(() => {
    getDefaultPlaybooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClick = (playbookId: string) => {
    setSelectedPlaybookId(playbookId);
    open();
  };

  const handleCreateClick = () => {
    setSelectedPlaybookId(undefined);
    open();
  };

  const handleEditDelete = (playbookId: string) => {
    if (window.confirm("Are you sure you want to delete this playbook?")) {
      deletePlaybook(playbookId);
    }
  };

  useEffect(() => {
    if (!isDeleted) return;
    getDefaultPlaybooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted]);

  const onClose = () => {
    getDefaultPlaybooks();
    close();
  };

  return (
    <>
      <Button m={rem(10)} onClick={handleCreateClick}>
        Create Playbook
      </Button>
      {!defaultPlaybooks && <Loader />}
      {defaultPlaybooks && (
        <Center>
          <Table w="90%">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Title</Table.Th>
                <Table.Th>Created At</Table.Th>
                <Table.Th>Edit</Table.Th>
                <Table.Th>Delete</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {defaultPlaybooks.map((p) => (
                <Table.Tr key={p.id}>
                  <Table.Td>{p.title}</Table.Td>
                  <Table.Td>{dateTimeToString(p.createdAt)}</Table.Td>
                  <Table.Td>
                    <Button
                      onClick={() => {
                        handleEditClick(p.id);
                      }}
                    >
                      <IconEdit />
                    </Button>
                  </Table.Td>
                  <Table.Td>
                    <Button
                      onClick={() => {
                        handleEditDelete(p.id);
                      }}
                    >
                      <IconTrash />
                    </Button>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Center>
      )}
      <EditPlaybookModal
        opened={opened}
        close={onClose}
        playbookId={selectedPlaybookId}
      />
    </>
  );
}
