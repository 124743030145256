type FileType = "text/plain" | "application/json" | "text/csv";

export function downloadFile(
  content: string,
  filename: string,
  fileType: FileType,
): void {
  const link = document.createElement("a");
  const file = new Blob([content], { type: `${fileType};charset=utf-8` });
  link.href = URL.createObjectURL(file);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
