import { ChatProcessingStep } from "../ProcessingStep";

export class UnhandledErrorStep extends ChatProcessingStep {
  public message: string;

  constructor(step: any) {
    super(step);
    this.message = step.data.message;
  }
}
