import {
  Button,
  Flex,
  Modal,
  ScrollArea,
  Space,
  TextInput,
  Title,
} from "@mantine/core";
import { useGetDefaultPlaybook } from "../api/useGetDefaultPlaybook";
import { useEffect, useState } from "react";
import PlaybookStepsManagement from "../../playbook/components/PlaybookStepsManagement";
import { PlaybookStepShort } from "../../playbook/model/Playbook";
import { useListState } from "@mantine/hooks";
import { useCreateDefaultPlaybook } from "../api/useCreateDefaultPlaybook";
import { useUpdateDefaultPlaybook } from "../api/useUpdateDefaultPlaybook";

interface EditPlaybookModalProps {
  opened: boolean;
  close: () => void;
  playbookId?: string;
}

export default function EditPlaybookModal({
  opened,
  close,
  playbookId,
}: EditPlaybookModalProps) {
  const { defaultPlaybook, getDefaultPlaybook, setDefaultPlaybook } =
    useGetDefaultPlaybook();
  const [dragableSteps, { setState, reorder }] =
    useListState<PlaybookStepShort>();
  const [newPlaybookTitle, setNewPlaybookTitle] = useState("");
  const { createPlaybook } = useCreateDefaultPlaybook();
  const { updatePlaybook } = useUpdateDefaultPlaybook();

  useEffect(() => {
    if (!playbookId) return;
    getDefaultPlaybook(playbookId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbookId]);

  useEffect(() => {
    if (!defaultPlaybook) return;
    setNewPlaybookTitle(defaultPlaybook.title);
    setState(defaultPlaybook.steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlaybook]);

  const handleSaveClick = async () => {
    const updatedSteps = dragableSteps.map((step, index) => ({
      ...step,
      order: index,
    }));
    if (!defaultPlaybook) {
      await createPlaybook(newPlaybookTitle, updatedSteps);
    } else {
      await updatePlaybook(defaultPlaybook.id, newPlaybookTitle, updatedSteps);
    }

    onClose();
  };

  const onClose = () => {
    setDefaultPlaybook(null);
    setNewPlaybookTitle("");
    setState([]);
    close();
  };

  return (
    <Modal size="xl" opened={opened} onClose={onClose}>
      <TextInput
        value={newPlaybookTitle}
        placeholder={"Please enter a valid title"}
        error={newPlaybookTitle.replace(/\s/g, "") === ""}
        size="sm"
        radius="xxs"
        c="text-secondary"
        autoFocus
        onChange={(event) => setNewPlaybookTitle(event.currentTarget.value)}
      />
      <Space h="sm" />
      <Title order={5}>Steps</Title>
      <ScrollArea offsetScrollbars>
        <PlaybookStepsManagement
          playbookSteps={defaultPlaybook?.steps || []}
          draggableSteps={dragableSteps}
          setState={setState}
          reorder={reorder}
        />
      </ScrollArea>
      <Space h="sm" />
      <Flex justify="end">
        <Button onClick={handleSaveClick}>Save</Button>
      </Flex>
    </Modal>
  );
}
