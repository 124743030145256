import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { PromptVersionShort } from "../models/PromptVersion";

export const useGetShortLatestVersions = () => {
  const {
    data: shortLatestVersions,
    call,
    isLoading,
    isFailed,
    setData: setShortLatestVersions,
  } = useApiPrivateRequest<PromptVersionShort[]>((response) =>
    response.map(
      (shortLatestVersion: any) => new PromptVersionShort(shortLatestVersion),
    ),
  );

  const getShortLatestVersions = async () => {
    await call(`/admin/prompt`, "GET");
  };

  return {
    shortLatestVersions,
    getShortLatestVersions,
    isLoading,
    isFailed,
    setShortLatestVersions,
  };
};
