import { rem, Center, Group, Stack, ScrollArea } from "@mantine/core";
import UserDocumentLink from "./components/UserDocumentLink";
import { useGetChatUserContent } from "./api/useGetChatUserContent";
import { useEffect } from "react";
import { useGetFilings } from "./api/useGetFilings";
import { useGetTranscripts } from "./api/useGetTranscripts";
import CompanyDocumentLink from "./components/CompanyDocumentLink";
import classes from "./styles/LinkGroup.module.css";
import CompanyLink from "./components/CompanyLink";
import { CompanyDocumentView } from "../../shared/models/CompanyDocument";
import { useListState, useMediaQuery } from "@mantine/hooks";
import { useGetCompanies } from "./api/useGetCompanies";
import { isLargeScreenMediaQuery } from "../../shared/utils/ResponsiveStyles";

interface UserDocumentLinkGroupProps {
  chatId?: string;
  userContentIds?: string[];
  filingIds?: string[];
  transcriptIds?: number[];
  companyIds?: number[];
  offset?: boolean;
}

export default function UserDocumentLinkGroup({
  chatId,
  userContentIds,
  filingIds,
  transcriptIds,
  companyIds,
  offset = true,
}: UserDocumentLinkGroupProps) {
  const { userContents, getUserContents } = useGetChatUserContent();
  const { filings, getFilings } = useGetFilings();
  const { transcripts, getTranscripts } = useGetTranscripts();
  const [companyDocuments, { append: appendCompanyDocuments }] =
    useListState<CompanyDocumentView>();
  const { companies: loadedCompanies, getCompanies } = useGetCompanies();
  const [companies, { append: appendCompanies }] = useListState<{
    id: number;
    name: string;
  }>();

  useEffect(() => {
    if (!userContentIds || userContentIds.length === 0 || !chatId) return;
    getUserContents(chatId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContentIds, chatId]);

  useEffect(() => {
    if (!filingIds || filingIds.length === 0) return;
    getFilings(filingIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filingIds]);

  useEffect(() => {
    if (!transcriptIds || transcriptIds.length === 0) return;
    getTranscripts(transcriptIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptIds]);

  useEffect(() => {
    if (!filings) return;
    appendCompanyDocuments(
      ...filings.filter((f) => companyDocuments.every((cd) => cd.id !== f.id)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filings]);

  useEffect(() => {
    if (!transcripts) return;
    appendCompanyDocuments(
      ...transcripts.filter((t) =>
        companyDocuments.every((cd) => cd.id !== t.id),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcripts]);

  useEffect(() => {
    if (!companyIds || companyIds.length === 0) return;
    getCompanies(companyIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIds]);

  useEffect(() => {
    if (!companyDocuments) return;
    appendCompanies(
      ...companyDocuments
        .filter((cd) => companies.every((c) => c.id !== cd.companyId))
        .reduce(
          (acc, doc) => {
            if (!acc.some((company) => company.id === doc.companyId)) {
              acc.push({ id: doc.companyId, name: doc.companyName });
            }

            return acc;
          },
          [] as { id: number; name: string }[],
        ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDocuments]);

  useEffect(() => {
    if (!loadedCompanies) return;
    appendCompanies(
      ...loadedCompanies
        .filter((c) => companies.every((company) => company.id !== c.id))
        .map((c) => ({ id: c.id, name: c.companyName })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedCompanies]);

  const documentsByCompany = companyDocuments.reduce(
    (acc, doc) => {
      if (!acc[doc.companyId]) {
        acc[doc.companyId] = [];
      }
      acc[doc.companyId].push(doc);
      return acc;
    },
    {} as Record<number, CompanyDocumentView[]>,
  );
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    (userContents || companies.length > 0) && (
      <ScrollArea.Autosize mah={100}>
        <Stack gap={rem(10)} pl={offset ? 50 : 0} pb={isLargeScreen ? 10 : 5}>
          {userContents && (
            <Group className={classes["group"]}>
              {userContents.map((document) => (
                <Center key={document.id} className={classes["center"]}>
                  <UserDocumentLink key={document.id} document={document} />
                </Center>
              ))}
            </Group>
          )}
          {companies.length > 0 &&
            companies.map((company) => (
              <Group key={company.id} className={classes["group"]}>
                <Center className={classes["center"]}>
                  <CompanyLink
                    companyName={company.name}
                    companyId={company.id}
                  />
                </Center>
                {documentsByCompany[company.id] &&
                  documentsByCompany[company.id].map((document) => (
                    <Center key={document.id} className={classes["center"]}>
                      <CompanyDocumentLink
                        key={document.id}
                        document={document}
                      />
                    </Center>
                  ))}
              </Group>
            ))}
        </Stack>
      </ScrollArea.Autosize>
    )
  );
}
