import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useUpdateQuestion = () => {
  const {
    data: isUpdated,
    call,
    isLoading: isUpdating,
    isFailed: isUpdateFailed,
  } = useApiPrivateRequest<boolean>((_) => true);
  const updateQuestion = async (id: string, question: string) => {
    await call(`/questionbank/${id}`, "PUT", undefined, { question });
  };

  return {
    isUpdated,
    updateQuestion,
    isUpdating,
    isUpdateFailed,
  };
};
