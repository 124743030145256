import { Progress, Skeleton, rem, Text, Popover, Group } from "@mantine/core";
import { useGetUser } from "../api/useGetUser";
import { useEffect } from "react";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";

export function CreditsUsage() {
  const { user, getUser, isLoading } = useGetUser();
  const [opened, { close, open }] = useDisclosure(false);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  return (
    <>
      {user && (
        <Popover
          width={rem(200)}
          position="bottom"
          withArrow
          shadow="md"
          opened={opened}
        >
          <Popover.Target>
            <Group>
              {user.creditsUsed < user.creditLimit && (
                <Progress
                  onMouseEnter={open}
                  onMouseLeave={close}
                  value={Math.min(
                    100,
                    Math.max(0, 100 - user.creditsUsedPercentage),
                  )}
                  style={{ width: rem(150) }}
                />
              )}
              <Text
                size={isLargeScreen ? "sm" : "xs"}
                c={user.creditsUsedPercentage >= 100 ? "red" : "black"}
              >
                {user.creditsRemaining} credits remaining
              </Text>
            </Group>
          </Popover.Target>
          <Popover.Dropdown>
            <Text size="sm">Want more credits? Contact us!</Text>
          </Popover.Dropdown>
        </Popover>
      )}
      {isLoading && <Skeleton w={rem(150)} />}
    </>
  );
}
