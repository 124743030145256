import { Environment } from "../enums/Environment";
import { LogLevel } from "../enums/LogLevel";

const APP_ENV: Environment =
  import.meta.env.VITE_ENVIRONMENT === "DEV" ? "development" : "production";

const LOG_LEVEL: LogLevel = APP_ENV === "development" ? "debug" : "warn";

interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

interface Logger {
  debug: LogFn;
  warn: LogFn;
  error: LogFn;
}

const NO_OP: LogFn = () => {};

class ConsoleLogger implements Logger {
  readonly debug: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  constructor(options?: { level?: LogLevel }) {
    const { level } = options || {};

    this.error = this.createLogFunction("error", console.error);

    if (level === "error") {
      this.warn = NO_OP;
      this.debug = NO_OP;
      return;
    }

    this.warn = this.createLogFunction("warn", console.warn);

    if (level === "warn") {
      this.debug = NO_OP;
      return;
    }

    this.debug = this.createLogFunction("debug", console.log);
  }

  private createLogFunction(
    level: LogLevel,
    logFunction: (...data: any[]) => void,
  ): LogFn {
    return (message?: any, ...optionalParams: any[]) => {
      const timestamp = this.getFormattedTimestamp();
      const formattedMessage = `[${timestamp}] [${level.toUpperCase()}] ${message}`;
      logFunction(formattedMessage, ...optionalParams);
    };
  }

  private getFormattedTimestamp(): string {
    const now = new Date();
    const date = now.toLocaleDateString("en-US");
    const time = now.toLocaleTimeString("en-US", { hour12: false });
    const milliseconds = now.getMilliseconds().toString().padStart(3, "0");
    return `${date} ${time}.${milliseconds}`;
  }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL });
