import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useCreateAnalysisV2Chat = () => {
  const { data: chat, call } = useApiPrivateRequest<ChatShort>(
    (response) => new ChatShort(response),
  );
  const createChat = async (input: string) => {
    sendEvent(EventName.ChatCreated, {
      [EventParameter.ChatMode]: EventTitle.ChatModeAnalysisV2,
    });
    await call("/chat/analysisv2", "POST", undefined, { input });
  };

  return { chat, createChat };
};
