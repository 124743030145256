import { ChatProcessingStep } from "../ProcessingStep";
import { Timeframe } from "../../../../models/Timeframe";
import { VariableInfo } from "../../../../models/VariableInfo";
import { enumToString } from "../../../../shared/utils/Enums";

export class AnalysisInputsIdentificationStep extends ChatProcessingStep {
  public variables: VariableInfo[];
  public timeframe: Timeframe;
  public steps: { description: string; explanation: string }[];

  constructor(step: any) {
    super(step);
    this.variables = step.data.variables.map(
      (variableInfo: any) => new VariableInfo(variableInfo),
    );
    this.timeframe = new Timeframe(step.data.timeframe);
    this.steps = step.data.steps;
  }

  public get textContent() {
    const variables = this.variables
      .map((variable) => `• ${variable.toViewString()}`)
      .join("\n");

    const timeframe = `${this.timeframe.startDate.toLocaleDateString()} - ${this.timeframe.endDate.toLocaleDateString()}`;

    const frequency = `Frequency: ${enumToString(this.timeframe.frequency)}`;

    const description = this.steps
      .map((step) => {
        return `• ${step.description} (${step.explanation})`;
      })
      .join("\n");

    return (
      variables + "\n\n" + timeframe + "  " + frequency + "\n\n" + description
    );
  }
}
