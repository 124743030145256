import { PromptVersion } from "../models/PromptVersion";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { PromptType } from "../enums/PromptType";

export const useCreatePromptVersion = () => {
  const {
    data: createdPromptVersion,
    call,
    isLoading: isCreating,
    isFailed: isCreateFailed,
  } = useApiPrivateRequest<PromptVersion>(
    (response) => new PromptVersion(response),
  );

  const createPromptVersion = async (
    name: PromptType,
    text: string,
    description: string | null,
    temperature: number,
    maxTokens: number,
  ) => {
    await call(`/admin/prompt`, "POST", undefined, {
      name,
      text,
      description,
      temperature,
      maxTokens,
    });
  };

  return {
    createdPromptVersion,
    createPromptVersion,
    isCreating,
    isCreateFailed,
  };
};
